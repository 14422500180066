import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import BrowserStore from "./utils/browserContext";
import UiStore from "./utils/UiContext";
import esES from "antd/lib/locale/es_ES";
ReactDOM.render(
  // <React.StrictMode>
  <ConfigProvider locale={esES}>
    <BrowserStore>
      <UiStore>
        <App />
      </UiStore>
    </BrowserStore>
  </ConfigProvider>,

  // </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
