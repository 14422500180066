import { CaretRightFilled } from "@ant-design/icons";
import { Button, message, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { api } from "../../../../utils/api";

const SaleScreenOpener = ({ visible, onCancel }) => {
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    api
      .get("/branches")
      .then((res) => {
        setBranches(res);
      })
      .catch((err) => {
        message.error("Error al actualizar lista de sucursales");
        setBranches([]);
      });
  }, []);

  return (
    <Modal
      visible={visible}
      title={"Abrir pantalla de venta"}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{
        padding: 8,
      }}
    >
      <Table
        dataSource={branches}
        pagination={false}
        rowKey={"id"}
        columns={[
          { title: "id", dataIndex: "id" },
          { title: "Codigo", dataIndex: "code" },
          { title: "Nombre", dataIndex: "name" },
          {
            title: "Abrir",
            dataIndex: "id",
            width: 80,
            render: (_, row) => (
              <Button>
                <a
                  href={`/sale_screen/${row.id}`}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  {" "}
                  Abrir <CaretRightFilled />
                </a>
              </Button>
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default SaleScreenOpener;
