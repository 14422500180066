import { useContext } from "react"
import { Redirect, Route } from "react-router-dom"
import SideMenu from "../components/menu/sideMenu"
import { BrowserContext } from "../utils/browserContext"
import {
  AuditOutlined,
  BarChartOutlined,
  BarcodeOutlined,
  CalculatorOutlined,
  ExperimentOutlined,
  FileDoneOutlined,
  LockOutlined,
  ReadOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  SwapOutlined,
  TagsOutlined,
  TeamOutlined,
  WalletOutlined,
} from "@ant-design/icons"

const routes = [
  {
    key: "1",
    label: "Categorias",
    path: "/categories",
    icon: <TagsOutlined />,
  },
  { key: "2", label: "Catalogo", path: "/catalog", icon: <BarcodeOutlined /> },
  { key: "3", label: "Entidades", path: "/entities", icon: <TeamOutlined /> },

  {
    key: "4",
    label: "Compras",
    path: "/purchases",
    icon: <WalletOutlined />,
  },
  {
    key: "5",
    label: "Ventas",
    path: "/sales",
    icon: <ShoppingCartOutlined />,
  },
  {
    key: "5.1",
    label: "Extracto de Facturas",
    path: "/billSearch",
    icon: <SearchOutlined />
  },
  {
    key: "6",
    label: "Recibos",
    path: "/receipts",
    icon: <AuditOutlined />,
  },
  {
    key: "6.1",
    label: "Notas de Crédito",
    path: "/credit_notes",
    icon: <CalculatorOutlined />,
  },
  {
    key: "7",
    label: "Transferencias",
    path: "/transferences",
    icon: <SwapOutlined />,
  },
  {
    key: "8",
    label: "Ajustes de Stock",
    path: "/adjustments",
    icon: <CalculatorOutlined />,
  },
  {
    key: "9",
    label: "Formulas",
    path: "/recipe",
    icon: <ExperimentOutlined />,
  },
  {
    key: "10",
    label: "Informes",
    path: "/reports",
    icon: <ReadOutlined />,
  },
  {
    key: "11",
    label: "Dashboards",
    path: "/dashboard",
    icon: <BarChartOutlined />,
  },
]

const BillLayout = ({ children }) => {
  return <SideMenu routes={routes}>{children}</SideMenu>
}

const BillLayoutRoute = ({ component: Component, ...rest }) => {
  const [browserData] = useContext(BrowserContext)
  const hasAccess = browserData.user

  return (
    <Route
      {...rest}
      render={(props) =>
        hasAccess ? (
          <BillLayout>
            <Component {...props} />
          </BillLayout>
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  )
}

export default BillLayoutRoute
