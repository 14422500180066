import { Descriptions, InputNumber, message, Modal, Skeleton } from "antd";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { api } from "../../utils/api";
import { currencyFormat, currencyParser } from "../../utils/formaters";

const ExchangeModal = ({ visible, onCancel, bill, drawer, onSuccess }) => {
  const [paymentValue, setPaymentValue] = useState();
  const valueInput = useRef(null);
  const [posting, setPosting] = useState(false);

  function handlePaymentConfirm() {
    if (paymentValue < bill.total) {
      message.info(
        "No se puede realizar pago con valor inferior al valor de la Factura"
      );
      return;
    }
    setPosting(true);
  }

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        valueInput?.current?.focus();
      }, 100);
    }
  }, [visible]);

  useEffect(() => {
    if (posting && bill && drawer) {
      api
        .post(`/drawers/bill/payment`, {
          drawerId: drawer.id,
          billId: bill.id,
        })
        .then((res) => {
          message.success("Pago realizado");
          onSuccess();
          setPaymentValue(0);
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setPosting(false);
        });
    }
  }, [posting, bill, drawer, onSuccess]);

  return (
    <Modal
      visible={visible}
      title={"Realizar Pago"}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ padding: 8 }}
    >
      {bill ? (
        <Descriptions bordered>
          <Descriptions.Item span={3} label={"Total"}>
            {currencyFormat(bill.total)}
          </Descriptions.Item>
          <Descriptions.Item span={3} label={"Pago"}>
            <InputNumber
              ref={valueInput}
              style={{ width: "100%" }}
              value={paymentValue}
              onChange={(value) => {
                setPaymentValue(value);
              }}
              parser={currencyParser}
              formatter={currencyFormat}
              onPressEnter={handlePaymentConfirm}
            />
          </Descriptions.Item>
          <Descriptions.Item span={3} label={"Vuelto"}>
            {currencyFormat(
              paymentValue > bill.total ? paymentValue - bill.total : 0
            )}
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Skeleton active />
      )}
    </Modal>
  );
};

export default ExchangeModal;
