import { Tag } from "antd";

const ReceiptsTypeTag = ({ type }) => {
  const options = {
    CHARGE: <Tag color={"geekblue"}>COBRO</Tag>,
    PAYMENT: <Tag color={"purple"}>PAGO</Tag>,
    default: <Tag>No indentificado</Tag>,
  };

  return options[type] || options["default"];
};

export default ReceiptsTypeTag;
