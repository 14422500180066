import { Descriptions, message, PageHeader, Skeleton, Table, Tabs } from "antd";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { useEffect } from "react";
import { api } from "../../../utils/api";
import { currencyFormat } from "../../../utils/formaters";

const DrawerDetails = () => {
  const { id } = useParams();
  const [drawer, setDrawer] = useState(null);

  function fetchDrawer() {
    api
      .get(`/drawers/${id}`)
      .then((res) => setDrawer(res))
      .catch((err) => {
        message.error(err.message);
      });
  }

  const cbFetchDrawer = useCallback(fetchDrawer, [id]);

  useEffect(() => {
    document.title = "Cargando...";
    cbFetchDrawer();
  }, [cbFetchDrawer]);

  useEffect(() => {
    if (drawer) {
      document.title = `Caja - ${drawer.code}`;
    }
  }, [drawer]);

  if (drawer) {
    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={drawer.id + " - " + drawer.code}
        subTitle={"Detalles del Caja"}
      >
        <Descriptions bordered>
          <Descriptions.Item label={"ID"}>{drawer.id}</Descriptions.Item>
          <Descriptions.Item label={"Codigo"}>{drawer.code}</Descriptions.Item>
          <Descriptions.Item label={"Sucursal"}>
            {drawer.branch.code}
          </Descriptions.Item>
          <Descriptions.Item label={"En Caja"}>
            {currencyFormat(drawer.stored)}
          </Descriptions.Item>
        </Descriptions>
        <Tabs defaultActiveKey={"cashMovements"}>
          <Tabs.TabPane tab={"Movimientos de Caja"} key={"cashMovements"}>
            <Table
              dataSource={drawer.movements}
              rowKey={"id"}
              columns={[
                {
                  title: "Fecha",
                  dataIndex: "createdAt",
                  width: 120,
                  render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
                },
                {
                  title: "Descripcion",
                  dataIndex: "reason",
                },
                {
                  title: "Cantidad",
                  dataIndex: "amount",
                  render: (amount, row) => currencyFormat(amount),
                },
              ]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Historico de Cierre"} key={"dayEns"}>
            <Table
              dataSource={drawer.dayEnds}
              rowKey={"id"}
              columns={[
                {
                  title: "Fecha",
                  dataIndex: "createdAt",
                  width: 120,
                  render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
                },
                {
                  title: "Restante",
                  dataIndex: "amountLeft",
                  render: (amount, _) => currencyFormat(amount),
                },
                {
                  title: "Removido",
                  dataIndex: "amountRemoved",
                  render: (amount, _) => currencyFormat(amount),
                },
                {
                  title: "Declarado",
                  dataIndex: "id",
                  render: (_, row) =>
                    currencyFormat(
                      parseInt(row.amountRemoved) + parseInt(row.amountLeft)
                    ),
                },
                {
                  title: "Esperado",
                  dataIndex: "id",
                  render: (_, row) =>
                    currencyFormat(
                      parseInt(row.amountRemoved) +
                        parseInt(row.amountLeft) -
                        parseInt(row.variation)
                    ),
                },
                {
                  title: "Variacion",
                  dataIndex: "variation",
                  render: (amount, _) => currencyFormat(amount),
                },
              ]}
            />
          </Tabs.TabPane>
        </Tabs>
      </PageHeader>
    );
  } else {
    return <Skeleton active />;
  }
};

export default DrawerDetails;
