import { EditOutlined, EyeOutlined } from "@ant-design/icons"
import {
  Descriptions,
  message,
  PageHeader,
  Skeleton,
  Tabs,
  Table,
  Button,
  Modal,
  Form,
  InputNumber,
} from "antd"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useCallback } from "react"
import { useState } from "react"
import { useParams } from "react-router"
import { Link } from "react-router-dom"
import BillPaymentStatus from "../../../components/tags/billPaymentStatus"
import { api } from "../../../utils/api"
import { currencyFormat, currencyParser } from "../../../utils/formaters"

const EntityDetails = () => {
  const { id } = useParams()
  const [entity, setEntity] = useState(null)
  const [creditModal, setCreditModal] = useState(false)
  const [creditForm] = Form.useForm()

  function submitCredit(values) {
    api
      .patch(`/entities/${entity.id}`, {
        creditLimit: values.amount,
      })
      .then((res) => {
        message.success("Fue salvo el nuevo limite de credito")
        setCreditModal(false)
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => {
        fetchEntity()
      })
  }

  function fetchEntity() {
    api
      .get(`/entities/${id}`)
      .then((res) => setEntity(res))
      .catch((err) => {
        message.error(err.message)
      })
  }

  const cbFetchEntity = useCallback(fetchEntity, [id])

  useEffect(() => {
    document.title = "Cargando..."
    cbFetchEntity()
  }, [cbFetchEntity])

  useEffect(() => {
    if (entity) {
      document.title = `${entity.id} - ${entity.name}`
    }
  })

  if (entity) {
    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={entity.name}
        subTitle={"Detalles de Entidad"}
      >
        <Descriptions bordered size={"small"}>
          <Descriptions.Item label={"Codigo"}>{entity.id}</Descriptions.Item>
          <Descriptions.Item label={"Nombre"}>{entity.name}</Descriptions.Item>
          <Descriptions.Item label={"Fecha creación"}>
            {dayjs(entity.createdAt).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label={"Contacto"}>
            {entity.phone || "No registrado"}
          </Descriptions.Item>
          <Descriptions.Item label={"Cedula"}>
            {entity.ci || "No registrado"}
          </Descriptions.Item>
          <Descriptions.Item label={"RUC"}>
            {entity.ruc || "No registrado"}
          </Descriptions.Item>
          <Descriptions.Item label={"Direción"} span={3}>
            {entity.address || "No registrado"}
          </Descriptions.Item>
          <Descriptions.Item label={"Limite crédito"}>
            {currencyFormat(entity.creditLimit)}{" "}
            <Button
              onClick={() => setCreditModal(true)}
              type={"link"}
              style={{ border: "none", padding: 0 }}
            >
              <EditOutlined />
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label={"Pen. Pago"}>
            {currencyFormat(
              entity.bills
                .filter((b) => b.type === "PURCHASE")
                .map((b) => b.paymentLeft)
                .reduce((acc, cur) => acc + cur, 0)
            )}
          </Descriptions.Item>
          <Descriptions.Item label={"Pen. Cobro"}>
            {currencyFormat(
              entity.bills
                .filter((b) => b.type === "SALE")
                .map((b) => b.paymentLeft)
                .reduce((acc, cur) => acc + cur, 0)
            )}
          </Descriptions.Item>
        </Descriptions>
        <Tabs defaultActiveKey={"cred_client"}>
          <Tabs.TabPane tab={"Credito Cliente"} key={"cred_client"}>
            <Table
              dataSource={entity.bills.filter((b) => b.type === "SALE")}
              rowKey={"id"}
              size={"small"}
              columns={[
                { title: "Cod.", dataIndex: "id" },
                {
                  title: "Fecha",
                  dataIndex: "date",
                  render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
                },
                {
                  title: "Estado Pag.",
                  dataIndex: "paymentStatus",
                  render: (_, row) => (
                    <BillPaymentStatus paymentStatus={row.paymentStatus} />
                  ),
                },
                {
                  title: "Total",
                  dataIndex: "total",
                  render: (total, _) => currencyFormat(total),
                },
                {
                  title: "Pen. Pago",
                  dataIndex: "paymentLeft",
                  render: (paymentLeft, _) => currencyFormat(paymentLeft),
                },
                {
                  title: 'Acciones',
                  dataIndex: 'id',
                  width: 80,
                  render: (_, row) => (
                    <Button><Link to={`/sales/${row.id}`}><EyeOutlined /> Abrir</Link></Button>
                  )
                }
              ]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Credito Provedor"} key={"cred_supplier"}>
            <Table
              dataSource={entity.bills.filter((b) => b.type === "PURCHASE")}
              rowKey={"id"}
              size={"small"}
              columns={[
                { title: "Cod.", dataIndex: "id" },
                {
                  title: "Fecha",
                  dataIndex: "date",
                  render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
                },
                {
                  title: "Estado Pag.",
                  dataIndex: "paymentStatus",
                  render: (_, row) => (
                    <BillPaymentStatus paymentStatus={row.paymentStatus} />
                  ),
                },
                {
                  title: "Total",
                  dataIndex: "total",
                  render: (total, _) => currencyFormat(total),
                },
                {
                  title: "Pen. Pago",
                  dataIndex: "paymentLeft",
                  render: (paymentLeft, _) => currencyFormat(paymentLeft),
                },
                {
                  title: 'Acciones',
                  dataIndex: 'id',
                  width: 80,
                  render: (_, row) => (
                    <Button><Link to={`/compras/${row.id}`}><EyeOutlined /> Abrir</Link></Button>
                  )
                }
              ]}
            />
          </Tabs.TabPane>
        </Tabs>
        <Modal
          visible={creditModal}
          title={"Cambio de Limite de Crédito"}
          onCancel={() => setCreditModal(false)}
          footer={null}
        >
          <Form
            form={creditForm}
            name={"CreditForm"}
            layout={"vertical"}
            onFinish={submitCredit}
          >
            <Form.Item
              label={"Valor"}
              name={"amount"}
              rules={[
                { required: true, message: "Por favor especifique el valor" },
              ]}
            >
              <InputNumber
                formatter={currencyFormat}
                parser={currencyParser}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </PageHeader>
    )
  } else {
    return <Skeleton active />
  }
}

export default EntityDetails
