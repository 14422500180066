import { createContext, useState } from "react";

export const BrowserContext = createContext([]);

const BrowserStore = ({ children }) => {
  const [data, setData] = useState(() => {
    return (
      JSON.parse(localStorage.getItem("data")) || {
        user: null,
      }
    );
  });

  window.onstorage = () => {
    setData(JSON.parse(window.localStorage.getItem("data")));
  };

  function storeData(data) {
    localStorage.setItem("data", JSON.stringify(data));
    setData(data);
  }

  return (
    <BrowserContext.Provider value={[data, storeData]}>
      {children}
    </BrowserContext.Provider>
  );
};

export default BrowserStore;
