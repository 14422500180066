import {
  DollarOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons";
import { Avatar, Button, Col, Dropdown, Layout, Menu, Row, Space } from "antd";
import { Header } from "antd/es/layout/layout";
import MenuItem from "antd/es/menu/MenuItem";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import DrawerScreenOpener from "../../routes/admin/drawer/drawerScreenOpener";
import SaleScreenOpener from "../../routes/facturacion/bills/sales/saleScreenOpener";
import { api } from "../../utils/api";
import { BrowserContext } from "../../utils/browserContext";
import { UiContext } from "../../utils/UiContext";
import useSessionState from "../../utils/useSessionState";

const { Sider, Content } = Layout;

const SideMenu = ({ children, routes }) => {
  const location = useLocation();
  const history = useHistory();
  const [browserData, setBrowserData] = useContext(BrowserContext);
  const [uiData] = useContext(UiContext);
  const [sideMenuCollapsed, setSideMenuCollapsed] = useSessionState(
    "side_menu_collapsed",
    uiData?.sideMenuCollapsed ?? false
  );
  const [selectedKey, setSelectedKey] = useState(location.pathname);
  const [saleScreenOpener, setSaleScreenOpener] = useState(false);
  const [drawerScreenOpener, setDrawerScreenOpener] = useState(false);

  function toggleMenu() {
    setSideMenuCollapsed(!sideMenuCollapsed);
  }

  function logout() {
    api.post("/auth/logout");
    history.push("/login");
    setBrowserData({
      ...browserData,
      user: null,
    });
  }

  useEffect(() => {
    const path = location.pathname.split("/");
    if (path && path.length > 0) {
      setSelectedKey("/" + path[1]);
    }
  }, [location, selectedKey]);

  const profileMenu = (
    <Menu style={{ minWidth: 150 }}>
      <MenuItem key={"user_preferences"} icon={<SettingOutlined />}>
        <span>Preferencias</span>
      </MenuItem>
      <MenuItem key={"logout"} icon={<LogoutOutlined />} onClick={logout}>
        <span>Salir</span>
      </MenuItem>
    </Menu>
  );

  return (
    <Layout style={{ height: "100%" }}>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
        }}
        width={250}
        collapsedWidth={80}
        collapsible
        collapsed={sideMenuCollapsed}
        trigger={null}
        theme={"light"}
      >
        <Menu theme={"light"} mode={"inline"} selectedKeys={[selectedKey]}>
          <MenuItem
            key={"logo"}
            icon={
              <Icon
                component={() => (
                  <img
                    width={24}
                    src={
                      "https://storage.googleapis.com/hofftek-logos/hofftek-logo.png"
                    }
                    alt="logo"
                  />
                )}
              />
            }
          ></MenuItem>

          {routes.map((route) =>
            route.subMenu ? (
              <Menu.SubMenu
                key={route.path}
                title={route.label}
                icon={route.icon}
              >
                {route.items.map((subRoute) => (
                  <Menu.Item key={subRoute.path} icon={subRoute.icon}>
                    <Link to={subRoute.path} className={"nav-text"}>
                      {subRoute.label}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ) : (
              <MenuItem key={route.path} icon={route.icon}>
                <Link to={route.path} className={"nav-text"}>
                  {route.label}
                </Link>
              </MenuItem>
            )
          )}
        </Menu>
      </Sider>

      <Layout className={"site-layout"} style={{ height: "100%" }}>
        <Header
          className={"site-layout-background"}
          style={{
            marginLeft: sideMenuCollapsed ? 80 : 250,
            backgroundColor: "white",
          }}
        >
          <Row>
            <Col span={12}>
              <Button
                type={"text"}
                key={"toggle-menu"}
                icon={
                  sideMenuCollapsed ? (
                    <MenuUnfoldOutlined />
                  ) : (
                    <MenuFoldOutlined />
                  )
                }
                onClick={toggleMenu}
                style={{
                  marginLeft: -38,
                }}
              />
            </Col>
            <Col span={12}>
              <Space
                align={"center"}
                style={{ width: "100%", flexDirection: "row-reverse" }}
              >
                <Dropdown overlay={profileMenu} placement="bottomRight">
                  <Avatar icon={<UserOutlined />} />
                </Dropdown>
                <span>{browserData.user.fullName}</span>
                {process.env["REACT_APP_TRUCK_WEIGHT"] === "true" ? (
                  <></>
                ) : (
                  <Button
                    type={"primary"}
                    onClick={() => setSaleScreenOpener(true)}
                    style={{ marginRight: 8 }}
                  >
                    <ShoppingCartOutlined /> Venta
                  </Button>
                )}
                <Button
                  type={"primary"}
                  onClick={() => setDrawerScreenOpener(true)}
                >
                  <DollarOutlined /> Caja
                </Button>
              </Space>
            </Col>
          </Row>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "0px 16px",
            padding: 24,
            marginLeft: sideMenuCollapsed ? 80 : 250,
          }}
        >
          {children}
          <SaleScreenOpener
            visible={saleScreenOpener}
            onCancel={() => setSaleScreenOpener(false)}
          />
          <DrawerScreenOpener
            visible={drawerScreenOpener}
            onCancel={() => setDrawerScreenOpener(false)}
          />
          {/* <img
            width={450}
            src={
              'https://storage.googleapis.com/hofftek-logos/hofftek-logo.png'
            }
            style={{
              position: 'fixed',
              opacity: 0.2,
              bottom: 10,
              right: 10,
              zIndex: -1,
            }}
          /> */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default SideMenu;
