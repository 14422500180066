import {
  CalculatorOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons"
import {
  Button,
  Descriptions,
  Drawer,
  Dropdown,
  message,
  PageHeader,
  Skeleton,
  Table,
  Menu,
  Tabs,
  Modal,
  Popconfirm,
  Form,
  DatePicker,
  Input,
} from "antd"
import confirm from "antd/lib/modal/confirm"
import dayjs from "dayjs"
import { useCallback, useContext } from "react"
import { useHistory, useParams } from "react-router"
import { useEffect, useState } from "react"
import BillStatusTag from "../../../../components/tags/billStatusTag"
import { api, apiDefaults } from "../../../../utils/api"
import {
  currencyFormat,
  currencyFormatDecimal,
} from "../../../../utils/formaters"
import ChangeClientForm from "./changeClientForm"
import SaleBillItemForm from "./billItemForm"
import { Link } from "react-router-dom"
import ItemPricePane from "../../catalog/itemPricePane"
import { BrowserContext } from "../../../../utils/browserContext"
import BillPaymentStatus from "../../../../components/tags/billPaymentStatus"
import CreditNoteForm from "../../credit_notes/creditNoteForm"
import CreditNoteTypeTag from "../../../../components/tags/creditNoteTypeTag"
import DescriptionUpdateModal from "../../../../components/billItem/descriptionUpdateModal"

const BillDetails = () => {
  const history = useHistory()
  const { id } = useParams()
  const [bill, setBill] = useState(null)
  const [itemDrawerVisible, setItemDrawerVisible] = useState(false)
  const [changeClient, setChangeClient] = useState(false)
  const [changeDate, setChangeDate] = useState(false)
  const [changeFactura, setChangeFactura] = useState(false)
  const [dateForm] = Form.useForm()
  const [facturaForm] = Form.useForm()
  const [columns, setColumns] = useState([])
  const [creditNoteDrawer, setCreditNoteDrawer] = useState(false)
  const [descriptionModal, setDescriptionModal] = useState(false)
  const [descriptionUpdate, setDescriptionUpdate] = useState(null)

  const [{ user }] = useContext(BrowserContext)

  const truckMode =
    bill?.type === "SALE" && process.env["REACT_APP_TRUCK_WEIGHT"] === "true"

  function printBill() {
    confirm({
      title: "Desea imprimir la Factura?",
      icon: <PrinterOutlined />,
      okText: "Si",
      cancelText: "No",
      onOk: () => {
        window.open(`${apiDefaults.baseURL}/bills/${id}/pdf`, "_blank").focus()
      },
    })
  }

  function completeBill() {
    api
      .post(`/bills/${id}/complete`)
      .then(() => {
        message.success("Fue finalizada la Factura")
        printBill()
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => fetchBill())
  }

  function cancelBill() {
    if (!user.roles.includes("ADM")) {
      message.warn("Es necesario el grupo administrativo en usuario")
      return
    }

    api
      .delete(`/bills/${id}/cancel`)
      .then(() => {
        message.success("Fue cancelada la factura")
      })
      .catch((err) => message.error(err.message))
      .finally(() => fetchBill())
  }

  function updateBillDate(values) {
    api
      .patch(`/bills/${id}`, {
        date: dayjs(values.date).format("YYYY-MM-DD"),
      })
      .then((res) => {
        message.success("Fecha atualizada")
        dateForm.resetFields()
        setChangeDate(false)
      })
      .catch((err) => message.error(err.message))
      .finally(() => fetchBill())
  }

  function updateBillFactura(values) {
    api
      .patch(`/bills/${id}`, values)
      .then((res) => {
        message.success("Nro. Factura atualizado")
        setChangeFactura(false)
        facturaForm.resetFields()
      })
      .catch((err) => message.error(err.message))
      .finally(() => fetchBill())
  }

  function fetchBill() {
    api
      .get(`/bills/${id}`)
      .then((res) => setBill(res))
      .catch((err) => {
        message.error(err)
      })
  }

  const cbFetchBill = useCallback(fetchBill, [id])

  function removeItem(rowId) {
    api
      .delete(`/bills/items/${rowId}`)
      .then((res) => {
        message.success("Iten removido")
      })
      .catch((err) => message.error(err.message))
      .finally(() => cbFetchBill())
  }

  const cbRemoveItem = useCallback(removeItem, [cbFetchBill])

  useEffect(() => {
    cbFetchBill()
  }, [cbFetchBill])

  useEffect(() => {
    document.title = `Fac. ${bill?.type === "SALE" ? "Venta" : "Compra"
      } - ${id}`
  }, [id, bill])

  useEffect(() => {
    const baseColumns = [
      { title: "Cantidad", dataIndex: "quantity" },
      {
        title: "Descripción",
        dataIndex: "description",
        render: (_, row) => (
          <div>
            <Link to={`/catalog/${row.itemId}`}>
              {row.description} <EyeOutlined />
            </Link>
            <Button type="link" onClick={() => { setDescriptionModal(true); setDescriptionUpdate(row) }}><EditOutlined /></Button>
          </div>

        ),
      },
      {
        title: "Precio",
        dataIndex: "price",
        render: (data, _) => currencyFormatDecimal(data),
      },
      {
        title: "Exentas",
        dataIndex: "iva0",
        render: (data, _) => currencyFormat(data),
      },
      {
        title: "IVA 5%",
        dataIndex: "iva5",
        render: (data, _) => currencyFormat(data),
      },
      {
        title: "IVA 10%",
        dataIndex: "iva10",
        render: (data, _) => currencyFormat(data),
      },
    ]
    const columns = []

    if (truckMode) {
      columns.push(
        ...[
          {
            title: "Tara",
            dataIndex: "id",
            render: (_, row) => row.metaData?.tare || "No informado",
          },
          {
            title: "Peso Bruto",
            dataIndex: "id",
            render: (_, row) => row.metaData?.gross || "No informado",
          },
        ]
      )
    }

    columns.push(...baseColumns)
    if (bill?.status === "PENDING") {
      columns.push({
        title: "Acciones",
        dataIndex: "id",
        width: 80,
        render: (_, row) =>
          bill.status === "PENDING" ? (
            <Button type={"link"} danger onClick={() => cbRemoveItem(row.id)}>
              <DeleteOutlined />
            </Button>
          ) : (
            <></>
          ),
      })
    }
    setColumns(columns)
  }, [bill, cbRemoveItem])

  if (bill) {
    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`Fac. ${bill.type === "SALE" ? "Venta" : "Compra"}: ${bill.id}`}
        subTitle={`Detalles de Factura de ${bill?.type === "SALE" ? "Venta" : "Compra"
          }`}
        tags={<BillStatusTag status={bill.status} />}
        extra={[
          bill.status === "COMPLETED" ? (
            <Button
              key={"credit_note"}
              type={"primary"}
              onClick={() => setCreditNoteDrawer(true)}
            >
              <CalculatorOutlined /> Crear Nota de Crédito
            </Button>
          ) : (
            <></>
          ),
          bill.status !== "CANCELED" ? (
            <Popconfirm
              key={"cancelBill"}
              title={"Esta operación no puede ser regresada, deseas proseguir?"}
              okText={"Cancelar la Factura"}
              cancelText={"No"}
              onConfirm={cancelBill}
            >
              <Button type={"primary"} danger>
                <DeleteOutlined /> Cancelar
              </Button>
            </Popconfirm>
          ) : (
            <></>
          ),
          bill.status === "PENDING" ? (
            <Popconfirm
              key={"CompleteBill"}
              title={"Esta operación no puede ser regresada, deseas proseguir?"}
              okText={"Finalizar la Factura"}
              cancelText={"No"}
              onConfirm={completeBill}
            >
              <Button type={"primary"}>
                <CheckOutlined />
                Finalizar
              </Button>
            </Popconfirm>
          ) : (
            <></>
          ),
          <Dropdown
            key={"moreOptions"}
            overlay={
              <Menu>
                <Menu.Item onClick={printBill}>
                  <PrinterOutlined /> Imprimir
                </Menu.Item>
              </Menu>
            }
          >
            <Button style={{ border: "none", padding: 0 }}>
              <EllipsisOutlined
                style={{ fontSize: 20, verticalAlign: "top" }}
              />
            </Button>
          </Dropdown>,
        ]}
      >
        <Descriptions bordered size={"small"}>
          <Descriptions.Item
            span={2}
            label={bill?.type === "SALE" ? "Cliente" : "Provedor"}
          >
            {`${bill.entity.id} - ${bill.entity.name}`}{" "}
            <Button type={"link"} style={{ border: "none", padding: 0 }}>
              <EditOutlined onClick={() => setChangeClient(true)} />
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label={"Documento Cliente"}>
            {bill.entityDocument}
          </Descriptions.Item>
          {bill?.type === "SALE" ? (
            <Descriptions.Item span={2} label={"Direción Cliente"}>
              {bill.address}
            </Descriptions.Item>
          ) : (
            <></>
          )}

          <Descriptions.Item label={"Fecha"}>
            {dayjs(bill.date).format("DD/MM/YYYY")}{" "}
            <Button type={"link"} style={{ border: "none", padding: 0 }}>
              <EditOutlined onClick={() => setChangeDate(true)} />
            </Button>
          </Descriptions.Item>

          <Descriptions.Item label={"Sucursal"}>
            {bill.branch.code} - {bill.branch.name}
          </Descriptions.Item>
          <Descriptions.Item label={"Condición de Pago"}>
            {bill.credit ? "CREDITO" : "CONTADO"}
          </Descriptions.Item>
          <Descriptions.Item label={"Fecha de creación"}>
            {dayjs(bill.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item span={2} label={"Nro. Factura"}>
            {bill.factura && bill.factura.length > 0
              ? bill.factura
              : "No Especificado"}{" "}
            <Button type={"link"} style={{ border: "none", padding: 0 }}>
              <EditOutlined onClick={() => setChangeFactura(true)} />
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label={"Total"}>
            {bill.total > 0 ? currencyFormat(bill.total) : "G$ 0"}
          </Descriptions.Item>
          {bill.credit ? (
            <>
              <Descriptions.Item label={"Estado Pago"}>
                <BillPaymentStatus paymentStatus={bill.paymentStatus} />
              </Descriptions.Item>
              <Descriptions.Item label={"Monto pen. Pago"}>
                {currencyFormat(bill.paymentLeft)}
              </Descriptions.Item>
            </>
          ) : (
            <></>
          )}
        </Descriptions>

        <Tabs defaultActiveKey={"itens"}>
          <Tabs.TabPane tab={"Itenes"} key={"itens"}>
            {bill.status === "PENDING" ? (
              <Button
                type={"primary"}
                onClick={() => setItemDrawerVisible(true)}
                style={{
                  float: "right",
                  marginBottom: 10,
                }}
              >
                <PlusOutlined /> Adicionar Item
              </Button>
            ) : (
              ""
            )}
            <Table
              size={"small"}
              dataSource={bill.items}
              pagination={false}
              columns={columns}
              rowKey={"id"}
              style={{
                marginBottom: 16,
              }}
            />

            <h3>IVA</h3>
            <Descriptions bordered size={"small"}>
              <Descriptions.Item label={"Exentas"}>
                {currencyFormat(bill.iva0)}
              </Descriptions.Item>
              <Descriptions.Item label={"Gravada IVA 5%"}>
                {currencyFormat(bill.iva5)}
              </Descriptions.Item>
              <Descriptions.Item label={"Gravada IVA 10%"}>
                {currencyFormat(bill.iva10)}
              </Descriptions.Item>
              <Descriptions.Item label={"IVA 5%"}>
                {currencyFormat(bill.liqIva5)}
              </Descriptions.Item>
              <Descriptions.Item label={"IVA 10%"}>
                {currencyFormat(bill.liqIva10)}
              </Descriptions.Item>
              <Descriptions.Item label={"Total IVA"}>
                {currencyFormat(bill.ivaTotal)}
              </Descriptions.Item>
            </Descriptions>

            <Drawer
              title={"Adicionar Item"}
              closable={false}
              visible={itemDrawerVisible}
              onClose={() => setItemDrawerVisible(false)}
              width={450}
            >
              <SaleBillItemForm bill={bill} onUpdateNeeded={fetchBill} />
            </Drawer>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Notas de Credito"} key={"credit_notes"}>
            <Table
              dataSource={bill.CreditNote}
              columns={[
                { title: "Codigo", dataIndex: "id" },
                {
                  title: "Tipo",
                  dataIndex: "type",
                  render: (type, _) => <CreditNoteTypeTag type={type} />,
                },
                {
                  title: "Status",
                  dataIndex: "status",
                  width: 120,
                  render: (status, _) => <BillStatusTag status={status} />,
                },
                {
                  title: "Total",
                  dataIndex: "total",
                  render: (total, _) => currencyFormat(total),
                },
                {
                  title: "Acciones",
                  dataIndex: "id",
                  width: 120,
                  render: (_, row) => (
                    <Button>
                      <Link to={"/credit_notes/" + row.id}>
                        {" "}
                        <EyeOutlined /> Visualizar
                      </Link>
                    </Button>
                  ),
                },
              ]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Recibos"} key={"receipts"}>
            <Table
              dataSource={bill.payments}
              rowKey={"receiptId"}
              columns={[
                {
                  title: "Cod. Recibo",
                  dataIndex: "receiptId",
                },
                {
                  title: "Fecha",
                  dataIndex: "receiptId",
                  render: (_, row) =>
                    dayjs(row.receipt.date).format("DD/MM/YYYY"),
                },
                {
                  title: "Status",
                  dataIndex: "receiptId",
                  render: (_, row) => (
                    <BillStatusTag status={row.receipt.status} />
                  ),
                },
                {
                  title: "Total",
                  dataIndex: "receiptId",
                  render: (_, row) => currencyFormat(row.amount),
                },
                {
                  title: "Acciones",
                  dataIndex: "receiptId",
                  render: (receiptId, _) => (
                    <Button
                      onClick={() => history.push(`/receipts/${receiptId}`)}
                    >
                      <EyeOutlined /> Visualizar
                    </Button>
                  ),
                },
              ]}
            />
          </Tabs.TabPane>

          {bill.type === "PURCHASE" ? (
            <Tabs.TabPane tab={"Ajuste de Precios"} key={"priceAdjust"}>
              <Table
                size={"small"}
                dataSource={bill.items}
                pagination={false}
                columns={[
                  { title: "Cantidad", dataIndex: "quantity" },
                  {
                    title: "Descripción",
                    dataIndex: "description",
                    render: (_, row) => (
                      <Link to={`/catalog/${row.itemId}`}>
                        {row.description} <EyeOutlined />
                      </Link>
                    ),
                  },
                  {
                    title: "Precio",
                    dataIndex: "price",
                    render: (data, _) => currencyFormatDecimal(data),
                  },
                  {
                    title: "Total",
                    dataIndex: "id",
                    render: (_, row) =>
                      currencyFormat(
                        parseInt(row.price) * parseInt(row.quantity)
                      ),
                  },
                ]}
                rowKey={"id"}
                expandable={{
                  rowExpandable: (row) => true,
                  expandedRowRender: (row) => (
                    <ItemPricePane itemId={row.itemId} />
                  ),
                }}
                style={{
                  marginBottom: 16,
                }}
              />
            </Tabs.TabPane>
          ) : (
            <></>
          )}
        </Tabs>

        <Drawer
          title={"Formulário de Nota de cŕedito"}
          closable={false}
          visible={creditNoteDrawer}
          onClose={() => setCreditNoteDrawer(false)}
          width={450}
        >
          <CreditNoteForm onFormSuccess={fetchBill} bill={bill} />
        </Drawer>

        <Modal
          visible={changeClient}
          title={"Cambio de cliente"}
          onCancel={() => setChangeClient(false)}
          footer={null}
        >
          <ChangeClientForm
            bill={bill}
            onFormSuccess={() => setChangeClient(false)}
            onUpdateNeeded={fetchBill}
          />
        </Modal>

        <DescriptionUpdateModal
          visible={descriptionModal}
          billItem={descriptionUpdate}
          onCancel={() => setDescriptionModal(false)}
          onFinish={() => { setDescriptionModal(false); fetchBill() }}
        />

        <Modal
          visible={changeDate}
          title={"Cambio de Fecha"}
          onCancel={() => setChangeDate(false)}
          footer={null}
        >
          <Form form={dateForm} onFinish={updateBillDate} layout={"vertical"}>
            <Form.Item
              label={"Fecha"}
              name={"date"}
              rules={[
                { required: true, message: "Por favor especifica la Fecha" },
              ]}
            >
              <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          visible={changeFactura}
          title={"Vinculo Nro. Factura"}
          onCancel={() => setChangeFactura(false)}
          footer={null}
        >
          <Form
            form={facturaForm}
            name={"UpdateFacturaBill"}
            onFinish={updateBillFactura}
            layout={"vertical"}
          >
            <Form.Item
              label={"Nro Factura"}
              name={"factura"}
              rules={[
                {
                  required: true,
                  message: "Por favor especifica el nro de la factura",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </PageHeader>
    )
  } else {
    return <Skeleton active />
  }
}

export default BillDetails
