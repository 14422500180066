import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { api } from "../../../utils/api";

const CategoryForm = ({ category, onFormSuccess }) => {
  const [categoryForm] = Form.useForm();

  function handleFormSubmit(values) {
    const reqMethod = category ? "PUT" : "POST";
    const reqUrl = "/categories" + (category ? "/" + category.id : "");
    api
      .req(reqMethod, reqUrl, {
        name: values.name,
      })
      .then((res) => {
        message.success("Categoria Salva");
        onFormSuccess();
        categoryForm.resetFields();
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  useEffect(() => {
    if (category) {
      categoryForm.setFieldsValue({
        name: category.name,
      });
    } else {
      categoryForm.resetFields();
    }
  }, [category, categoryForm]);

  return (
    <Form
      form={categoryForm}
      name={"CategoryForm"}
      onFinish={handleFormSubmit}
      layout={"vertical"}
    >
      <Form.Item
        label={"Nombre"}
        name={"name"}
        rules={[
          {
            required: true,
            message: "Por favor indique el nombre de la categoria",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};
export default CategoryForm;
