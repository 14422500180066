import { PlusOutlined } from "@ant-design/icons"
import {
  Dropdown,
  message,
  PageHeader,
  Table,
  Menu,
  Drawer,
  Button,
  Space,
} from "antd"
import Search from "antd/lib/input/Search"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import BillStatusTag from "../../../components/tags/billStatusTag"
import ReceiptsTypeTag from "../../../components/tags/receiptTypeTag"
import { api } from "../../../utils/api"
import { currencyFormat } from "../../../utils/formaters"
import ReceiptForm from "./receiptForm"

const ReceiptsPage = ({ branch }) => {
  const [receipts, setReceipts] = useState([])
  const [loading, setLoading] = useState(false)
  const [drawer, setDrawer] = useState(false)
  const [search, setSearch] = useState(null)

  function fetchReceipts() {
    setLoading(false)

    let query = null

    if (search) {
      query = { entityName: search }
    }

    api
      .get(`/receipts`, query)
      .then((res) => setReceipts(res))
      .catch((err) => {
        message.error(err.message)
        setReceipts([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    document.title = "Recibos"
  }, [])

  useEffect(() => {
    fetchReceipts()
  }, [])

  return (
    <PageHeader
      ghost={false}
      onBack={branch ? null : () => window.history.back()}
      title={"Recibos"}
      subTitle={"Lista de recibos COBROS/PAGOS"}
      extra={[
        <Button type={"primary"} key={"add"} onClick={() => setDrawer(true)}>
          <PlusOutlined /> Adicionar Recibo
        </Button>,
      ]}
    >

      <Search
        placeholder="Tipea para buscar"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => fetchReceipts()}
        enterButton
      />


      <Table
        loading={loading}
        dataSource={receipts}
        style={{ marginTop: 10 }}
        columns={[
          { title: "Codigo", dataIndex: "id" },
          {
            title: "Fecha",
            dataIndex: "date",
            width: 120,
            render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
          },
          {
            title: "Tipo",
            dataIndex: "type",
            render: (type, _) => <ReceiptsTypeTag type={type} />,
          },
          {
            title: "Status",
            dataIndex: "status",
            width: 120,
            render: (status, _) => <BillStatusTag status={status} />,
          },
          {
            title: "Entidad",
            dataIndex: "id",
            render: (_, row) => row.entity.name,
          },
          {
            title: "total",
            dataIndex: "total",
            render: (total, _) => currencyFormat(total),
          },
          {
            title: "Acciones",
            dataIndex: "id",
            render: (_, row) => (
              <Dropdown.Button
                overlay={
                  <Menu>
                    <Menu.Item key={"openNewTab"}>
                      <a
                        href={`/receipts/${row.id}`}
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        Abrir en nueva guia
                      </a>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Link to={`/receipts/${row.id}`}>Visualizar</Link>
              </Dropdown.Button>
            ),
          },
        ]}
        rowKey={"id"}
        size={"small"}
      />

      <Drawer
        title={"Formulario de Recibo"}
        closable={false}
        visible={drawer}
        onClose={() => setDrawer(false)}
        width={450}
      >
        <ReceiptForm
          onFormSuccess={() => {
            fetchReceipts()
            setDrawer(false)
          }}
        />
      </Drawer>
    </PageHeader>
  )
}

export default ReceiptsPage
