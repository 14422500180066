import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import "./App.css"
import AdminLayoutRoute from "./layouts/adminLayout"
import BillLayoutRoute from "./layouts/billLayout"
import LoginLayout from "./layouts/loginLayout"
import BranchesPage from "./routes/admin/branches/branchesPage"
import UserDetails from "./routes/admin/users/userDetails"
import UsersPage from "./routes/admin/users/usersPage"
import PurchasesBills from "./routes/facturacion/bills/purchases/purchasesBills"
import SalesBills from "./routes/facturacion/bills/sales/salesBills"
import CatalogPage from "./routes/facturacion/catalog/catalogPage"
import ItemDetails from "./routes/facturacion/catalog/itemDetails"
import EntitiesPage from "./routes/facturacion/entities/entitiesPage"
import BillDetails from "./routes/facturacion/bills/details/billDetails"
import CleanLayoutRoute from "./layouts/cleanLayout"
import SaleScreen from "./routes/sale/saleScreen"
import CategoriesPage from "./routes/facturacion/categories/categoriesPage"
import PriceSearchPage from "./routes/facturacion/catalog/PriceSearchPage"
import DrawersPage from "./routes/admin/drawer/drawersPage"
import DrawerDetails from "./routes/admin/drawer/drawerDetails"
import Checkout from "./routes/checkout/checkout"
import ReceiptsPage from "./routes/facturacion/receipts/receiptsPage"
import ReceiptsDetails from "./routes/facturacion/receipts/receiptDetails"
import EntityDetails from "./routes/facturacion/entities/entityDetails"
import CountsPage from "./routes/inventory/stockCounts/countsPage"
import CountDetails from "./routes/inventory/stockCounts/countDetails"
import TransferencePage from "./routes/inventory/transferences/transferencePage"
import TransferenceDetails from "./routes/inventory/transferences/transferenceDetails"
import ReportsPage from "./routes/reports/reportsPage"
import ExtractInventory from "./routes/reports/extractInventory"
import BillExtract from "./routes/reports/billExtract"
import MainDash from "./routes/dashboards/mainDash"
import PurchaseDash from "./routes/dashboards/purchaseDash"
import FinancesDash from "./routes/dashboards/financesDash"
import DashboardPage from "./routes/dashboards/dashboardPage"
import CreditNotesPage from "./routes/facturacion/credit_notes/creditNotesPage"
import CreditNoteDetails from "./routes/facturacion/credit_notes/creditNoteDetails"
import GainsExtract from "./routes/reports/gainsExtract"
import BillAdvancedSearch from "./routes/facturacion/bills/BillAdvancedSearch"
import StockAdjustmentPage from './routes/inventory/stockAdjustment/stockAdjustmentPage'
import StockAdjustmentDetails from './routes/inventory/stockAdjustment/stockAdjustmentDetails'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <BillLayoutRoute path="/" exact component={() => <></>} />
        <BillLayoutRoute path="/dashboard/sales" exact component={MainDash} />
        <Route path="/login" component={LoginLayout} />
        <AdminLayoutRoute path="/users" exact component={UsersPage} />
        <AdminLayoutRoute path="/users/:id" exact component={UserDetails} />
        <AdminLayoutRoute path="/branches" exact component={BranchesPage} />
        <BillLayoutRoute path="/categories" exact component={CategoriesPage} />
        <BillLayoutRoute path="/catalog" exact component={CatalogPage} />
        <BillLayoutRoute path="/catalog/:id" exact component={ItemDetails} />
        <BillLayoutRoute path="/entities" exact component={EntitiesPage} />
        <BillLayoutRoute path="/entities/:id" exact component={EntityDetails} />
        <BillLayoutRoute path="/sales" exact component={SalesBills} />
        <BillLayoutRoute path="/sales/:id" exact component={BillDetails} />
        <BillLayoutRoute path="/purchases" exact component={PurchasesBills} />
        <BillLayoutRoute path="/purchases/:id" exact component={BillDetails} />
        <BillLayoutRoute path="/receipts" exact component={ReceiptsPage} />
        <BillLayoutRoute path="/billSearch" exact component={BillAdvancedSearch} />
        <BillLayoutRoute path="/adjustments" exact component={StockAdjustmentPage} />
        <BillLayoutRoute path="/adjustments/:id" exact component={StockAdjustmentDetails} />
        <BillLayoutRoute
          path="/receipts/:id"
          exact
          component={ReceiptsDetails}
        />
        <BillLayoutRoute path="/stock_count" exact component={CountsPage} />
        <BillLayoutRoute
          path="/stock_count/:id"
          exact
          component={CountDetails}
        />
        <BillLayoutRoute
          path="/transferences"
          exact
          component={TransferencePage}
        />
        <BillLayoutRoute
          path="/transferences/:id"
          exact
          component={TransferenceDetails}
        />
        <BillLayoutRoute path="/reports" exact component={ReportsPage} />
        <BillLayoutRoute
          path="/reports/inventory_extract"
          exact
          component={ExtractInventory}
        />
        <BillLayoutRoute
          path="/reports/bill_extract"
          exact
          component={BillExtract}
        />
        <BillLayoutRoute path="/reports/gains_extract" exact component={GainsExtract} />
        <BillLayoutRoute path="/dashboard" exact component={DashboardPage} />
        <BillLayoutRoute
          path="/dashboard/purchases"
          exact
          component={PurchaseDash}
        />
        <BillLayoutRoute
          path="/dashboard/finances"
          exact
          component={FinancesDash}
        />
        <BillLayoutRoute
          path="/credit_notes"
          exact
          component={CreditNotesPage}
        />
        <BillLayoutRoute
          path="/credit_notes/:id"
          exact
          component={CreditNoteDetails}
        />
        <CleanLayoutRoute
          path="/sale_screen/:id"
          exact
          component={SaleScreen}
        />
        <CleanLayoutRoute
          path="/price_search/:id"
          exact
          component={PriceSearchPage}
        />
        <CleanLayoutRoute
          path="/checkout/:drawerId"
          exact
          component={Checkout}
        />
        <CleanLayoutRoute
          path="/checkout_receipt/:id"
          exact
          component={ReceiptsDetails}
        />
        <AdminLayoutRoute path={"/drawers"} exact component={DrawersPage} />
        <AdminLayoutRoute
          path={"/drawers/:id"}
          exact
          component={DrawerDetails}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default App
