import { Form, Modal, Button, message, Input } from "antd"
import { useEffect, useRef } from "react"
import { api } from "../../utils/api"

const DescriptionUpdateModal = ({ visible, billItem, onCancel, onFinish }) => {
	const [form] = Form.useForm()
	const inputRef = useRef()

	function handleSubmit(values) {
		api.patch(`/bills/items/${billItem.id}/description`, values).then(res => {
			message.success("Fue actualizada la Descripción")
			onFinish()
		}).catch(err => {
			message.error(err.message)
		})
	}

	useEffect(() => {
		if (visible) {
			setTimeout(() => {
				const input = inputRef.current
				input.focus({ cursor: 'end' })
			}, 350)
		}

	}, [visible])

	useEffect(() => {
		if (billItem) {
			form.setFieldsValue({ description: billItem?.description })
		}
	}, [billItem])

	return (
		<Modal visible={visible} title="Actualizar Descripción" onCancel={onCancel} footer={[
			<Button type="primary" form="descriptionUpdateForm" key="descriptionSubmit" htmlType="submit">Confirmar</Button>
		]}>
			<Form form={form} id="descriptionUpdateForm" name="descriptionUpdateForm" layout="vertical" onFinish={handleSubmit}>
				<Form.Item label="Descripción" name="description" rules={[{ required: true, message: 'Por favor especifique la descripción' }, { min: 3, message: 'La descripción debe tener un minimo de 3 caracteres.' }]}>
					<Input ref={inputRef} />
				</Form.Item>
			</Form>
		</Modal>
	)

}

export default DescriptionUpdateModal