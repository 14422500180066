import { CheckOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table } from "antd";
import { useEffect } from "react";
import { useHistory } from "react-router";

const DashboardPage = () => {
  const history = useHistory();

  useEffect(() => {
    document.title = "Dashboards";
  }, []);

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Dashboards"}
    >
      <Table
        dataSource={[
          {
            id: 1,
            name: "Panel de Ventas",
            url: "/dashboard/sales",
          },
          {
            id: 2,
            name: "Panel de Compras",
            url: "/dashboard/purchases",
          },
          {
            id: 3,
            name: "Panel Financiero",
            url: "/dashboard/finances",
          },
        ]}
        rowKey={"id"}
        columns={[
          { title: "Cod", dataIndex: "id" },
          { title: "Nombre", dataIndex: "name" },
          {
            title: "Selecionar",
            width: 200,
            dataIndex: "id",
            render: (_, row) => (
              <Button type={"primary"} onClick={() => history.push(row.url)}>
                <CheckOutlined /> Selecionar
              </Button>
            ),
          },
        ]}
      />
    </PageHeader>
  );
};

export default DashboardPage;
