import { Button, DatePicker, Form, message, Radio, Select } from "antd";
import dayjs from "dayjs";
import { useContext } from "react";
import DebounceSelect from "../../../../components/search/debounceSelect";
import { api } from "../../../../utils/api";
import { BrowserContext } from "../../../../utils/browserContext";
import { fetchBranches, fetchEntities } from "../../../../utils/selectFetchers";

const SaleBillForm = ({ bill, onFormSuccess, onUpdateNeeded }) => {
  const [saleBillForm] = Form.useForm();
  const [{ user }] = useContext(BrowserContext);

  function onFinish(values) {
    if (!user.roles.includes("ADM")) {
      message.warn("Es necesario el grupo administrativo en usuario");
      return;
    }

    api
      .post("/bills", {
        branchId: values.branch.value,
        type: "SALE",
        credit: values.credit,
        date: dayjs(values.date).format("YYYY-MM-DD"),
        entityId: values.entity.value,
      })
      .then((res) => {
        message.success("Factura creada");
        saleBillForm.resetFields();
        onFormSuccess();
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        onUpdateNeeded();
      });
  }

  return (
    <Form
      form={saleBillForm}
      name={"SaleBillForm"}
      layout={"vertical"}
      onFinish={onFinish}
      initialValues={{
        credit: false,
      }}
    >
      <Form.Item
        label={"Sucursal"}
        name={"branch"}
        rules={[{ required: true, message: "Por favor seleciona sucursal" }]}
      >
        <DebounceSelect
          showSearch
          searchOnFocus={true}
          fetchOptions={fetchBranches}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        label={"Condición de Pago"}
        name={"credit"}
        rules={[
          {
            required: true,
            message: "Por favor seleciona la condición de pago",
          },
        ]}
      >
        <Radio.Group>
          <Radio value={false}>CONTADO</Radio>
          <Radio value={true}>CREDITO</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label={"Fecha"}
        name={"date"}
        rules={[{ required: true, message: "Por favor seleciona la fecha " }]}
      >
        <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label={"Cliente"}
        name={"entity"}
        rules={[{ required: true, message: "Por favor seleciona el cliente" }]}
      >
        <DebounceSelect
          showSearch
          fetchOptions={fetchEntities}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SaleBillForm;
