import { Button, DatePicker, Form, Input, InputNumber, message } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useState } from "react";
import { api } from "../../../utils/api";
import { currencyFormat, currencyParser } from "../../../utils/formaters";

const ReceiptCheckForm = ({ receipt, onFormSuccess }) => {
  const [checkForm] = Form.useForm();
  const [posting, setPosting] = useState(null);
  const [postingEnd, setPostingEnd] = useState(false);

  useEffect(() => {
    if (posting) {
      api
        .post("/receipts/checks", {
          receiptId: receipt.id,
          number: posting.number,
          date: dayjs(posting.date).format("YYYY-MM-DD"),
          bank: posting.bank,
          amount: posting.amount,
        })
        .then((res) => {
          message.success("Fue adicionado el Cheque");
          setPosting(null);
          setPostingEnd(true);
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  }, [posting, receipt]);

  useEffect(() => {
    if (postingEnd) {
      checkForm.resetFields();
      setPostingEnd(false);
      onFormSuccess();
    }
  }, [postingEnd, checkForm, onFormSuccess]);

  return (
    <Form
      form={checkForm}
      name={"CheckForm"}
      onFinish={(values) => {
        setPosting(values);
      }}
      layout={"vertical"}
    >
      <Form.Item
        label={"Nro. Cheque"}
        name={"number"}
        rules={[
          {
            required: true,
            message: "Por favor complete el numero del cheque",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Fecha"}
        name={"date"}
        rules={[{ required: true, message: "Por favor indique la fecha" }]}
      >
        <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label={"Banco"}
        name={"bank"}
        rules={[
          { required: true, message: "Por favor complete el nombre del Banco" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={"Valor"}
        name={"amount"}
        rules={[
          { required: true, message: "Por favor indique el valor del cheque" },
        ]}
      >
        <InputNumber
          parser={currencyParser}
          formatter={currencyFormat}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};
export default ReceiptCheckForm;
