import { Card, Input, Form, Button, message, Checkbox } from "antd"
import { useContext, useEffect } from "react"
import { api } from "../utils/api"
import { BrowserContext } from "../utils/browserContext"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { Redirect, useHistory } from "react-router-dom"

const LoginLayout = () => {
  const [browserData, setBrowserData] = useContext(BrowserContext)
  const history = useHistory()

  useEffect(() => {
    document.title = "Login"
  }, [])

  function handleLogin(values) {
    api
      .post("/auth/login", {
        username: values.username,
        password: values.password,
      })
      .then((user) => {
        user.roles = user.roles.map((idx) => idx.role)
        setBrowserData({
          ...browserData,
          user: {
            ...user,
          },
          login: {
            remember: values.remember,
            username: values.remember ? values.username : "",
          },
        })
        history.push("/")
      })
      .catch((err) => {
        message.error(
          err?.message ??
          "Ocurrio un problema con la requisición, favor intentar nuevamente"
        )
      })
  }

  const loginCard = (
    <Card
      title="Login"
      style={{ width: "400px", margin: "auto", marginTop: "128px" }}
    >
      <Form
        name="Login"
        onFinish={handleLogin}
        initialValues={{
          remember: browserData.login?.remember ?? false,
          username: browserData.login?.username ?? "",
        }}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Por favor tipea tu usuario." }]}
        >
          <Input prefix={<UserOutlined />} placeholder={"Usuario"} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Por favor tipea tu contraseña." },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder={"Contraseña"}
          />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Guardar usuário?</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            Entrar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )

  return browserData?.user ?? false ? (
    <Redirect to={{ pathname: "/public" }} />
  ) : (
    loginCard
  )
}

export default LoginLayout
