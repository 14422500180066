import { Button, DatePicker, Form, message, Select } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import DebounceSelect from "../../../components/search/debounceSelect";
import { api } from "../../../utils/api";
import { fetchEntities } from "../../../utils/selectFetchers";

const ReceiptForm = ({ onFormSuccess, drawer }) => {
  const [receiptForm] = Form.useForm();
  const [posting, setPosting] = useState(null);
  const [postEnd, setPostEnd] = useState(false);

  useEffect(() => {
    if (posting) {
      const postBody = {
        type: posting.type,
        entityId: posting.entityId,
        date: posting.date,
      };

      if (drawer) {
        postBody["drawerId"] = drawer.id;
      }

      api
        .post(`/receipts`, postBody)
        .then((res) => {
          message.success("Recibo Creado");
          setPosting(null);
          setPostEnd(true);
          drawer ? onFormSuccess(res.id) : onFormSuccess();
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  }, [posting, drawer, onFormSuccess]);

  useEffect(() => {
    if (postEnd) {
      receiptForm.resetFields();
      setPostEnd(false);
    }
  }, [postEnd, receiptForm, onFormSuccess, drawer]);

  return (
    <Form
      form={receiptForm}
      name={"ReceiptForm"}
      onFinish={(values) => {
        setPosting({
          type: values.type,
          entityId: values.entity.key,
          date: dayjs(values.date).format("YYYY-MM-DD"),
        });
      }}
      layout={"vertical"}
    >
      <Form.Item
        label={"Tipo"}
        name={"type"}
        rules={[
          { required: true, message: "Por favor seleciona el tipo de recibo" },
        ]}
      >
        <Select placeholder={"Seleciona el tipo"}>
          <Select.Option value={"CHARGE"}>COBRO</Select.Option>
          <Select.Option value={"PAYMENT"}>PAGO</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={"Entidad"}
        name={"entity"}
        rules={[{ required: true, message: "Por favor seleciona la Entidad" }]}
      >
        <DebounceSelect
          showSearch
          fetchOptions={fetchEntities}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label={"Fecha"}
        name={"date"}
        rules={[{ required: true, message: "Por favor seleciona la Fecha" }]}
      >
        <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ReceiptForm;
