import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  message,
  PageHeader,
  Skeleton,
  Tabs,
  Table,
  Drawer,
  Modal,
  Form,
  InputNumber,
  Dropdown,
  Menu,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import BillStatusTag from "../../../components/tags/billStatusTag";
import ReceiptsTypeTag from "../../../components/tags/receiptTypeTag";
import { api, apiDefaults } from "../../../utils/api";
import { currencyFormat, currencyParser } from "../../../utils/formaters";
import ReceiptCheckForm from "./receiptCheckForm";
import ReceiptPaymentForm from "./receiptPaymentForm";
import confirm from "antd/lib/modal/confirm";

const ReceiptsDetails = () => {
  const { id } = useParams();
  const [receipt, setReceipt] = useState(null);
  const [paymentCols, setPaymentCols] = useState([]);
  const [paymentDrawer, setPaymentDrawer] = useState(false);
  const [cashModal, setCashModal] = useState(false);
  const [cashForm] = Form.useForm();
  const [postingCash, setPostingCash] = useState(null);
  const [postingCashEnd, setPostingCashEnd] = useState(false);
  const [checkCols, setCheckCols] = useState([]);
  const [checkDrawer, setCheckDrawer] = useState(false);

  function printReceipt() {
    confirm({
      title: "Desea imprimir el Recibo?",
      icon: <PrinterOutlined />,
      okText: "Si",
      cancelText: "No",
      onOk: () => {
        window
          .open(`${apiDefaults.baseURL}/receipts/${receipt.id}/pdf`, "_blank")
          .focus();
      },
    });
  }

  function cancelReceipt() {
    api
      .delete(`/receipts/${receipt.id}/cancel`)
      .then((res) => {
        message.success("Fue canelado el recibo");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        fetchReceipt();
      });
  }

  function completeReceipt() {
    api
      .post(`/receipts/${receipt.id}/complete`)
      .then((res) => {
        message.success("Recibo completado");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        cbFetchReceipt();
      });
  }

  function fetchReceipt() {
    api
      .get(`/receipts/${id}`)
      .then((res) => setReceipt(res))
      .catch((err) => {
        message.error(err.message);
      });
  }

  const cbFetchReceipt = useCallback(fetchReceipt, [id]);

  function removePayment(payment) {
    api
      .delete("/receipts/payments", {
        receiptId: receipt.id,
        billId: payment.bill.id,
      })
      .then((res) => {
        message.success("Pago removido");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        cbFetchReceipt();
      });
  }

  const cbRemovePayment = useCallback(removePayment, [cbFetchReceipt, receipt]);

  function removeCheck(check) {
    api
      .delete("/receipts/checks", {
        receiptId: receipt.id,
        checkId: check.id,
      })
      .then((res) => {
        message.success("Fue removido el cheque");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        cbFetchReceipt();
      });
  }

  const cbRemoveCheck = useCallback(removeCheck, [receipt, cbFetchReceipt]);

  useEffect(() => {
    if (postingCash) {
      api
        .post(`/receipts/${receipt.id}/cash`, {
          amount: postingCash.amount,
        })
        .then((res) => {
          message.success("Valor definidio");
          setPostingCash(null);
          setPostingCashEnd(true);
        })
        .catch((err) => {
          message.error(err.message);
          cbFetchReceipt();
        });
    }
  }, [postingCash, cbFetchReceipt, receipt]);

  useEffect(() => {
    if (postingCashEnd) {
      cashForm.resetFields();
      cbFetchReceipt();
      setPostingCashEnd(false);
      setCashModal(false);
    }
  }, [postingCashEnd, cashForm, cbFetchReceipt]);

  useEffect(() => {
    cbFetchReceipt();
  }, [cbFetchReceipt]);

  useEffect(() => {
    if (receipt) {
      document.title = `Recibo ${receipt.id}`;
    } else {
      document.title = "Cargando...";
    }
  }, [id, receipt]);

  useEffect(() => {
    const basePaymentCols = [
      {
        title: "Cod. Factura",
        dataIndex: "id",
        width: 100,
        render: (_, row) => row.bill.id,
      },
      {
        title: "Fecha Factura",
        dataIndex: "id",
        width: 200,
        render: (_, row) => dayjs(row.bill.date).format("DD/MM/YYYY"),
      },
      {
        title: "Nro. Factura",
        dataIndex: "id",
        render: (_, row) => (
          <Link
            to={`/${row.type === "PURCHASE" ? "purchases" : "sales"}/${
              row.bill.id
            }`}
          >
            {row.bill.factura} <EyeOutlined />
          </Link>
        ),
      },
      {
        title: "Pago",
        dataIndex: "amount",
        render: (_, row) => currencyFormat(row.amount),
      },
    ];
    let payCols = [...basePaymentCols];
    if (receipt?.status === "PENDING") {
      payCols.push({
        title: "Acciones",
        dataIndex: "id",
        width: 80,
        render: (_, row) => (
          <Button type={"link"} danger onClick={() => cbRemovePayment(row)}>
            <DeleteOutlined />
          </Button>
        ),
      });
    }

    const baseCheckCols = [
      { title: "id", dataIndex: "id" },
      { title: "Numero", dataIndex: "number" },
      {
        title: "Fecha",
        dataIndex: "date",
        render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
      },
      {
        title: "Banco",
        dataIndex: "bank",
      },
      {
        title: "Valor",
        dataIndex: "amount",
        render: (amount, _) => currencyFormat(amount),
      },
    ];

    let chCols = [...baseCheckCols];
    if (receipt?.status === "PENDING") {
      chCols.push({
        title: "Acciones",
        dataIndex: "id",
        render: (_, row) => (
          <Button type={"link"} danger onClick={() => cbRemoveCheck(row)}>
            <DeleteOutlined />
          </Button>
        ),
      });
    }

    setPaymentCols(payCols);
    setCheckCols(chCols);
  }, [receipt, cbRemovePayment, cbRemoveCheck]);

  if (receipt) {
    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={"Recibo " + receipt.id}
        tags={<BillStatusTag status={receipt.status} />}
        extra={[
          receipt.status === "PENDING" ? (
            <Button key={"complete"} type={"primary"} onClick={completeReceipt}>
              <CheckOutlined />
              Finalizar
            </Button>
          ) : (
            <></>
          ),
          receipt.status !== "CANCELED" ? (
            <Button
              key={"cancel"}
              type={"primary"}
              danger
              onClick={() => cancelReceipt()}
            >
              <DeleteOutlined /> Cancelar
            </Button>
          ) : (
            <></>
          ),
          <Dropdown
            key={"moreOptions"}
            overlay={
              <Menu>
                <Menu.Item onClick={() => printReceipt()}>
                  <PrinterOutlined /> Imprimir
                </Menu.Item>
              </Menu>
            }
          >
            <Button style={{ border: "none", padding: 0 }}>
              <EllipsisOutlined
                style={{ fontSize: 20, verticalAlign: "top" }}
              />
            </Button>
          </Dropdown>,
        ]}
      >
        {/* {JSON.stringify(receipt, null, '\t')} */}
        <Descriptions bordered size={"small"}>
          <Descriptions.Item label={"Codigo"}>{receipt.id}</Descriptions.Item>
          <Descriptions.Item label={"Tipo"}>
            <ReceiptsTypeTag type={receipt.type} />
          </Descriptions.Item>
          <Descriptions.Item label={"Fecha"}>
            {dayjs(receipt.date).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item span={2} label={"Entidad"}>
            {`${receipt.entity.id} - ${receipt.entity.name}`}
          </Descriptions.Item>
          <Descriptions.Item label={"Total"}>
            {currencyFormat(receipt.total)}
          </Descriptions.Item>
          <Descriptions.Item label={"Nro. Pagos"}>
            {currencyFormat(
              receipt.payments
                .map((p) => p.amount)
                .reduce((acc, cur) => acc + cur, 0)
            )}
          </Descriptions.Item>
          <Descriptions.Item label={"Total Efectivo"}>
            {currencyFormat(receipt.totalCash)}
            <Button
              type={"link"}
              style={{ padding: 0, border: "none", marginLeft: 8 }}
              onClick={() => {
                setCashModal(true);
              }}
            >
              <EditOutlined />
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label={"Total Cheques"}>
            {currencyFormat(receipt.totalCheck)}
          </Descriptions.Item>
        </Descriptions>
        <Tabs defaultActiveKey={"payments"}>
          <Tabs.TabPane tab={"Pagos"} key={"payments"}>
            {receipt.status === "PENDING" ? (
              <Button
                type={"primary"}
                onClick={() => setPaymentDrawer(true)}
                style={{ float: "right", marginBottom: 10 }}
              >
                <PlusOutlined /> Adicionar Item
              </Button>
            ) : (
              ""
            )}
            <Table
              size={"small"}
              dataSource={receipt.payments}
              pagination={false}
              columns={paymentCols}
              rowKey={"id"}
            />
            <Drawer
              title={"Formulario de Pago"}
              closable={false}
              visible={paymentDrawer}
              onClose={() => setPaymentDrawer(false)}
              width={450}
            >
              <ReceiptPaymentForm
                receipt={receipt}
                onFormSuccess={() => {
                  cbFetchReceipt();
                  setPaymentDrawer(false);
                }}
              />
            </Drawer>
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Cheques"} key={"checks"}>
            {receipt.status === "PENDING" ? (
              <Button
                type={"primary"}
                style={{ float: "right", marginBottom: 10 }}
                onClick={() => setCheckDrawer(true)}
              >
                <PlusOutlined /> Adicionar Cheque
              </Button>
            ) : (
              <></>
            )}
            <Table
              size={"small"}
              dataSource={receipt.checks}
              pagination={false}
              columns={checkCols}
              rowKey={"id"}
            />
          </Tabs.TabPane>
        </Tabs>
        <Modal
          visible={cashModal}
          title={"Definir Total Efectivo"}
          onCancel={() => setCashModal(false)}
          footer={null}
        >
          <Form
            form={cashForm}
            name={"CashForm"}
            onFinish={(values) => {
              setPostingCash(values);
            }}
            layout={"vertical"}
          >
            <Form.Item label={"Cantidad"} name={"amount"}>
              <InputNumber
                formatter={currencyFormat}
                parser={currencyParser}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Drawer
          title={"Formulario de Cheque"}
          visible={checkDrawer}
          closable={false}
          onClose={() => setCheckDrawer(false)}
          width={450}
        >
          <ReceiptCheckForm
            receipt={receipt}
            onFormSuccess={() => {
              setCheckDrawer(false);
              cbFetchReceipt();
            }}
          />
        </Drawer>
      </PageHeader>
    );
  } else {
    return <Skeleton active />;
  }
};

export default ReceiptsDetails;
