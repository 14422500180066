import { useContext, useState } from "react";
import {
  Col,
  Form,
  message,
  Row,
  List,
  Button,
  Modal,
  InputNumber,
  Skeleton,
  Descriptions,
} from "antd";
import { api } from "../../../utils/api";
import { useCallback, useEffect } from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { currencyFormat, currencyParser } from "../../../utils/formaters";
import { BrowserContext } from "../../../utils/browserContext";

const ItemPricePane = ({ itemId, editable = true }) => {
  const [prices, setPrices] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [priceForm] = Form.useForm();
  const [priceBranch, setPriceBranch] = useState(null);
  const [priceOrder, setPriceOrder] = useState(null);
  const [{ user }] = useContext(BrowserContext);
  const [item, setItem] = useState(null);

  function showPriceModal(branchId, order = null) {
    setPriceBranch(branchId);
    const branchPrices = prices.find((b) => b.id === branchId).prices;
    if (order) {
      priceForm.setFieldsValue({
        price: branchPrices.find((p) => p.order === order).value,
      });
      setPriceOrder(order);
    } else {
      const branchOrders = branchPrices.map((p) => p.order);
      const maxOrder = branchOrders.length > 0 ? Math.max(...branchOrders) : 0;
      setPriceOrder(maxOrder + 1);
    }
    setModalVisible(true);
  }

  function submitPrice(values) {
    if (!user.roles.includes("ADM")) {
      message.warn("Es necesario el grupo administrativo en usuario");
      return;
    }

    if (!priceBranch || !priceOrder) {
      message.error("Ocurrio un error al salva el precio");
      return;
    }

    api
      .post("/catalog/prices", {
        branchId: priceBranch,
        itemId: itemId,
        order: priceOrder,
        value: values.price,
      })
      .then((res) => {
        message.success("Precio modificado");
        priceForm.resetFields();
        setModalVisible(false);
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        fetchPrices();
      });
  }

  function removePrice(branchId, order) {
    if (!user.roles.includes("ADM")) {
      message.warn("Es necesario el grupo administrativo en usuario");
      return;
    }

    api
      .delete("/catalog/prices", {
        branchId: branchId,
        itemId: itemId,
        order: order,
      })
      .then((res) => {
        message.success("Precio removido");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        fetchPrices();
      });
  }

  function fetchPrices() {
    api
      .get(`/catalog/prices/${itemId}`)
      .then((res) => {
        setPrices(res);
      })
      .catch((err) => {
        message.error(err.message);
        setPrices([]);
      });
  }

  const cbFetchPrices = useCallback(fetchPrices, [itemId]);

  useEffect(() => {
    cbFetchPrices();
  }, [cbFetchPrices]);

  useEffect(() => {
    api
      .get(`/catalog/${itemId}`)
      .then((res) => {
        setItem(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }, [itemId]);

  if (prices) {
    return (
      <>
        <Row>
          <Descriptions
            size={"small"}
            style={{
              width: "60%",
            }}
          >
            <Descriptions.Item label={"40%"}>
              {currencyFormat(Math.round(item?.cost * 1.4))}
            </Descriptions.Item>
            <Descriptions.Item label={"30%"}>
              {currencyFormat(Math.round(item?.cost * 1.3))}
            </Descriptions.Item>
            <Descriptions.Item label={"25%"}>
              {currencyFormat(Math.round(item?.cost * 1.25))}
            </Descriptions.Item>
            <Descriptions.Item label={"20%"}>
              {currencyFormat(Math.round(item?.cost * 1.2))}
            </Descriptions.Item>
            <Descriptions.Item label={"15%"}>
              {currencyFormat(Math.round(item?.cost * 1.15))}
            </Descriptions.Item>
            <Descriptions.Item label={"10%"}>
              {currencyFormat(Math.round(item?.cost * 1.1))}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row gutter={16} style={{ marginTop: 8 }}>
          {prices.map((branch) => (
            <Col key={branch.id} span={12}>
              <List
                header={
                  <span>
                    {branch.code} - {branch.name}
                  </span>
                }
                footer={
                  editable ? (
                    <Button
                      type={"link"}
                      onClick={() => showPriceModal(branch.id)}
                    >
                      <PlusOutlined /> Adicionar Precio
                    </Button>
                  ) : null
                }
                bordered
                dataSource={branch.prices}
                renderItem={(price) => (
                  <List.Item>
                    {`Precio ${price.order}: ${currencyFormat(price.value)}`}
                    {editable ? (
                      <>
                        <Button
                          type={"link"}
                          onClick={() => showPriceModal(branch.id, price.order)}
                        >
                          <EditOutlined />
                        </Button>
                        <Button
                          type={"link"}
                          danger
                          style={{ float: "right" }}
                          onClick={() => removePrice(branch.id, price.order)}
                        >
                          <DeleteOutlined />
                        </Button>
                        {item?.cost ? (
                          <span style={{ float: "right", marginRight: 8 }}>
                            {Math.round((price.value / item.cost - 1) * 100, 2)}
                            %
                          </span>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </List.Item>
                )}
              />
            </Col>
          ))}
          <Modal
            visible={modalVisible}
            title={"Formulario de precio"}
            onCancel={() => setModalVisible(false)}
            footer={null}
          >
            <Form
              form={priceForm}
              name={"priceForm"}
              onFinish={submitPrice}
              layout={"vertical"}
            >
              <Form.Item
                label={"Precio"}
                name={"price"}
                rules={[
                  { required: true, message: "Por favor indique el precio" },
                ]}
              >
                <InputNumber
                  min={0}
                  formatter={currencyFormat}
                  parser={currencyParser}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item>
                <Button type={"primary"} htmlType={"submit"}>
                  Salvar
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </Row>
      </>
    );
  } else {
    return <Skeleton active />;
  }
};

export default ItemPricePane;
