import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, message, PageHeader, Table } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BillStatusTag from "../../../components/tags/billStatusTag";
import CreditNoteTypeTag from "../../../components/tags/creditNoteTypeTag";
import { api } from "../../../utils/api";
import { currencyFormat } from "../../../utils/formaters";

const CreditNotesPage = () => {
  const [loading, setLoading] = useState(false);
  const [creditNotes, setCreditNotes] = useState([]);

  function fetchCreditNotes() {
    setLoading(true);
    api
      .get("/credit_notes")
      .then((res) => setCreditNotes(res))
      .catch((err) => message.error(err.message))
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    document.title = "Notas de Crédito";
  }, []);

  useEffect(() => {
    fetchCreditNotes();
  }, []);

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Notas de Crédito"}
    >
      <Table
        loading={loading}
        dataSource={creditNotes}
        columns={[
          { title: "Codigo", dataIndex: "id" },
          {
            title: "Tipo",
            dataIndex: "type",
            render: (type, _) => <CreditNoteTypeTag type={type} />,
          },
          {
            title: "Status",
            dataIndex: "status",
            width: 120,
            render: (status, _) => <BillStatusTag status={status} />,
          },
          {
            title: "Entidad",
            dataIndex: "id",
            render: (_, row) => row.entity.name,
          },
          {
            title: "Total",
            dataIndex: "total",
            render: (total, _) => currencyFormat(total),
          },
          {
            title: "Acciones",
            dataIndex: "id",
            width: 120,
            render: (_, row) => (
              <Button>
                <Link to={"/credit_notes/" + row.id}>
                  {" "}
                  <EyeOutlined /> Visualizar
                </Link>
              </Button>
            ),
          },
        ]}
      />
    </PageHeader>
  );
};

export default CreditNotesPage;
