import {
  message,
  Skeleton,
  Form,
  InputNumber,
  Button,
  Table,
  Col,
  Row,
  Radio,
  Select,
  Modal,
  Input
} from "antd"
import { useCallback, useEffect, useRef } from "react"
import { useState } from "react"
import { useParams } from "react-router"
import DebounceSelect from "../../components/search/debounceSelect"
import { api, apiDefaults } from "../../utils/api"
import { currencyFormat, currencyParser } from "../../utils/formaters"
import { fetchEntities, fetchItems } from "../../utils/selectFetchers"
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import useSessionState from "../../utils/useSessionState"
import DescriptionUpdateModal from "../../components/billItem/descriptionUpdateModal"

const SaleScreen = () => {
  const { id: branchId } = useParams()
  const [branch, setBranch] = useState(null)
  const [bill, setBill] = useSessionState("sale_screen_bill", null)
  const [itemForm] = Form.useForm()
  const itemSelect = useRef(null)
  const [selectedItemOption, setSelectedItemOption] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [defaultPrice, setDefaultPrice] = useState(1)
  const [defaultPriceModal, setDefaultPriceModal] = useState(null)
  const [updatePriceForm] = Form.useForm()
  const [updatePriceModal, setUpdatePriceModal] = useState(false)
  const [selectedBillItemPrice, setSelectedBillItemPrice] = useState(null)
  const [closeBillModal, setCloseBillModal] = useState(null)
  const [closeBillForm] = Form.useForm()
  const selectBillCredit = useRef(null)
  const [needFetchBill, setNeedFetchBill] = useState(false)
  const [selectedBillItemDescription, setSelectedBillItemDescription] = useState(null)
  const [descriptionModal, setDescriptionModal] = useState(false)
  const [batchModal, setBatchModal] = useState(false)
  const [batchForm] = Form.useForm()
  const [batchDataSave, setBatchDataSave] = useState(null)
  const batchSelectRef = useRef()

  function fetchBill() {
    api
      .get(`/bills/${bill.id}`)
      .then((res) => setBill(res))
      .catch((err) => {
        message.error("Ocurrio un erro al atualziar la venta")
      })
  }

  const cbFetchBill = useCallback(fetchBill, [bill, setBill])

  function createBill(callback) {
    api
      .post("/bills", {
        branchId: branch.id,
        type: "SALE",
        credit: false,
        date: dayjs(Date.now()).format("YYYY-MM-DD"),
        entityId: 1,
      })
      .then((res) => {
        setBill(res)
        message.success("Fue iniciada una venta. Cod: " + res.id)
        callback(res.id)
      })
  }

  function handleBatchSelected(values) {
    if (values?.batch) {
      addItem(batchDataSave.fields, batchDataSave.billId, values.batch)
      batchForm.resetFields()
      setBatchDataSave(null)
      setBatchModal(false)
    } else {
      message.error("Error al selecionar el lote")
    }

  }

  function addItem(fields, billId, batch = null) {
    const price = selectedItem.prices.find(
      (p) => p.branch.id === branch.id && p.order === fields.price
    )

    const reqBody = {
      itemId: selectedItem.id,
      billId: billId,
      quantity: fields.quantity,
      price: price.value
    }

    if (selectedItem.batchControl && !batch) {
      setBatchDataSave({ fields, billId })
      setBatchModal(true)
      setTimeout(() => {
        const temp = batchSelectRef.current
        temp.focus()
      }, 280)
      return
    }

    if (batch) {
      reqBody['batch'] = batch
    }

    api
      .put("/bills/items", reqBody)
      .then((res) => {
        message.success("Adicionado item")
        setSelectedItemOption(null)
        itemForm.resetFields()
        itemSelect?.current?.focus()
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => {
        setNeedFetchBill(true)
      })
  }

  function handleItemFormSubmit(values) {
    if (!bill) {
      createBill((billId) => addItem(values, billId))
    } else {
      addItem(values, bill.id)
    }
  }

  function removeItem(rowId) {
    api
      .delete(`/bills/items/${rowId}`)
      .then((res) => {
        message.success("Item removido")
      })
      .catch((err) => message.error(err.message))
      .finally(() => fetchBill())
  }

  function onCloseUpdatePriceModal() {
    setSelectedBillItemPrice(null)
    setUpdatePriceModal(false)
    updatePriceForm.resetFields()
  }

  function showUpdatePriceModal(billItem) {
    setSelectedBillItemPrice(billItem)
    setUpdatePriceModal(true)
  }

  function showUpdateDescription(billItem) {
    setSelectedBillItemDescription(billItem)
    setDescriptionModal(true)
  }


  function updateItemPrice(billItemId, price) {
    api
      .patch(`/bills/items/${billItemId}`, {
        value: price,
      })
      .then((res) => {
        message.success("precio atualizado")
      })
      .catch((err) => message.error(err.message))
      .finally(() => {
        fetchBill()
        onCloseUpdatePriceModal()
      })
  }

  function handleUpdatePriceForm(values) {
    updateItemPrice(selectedBillItemPrice.id, values.price)
  }

  function handleCloseBillForm(values) {
    api
      .patch(`/bills/${bill.id}`, {
        entityId: values.client.value,
        credit: values.credit,
      })
      .then((res) => {
        api
          .post(`/bills/${bill.id}/complete`)
          .then((res) => {
            message.success("Venta finalizada")
            window
              .open(`${apiDefaults.baseURL}/bills/${bill.id}/pdf`, "_blank")
              .focus()

            setBill(null)
            setCloseBillModal(false)
            closeBillForm.resetFields()
          })
          .catch((err) => {
            message.error(err.message)
          })
      })
      .catch((err) => {
        message.error(err.message)
        fetchBill()
      })
  }

  useEffect(() => {
    if (needFetchBill && bill) {
      setNeedFetchBill(false)
      cbFetchBill()
    }
  }, [needFetchBill, bill, cbFetchBill])

  useEffect(() => {
    if (closeBillModal) {
      selectBillCredit?.current?.focus()
    }
  }, [closeBillModal])

  useEffect(() => {
    document.title = "Pantalla de venta"
    api
      .get(`/branches/${branchId}`)
      .then((res) => setBranch(res))
      .catch((err) => {
        message.error("Ocurrio un error al abrir ventana de venta")
      })
  }, [branchId])

  useEffect(() => {
    document.title = branch?.code + " VENTA"
  }, [branch])

  useEffect(() => {
    if (selectedItemOption) {
      api
        .get(`/catalog/${selectedItemOption.value}`)
        .then((res) => {
          setSelectedItem(res)
        })
        .catch((err) => {
          message.error("Ocurrio un error al buscar el item")
        })
    } else {
      setSelectedItem(null)
    }
  }, [selectedItemOption])

  useEffect(() => {
    if (selectedItem) {
      const pricesList = selectedItem.prices.filter(
        (p) => p.branch.id === branch.id
      )
      const pricesLength = pricesList.length
      const priceOrder =
        pricesLength >= defaultPrice ? defaultPrice : pricesLength
      itemForm.setFieldsValue({
        price: priceOrder,
        total:
          itemForm.getFieldValue("quantity") *
          (pricesList[priceOrder - 1]?.value || 0),
      })
    }
  }, [selectedItem, branch, defaultPrice, itemForm])

  if (branch) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Form
          form={itemForm}
          name={"itemForm"}
          layout={"vertical"}
          onFinish={handleItemFormSubmit}
          style={{
            margin: "8px 8px 0px 8px",
            padding: 12,
            backgroundColor: "white",
          }}
          initialValues={{
            quantity: 1,
            price: defaultPrice,
          }}
          onChange={(v) => {
            if (selectedItem) {
              itemForm.setFieldsValue({
                total: Math.round(
                  (selectedItem.prices.filter((p) => p.branch.id === branch.id)[
                    itemForm.getFieldValue("price") - 1
                  ]?.value || 0) * (itemForm.getFieldValue("quantity") || 0)
                ),
              })
            }
          }}
        >
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                label={"Item"}
                name={"item"}
                rules={[{ required: true, message: "Seleciona un item" }]}
              >
                <DebounceSelect
                  searchRef={itemSelect}
                  showSearch
                  fetchOptions={fetchItems}
                  onChange={(value) => setSelectedItemOption(value)}
                  value={selectedItemOption}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label={"Cantidad"}
                name={"quantity"}
                rules={[
                  {
                    required: true,
                    message: "Es necessario especificar la cantidad",
                  },
                ]}
                help={
                  selectedItem && selectedItem?.type === "PRODUCT" ? (
                    `Disponible: ${selectedItem.stocks?.find((s) => s.branchId === branch.id)
                      .quantity
                    }`
                  ) : (
                    <></>
                  )
                }
              >
                <InputNumber
                  decimalSeparator={","}
                  min={0}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              {selectedItem ? (
                <>
                  <Form.Item
                    label={"Precio"}
                    name={"price"}
                    rules={[
                      {
                        required: true,
                        message: "Es necessario selecionar el precio",
                      },
                    ]}
                  >
                    <Radio.Group>
                      {selectedItem.prices
                        .filter((p) => p.branch.id === branch.id)
                        .map((price) => (
                          <Radio key={price.id} value={price.order}>
                            {currencyFormat(price.value)}
                          </Radio>
                        ))}
                    </Radio.Group>
                  </Form.Item>
                </>
              ) : (
                <></>
              )}
            </Col>
            <Col span={3}>
              {selectedItem ? (
                <Form.Item label="Total" name="total">
                  <InputNumber
                    formatter={currencyFormat}
                    parser={currencyParser}
                    style={{ width: "100%" }}
                    disabled
                  />
                </Form.Item>
              ) : (
                <></>
              )}
            </Col>
            <Col span={3}>
              <Form.Item label={" "}>
                <Button
                  type={"primary"}
                  htmlType={"submit"}
                  style={{ width: "100%" }}
                >
                  Adicionar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          size={"small"}
          dataSource={bill?.items || []}
          pagination={false}
          rowKey={"id"}
          scroll={{
            y: 400,
          }}
          columns={[
            { title: "Cantidad", dataIndex: "quantity" },
            {
              title: "Descripción",
              dataIndex: "description",
              render: (data, row) => (<div>
                {data}
                <Button type="link" onClick={() => showUpdateDescription(row)}><EditOutlined /></Button>
              </div>)
            },
            {
              title: "Precio",
              dataIndex: "price",
              render: (data, row) => (
                <div>
                  {currencyFormat(parseInt(data))}
                  <Button
                    type={"link"}
                    onClick={() => showUpdatePriceModal(row)}
                  >
                    <EditOutlined />
                  </Button>
                </div>
              ),
            },
            {
              title: "Total",
              dataIndex: "id",
              render: (_, row) =>
                currencyFormat(
                  Math.round(parseFloat(row.price) * parseFloat(row.quantity))
                ),
            },
            {
              title: "Acciones",
              dataIndex: "id",
              width: 80,
              render: (_, row) => (
                <Button type={"link"} danger onClick={() => removeItem(row.id)}>
                  <DeleteOutlined />
                </Button>
              ),
            },
          ]}
          style={{ margin: 8 }}
        />
        <Row
          style={{
            margin: 0,
            position: "fixed",
            bottom: 0,
            width: "100%",
          }}
        >
          <Col span={24} style={{ margin: 0 }}>
            <Row
              style={{
                margin: 0,
                backgroundColor: "white",
                fontSize: 18,
                padding: 12,
                height: 60,
              }}
              gutter={8}
            >
              <Col
                span={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <Button
                  type={"primary"}
                  style={{ width: "100%" }}
                  onClick={() => setCloseBillModal(true)}
                  disabled={!bill || bill?.items?.length === 0}
                >
                  Finalizar
                </Button>
              </Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>Cod. Venta: {bill?.id || "No iniciada"}</span>
              </Col>
              <Col
                span={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>Num. Items: {bill?.items?.length || 0}</span>
              </Col>
              <Col
                span={10}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 24,
                }}
              >
                <span>TOTAL: </span>
                <span style={{ width: "100%", textAlign: "right" }}>
                  {currencyFormat(bill?.total || 0)}
                </span>
              </Col>
            </Row>
          </Col>{" "}
          <Col
            span={24}
            style={{
              padding: 3,
              backgroundColor: "#40A9FF",
              color: "white",
              fontSize: 12,
            }}
          >
            <Row style={{ margin: 0 }}>
              <Col span={6}>{`${branch.code} - ${branch.name}`}</Col>
              <Col span={12}></Col>
              <Col span={3}>
                <span
                  style={{
                    textAlign: "right",
                    display: "block",
                    width: "100%",
                  }}
                  className={"selectable-footer-bar"}
                  onClick={() => window.open(`/price_search/${branch.id}`)}
                >
                  Consulta de Precios
                </span>
              </Col>
              <Col span={3}>
                <span
                  style={{
                    textAlign: "right",
                    display: "block",
                    width: "100%",
                  }}
                  className={"selectable-footer-bar"}
                  onClick={() => setDefaultPriceModal(true)}
                >
                  Precio padrón: {defaultPrice} <EditOutlined />
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          visible={defaultPriceModal}
          title={"Precio pre selecionado"}
          onCancel={() => setDefaultPriceModal(false)}
          footer={null}
        >
          <Form>
            <Form.Item label={"Precio"}>
              <Select
                value={defaultPrice}
                onChange={(value) => setDefaultPrice(value)}
              >
                <Select.Option value={1}>Precio 1</Select.Option>
                <Select.Option value={2}>Precio 2</Select.Option>
                <Select.Option value={3}>Precio 3</Select.Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        <DescriptionUpdateModal
          visible={descriptionModal}
          billItem={selectedBillItemDescription}
          onCancel={() => setDescriptionModal(false)}
          onFinish={() => { setDescriptionModal(false); fetchBill() }}
        />

        <Modal
          visible={updatePriceModal}
          title={"Atualización de Precio"}
          onCancel={() => setUpdatePriceModal(false)}
          footer={null}
        >
          <Form
            form={updatePriceForm}
            name={"UpdatePriceForm"}
            onFinish={handleUpdatePriceForm}
            layout={"vertical"}
          >
            <Form.Item
              label={"Precio"}
              name={"price"}
              rules={[
                { required: true, message: "Por favor indique el precio" },
              ]}
            >
              <InputNumber
                min={1}
                formatter={currencyFormat}
                parser={currencyParser}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={batchModal}
          title="Selecionar Lote"
          onCancel={() => setBatchModal(false)}
          footer={null}
        >
          <Form
            form={batchForm}
            name="batchForm"
            layout="vertical"
            onFinish={handleBatchSelected}
          >
            <Form.Item
              label="Lote"
              name="batch"
              rules={[{ required: true, message: 'Por favor selecionar el Lote' }]}>
              <Select ref={batchSelectRef} showAction="focus">
                {
                  selectedItem?.batches?.filter(b => b.branch_id === branch.id).map(batch => (
                    <Select.Option value={batch.code}>{batch.code} - Stock: {batch.stock}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">Confirmar</Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={closeBillModal}
          title={"Finalizar venta"}
          onCancel={() => setCloseBillModal(false)}
          footer={null}
        >
          <Form
            form={closeBillForm}
            name={"CloseBillForm"}
            layout={"vertical"}
            onFinish={handleCloseBillForm}
            initialValues={{
              credit: false,
            }}
          >
            <Form.Item
              label={"Condición de Pago"}
              name={"credit"}
              rules={[
                {
                  required: true,
                  message: "Por favor seleciona la condición de pago",
                },
              ]}
            >
              <Select ref={selectBillCredit}>
                <Select.Option value={false}>CONTADO</Select.Option>
                <Select.Option value={true}>CREDITO</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={"Cliente"}
              name={"client"}
              rules={[
                { required: true, message: "Por favor seleciona el cliente" },
              ]}
            >
              <DebounceSelect
                showSearch
                fetchOptions={fetchEntities}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  } else {
    return <Skeleton active />
  }
}

export default SaleScreen
