import { EyeOutlined, FileExcelOutlined, SearchOutlined } from "@ant-design/icons"
import { Button, Col, DatePicker, Dropdown, Form, Input, InputNumber, Menu, message, PageHeader, Row, Select, Skeleton, Table } from "antd"
import dayjs from "dayjs"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import BillPaymentStatus from "../../../components/tags/billPaymentStatus"
import BillStatusTag from "../../../components/tags/billStatusTag"
import { api } from "../../../utils/api"

const BillAdvancedSearch = () => {
	const [branches, setBranches] = useState()
	const [billSearchForm] = Form.useForm()
	const [bills, setBills] = useState([])
	const [loading, setLoading] = useState(false)


	useEffect(() => {
		api.get('/branches').then(res => setBranches(res)).catch(err => message.error(err.message))
		document.title = "Busca Avanzada"
	}, [])

	function exportXLS() {
		if (!loading) {
			setLoading(true)
			const formValues = billSearchForm.getFieldsValue(true)
			api.req("post", '/bills/search', {
				...formValues,
				date_range: formValues?.date_range?.map(d => dayjs(d).format('YYYY-MM-DD')),
				xls: true
			}, 'arraybuffer')
				.then(res => {
					const link = document.createElement('a')
					link.href = window.URL.createObjectURL(new Blob([res], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
					link.download = 'Export Facturas'
					link.click()
					window.URL.revokeObjectURL(link.href)
				}).catch(err => {
					message.error(err.message)
				}).finally(() => { setLoading(false) })
		}
	}

	function handleSubmit(values) {
		if (!loading) {
			setLoading(true)
			api.post('/bills/search', {
				...values,
				date_range: values?.date_range?.map(d => dayjs(d).format('YYYY-MM-DD')),
				xls: false
			}).then((res) => {
				setBills(res)
				console.log(res)
			}).catch(err => {
				message.error(err.message)
				setBills([])
			}).finally(() => setLoading(false))
		}
	}

	if (!branches) {
		return <Skeleton active={true} />
	}

	return <PageHeader
		ghost={false}
		onBack={() => window.history.back()}
		title={'Extracto de Facturas'}
		subTitle={'Busca Avanzada de Facturas'}
		extra={[
			<Button type="default" key={"excel_export"} onClick={exportXLS} disabled={!(bills?.length > 0)}><FileExcelOutlined /> Exportar XLS</Button>
		]}
	>
		<Form
			form={billSearchForm}
			name={"BillSearchForm"}
			layout={"vertical"}
			onFinish={handleSubmit}>

			<Row gutter={16}>

				<Col span={4}>
					<Form.Item label={'Codigo'} name={'code'}>
						<InputNumber placeholder="Codigo de Factura" style={{ width: '100%' }} />
					</Form.Item>
				</Col>

				<Col span={6}>
					<Form.Item label={"Tipo de Factura"} name={'type'}>
						<Select placeholder={'Seleciona el Tipo de Factura'} allowClear={true}>
							<Select.Option value={"SALE"}>VENTA</Select.Option>
							<Select.Option value={"PURCHASE"}>COMPRA</Select.Option>
						</Select>
					</Form.Item>
				</Col>

				<Col span={6}>
					<Form.Item label={"Metodo de Pago"} name={'payment_type'}>
						<Select placeholder={'Seleciona el Metodo de Pago'} allowClear={true}>
							<Select.Option value={"CREDIT"}>CREDITO</Select.Option>
							<Select.Option value={"CASH"}>CONTADO</Select.Option>
						</Select>
					</Form.Item>

				</Col>

				<Col span={8}>
					<Form.Item label={'Sucursal'} name={'branches'}>
						<Select placeholder={"Seleciona Sucursal"} allowClear={true} mode="multiple">
							{branches.map(b => (
								<Select.Option value={b.id}>{b.name}</Select.Option>
							))}
						</Select>
					</Form.Item>

				</Col>


				<Col span={6}>
					<Form.Item label={'Fecha'} name={'date_range'}>
						<DatePicker.RangePicker style={{ width: '100%' }} />
					</Form.Item>
				</Col>

				<Col span={3}>
					<Form.Item label={'Cod. Entidad'} name={"entity_id"}>
						<InputNumber style={{ width: '100%' }} placeholder={"eg. 001"} />
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item label={'Nombre Entidad'} name={'entity_name'}>
						<Input placeholder="eg. Jose Fernandes" />
					</Form.Item>
				</Col>


				<Col span={7}>
					<Form.Item label={'Documento Entidad'} name={'entity_document'}>
						<Input placeholder="RUC o C.I." />
					</Form.Item>
				</Col>


				<Col span={8}>
					<Form.Item label={'Numero de Factura'} name={'factura'}>
						<Input placeholder="eg. 001-001-00001" />
					</Form.Item>
				</Col>


				<Col span={8}>

					<Form.Item label={"Estado de la Factura"} name={'status'}>
						<Select placeholder={'Seleciona el Tipo de Factura'} allowClear={true} mode={'multiple'}>
							<Select.Option value={"PENDING"}><BillStatusTag status={'PENDING'} /></Select.Option>
							<Select.Option value={"CANCELED"}><BillStatusTag status={'CANCELED'} /></Select.Option>
							<Select.Option value={"COMPLETED"}><BillStatusTag status={'COMPLETED'} /></Select.Option>
						</Select>
					</Form.Item>

				</Col>


				<Col span={8}>

					<Form.Item label={"Estado de Pago"} name={'payment_status'}>
						<Select placeholder={'Seleciona el Tipo de Factura'} allowClear={true} mode={'multiple'}>
							<Select.Option value={"PENDING"}><BillPaymentStatus paymentStatus={'PENDING'} /></Select.Option>
							<Select.Option value={"PARTIAL"}><BillPaymentStatus paymentStatus={'PARTIAL'} /></Select.Option>
							<Select.Option value={"COMPLETED"}><BillPaymentStatus paymentStatus={'COMPLETED'} /></Select.Option>
						</Select>
					</Form.Item>

				</Col>
				<Col span={19}></Col>
				<Col span={5}>
					<Button type="primary" htmlType="submit" style={{ width: '100%' }}><SearchOutlined /> Buscar</Button>
				</Col>
			</Row>

		</Form>

		<Table size="small"
			loading={loading}
			dataSource={bills}
			rowKey={"id"}
			scroll={{ x: 1300 }}
			pagination={{
				defaultPageSize: 50,
				showSizeChanger: true,
				pageSizeOptions: ["10", "20", "50", "100", "500"],
			}}
			style={{ marginTop: 16 }}
			columns={[
				{ title: "Codigo", dataIndex: "id", width: 80, fixed: "left" },
				{
					title: "Fecha",
					dataIndex: "date",
					width: 120,
					fixed: "left",
					render: (date, _) => dayjs(date).format('DD/MM/YYYY'),
				},
				{ title: 'Tipo', dataIndex: 'type', render: (type, _) => type === 'SALE' ? 'VENTA' : 'COMPRA' },
				{ title: "Sucursal", dataIndex: "id", render: (_, row) => row.branch.code },
				{
					title: "Estado",
					dataIndex: "status",
					width: 140,
					render: (status, _) => <BillStatusTag status={status} />,
				},
				{
					title: "Cond. Pago",
					dataIndex: "credit",
					render: (_, row) => (row.credit ? "Crédito" : "Contado"),
				},
				{
					title: "Nro. Factura",
					dataIndex: "factura",
				},
				{
					title: "Cliente",
					width: 250,
					dataIndex: "id",
					render: (_, row) => row.entity.name,
				},
				{ title: "Doc. Cliente", dataIndex: "entityDocument" },
				{ title: "Total", dataIndex: "total", width: 100, fixed: "right" },
				{
					title: "Acciones",
					dataIndex: "id",
					fixed: "right",
					width: 150,
					render: (_, row) => (

						<Button>
							<a
								href={`/${row.type === 'SALE' ? 'sales' : 'purchases'}/${row.id}`}
								target={"_blank"}
								rel={"noreferrer"}
							>
								<EyeOutlined /> Visualizar
							</a>
						</Button>
					),
				},
			]}
		/>


	</PageHeader>

}

export default BillAdvancedSearch