import {
  Descriptions,
  message,
  PageHeader,
  Skeleton,
  Tabs,
  Table,
  Button,
  Row,
  Col,
  List,
  Drawer,
  Form,
  Select,
  Input,
  InputNumber,
  Space,
  Result,
  Popconfirm,
} from "antd"
import dayjs from "dayjs"
import { useCallback } from "react"
import { useHistory, useParams } from "react-router"
import { useEffect, useState } from "react"
import TagControl from "../../../components/tags/tagControl"
import { api } from "../../../utils/api"
import ItemPricePane from "./itemPricePane"
import {
  currencyFormat,
  currencyFormatDecimal,
} from "../../../utils/formaters"
import BillStatusTag from "../../../components/tags/billStatusTag"
import { ResponsiveContainer } from "recharts/lib/component/ResponsiveContainer"
import { AreaChart } from "recharts/lib/chart/AreaChart"
import { CartesianGrid } from "recharts/lib/cartesian/CartesianGrid"
import { XAxis } from "recharts/lib/cartesian/XAxis"
import { Tooltip } from "recharts/lib"
import { Area } from "recharts/lib/cartesian/Area"
import { YAxis } from "recharts/lib/cartesian/YAxis"
import { LineChart } from "recharts/lib/chart/LineChart"
import { Line } from "recharts/lib/cartesian/Line"
import { CloseOutlined, DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons"

const ItemDetails = () => {
  const { id } = useParams()
  const [item, setItem] = useState(null)
  const [categories, setCategories] = useState([])
  const [history, setHistory] = useState(null)
  const [branches, setBranches] = useState([])
  const [manualBatchDrawer, setManualBatchDrawer] = useState(false)
  const [batchForm] = Form.useForm()
  const [posting, setPosting] = useState(false)

  const urlHistory = useHistory()

  function toggleBatchControl() {
    api.post('/catalog/batches', {
      itemId: id,
      batchControl: !item.batchControl
    }).then(res => {
      message.success("Control de Lotes Actualizado")
      fetchItem()
    }).catch(err => {
      message.error(err.message)
    })
  }

  function addBatch(values) {
    if (!posting) {
      setPosting(true)
      api.post('/catalog/batches/manual_batch', {
        ...values,
        itemId: id
      }).then(res => {
        message.success("Fue Adicionado el Lote al Producto")
        fetchItem()
        setManualBatchDrawer(false)
        batchForm.resetFields()
      }).catch(err => {
        message.error(err.message)
      }).finally(() => {
        setPosting(false)
      })
    }
  }

  function fetchHistory() {
    api
      .get(`/catalog/${id}/history`)
      .then((res) => {
        setHistory(res)
      })
      .catch((err) => {
        message.err(err.message)
      })
  }

  function addCategory(categoryId) {
    api
      .put("/catalog/categories", {
        itemId: id,
        categoryId: categoryId,
      })
      .then((res) => {
        message.success("Fue adicionada la categoria")
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => fetchItem())
  }

  function removeCategory(categoryId) {
    api
      .delete("/catalog/categories", {
        itemId: id,
        categoryId: categoryId,
      })
      .then((res) => {
        message.success("Fue removida la categoria")
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => fetchItem())
  }

  function fetchItem() {
    api
      .get(`/catalog/${id}`)
      .then((res) => setItem(res))
      .catch((err) => {
        message.error(err.message)
        window.history.back()
      })
  }

  const cbFetchItem = useCallback(fetchItem, [id])

  useEffect(() => {
    document.title = "Buscando Item"
    cbFetchItem()
  }, [cbFetchItem])

  useEffect(() => {
    if (item) {
      document.title = item.name
    }
  }, [item])

  useEffect(() => {
    api.get("/categories").then((res) => setCategories(res))
    api.get("/branches").then(res => setBranches(res))
  }, [])

  if (item) {
    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={item.id + " - " + item.name}
        subTitle={"Detalles del Item"}
        extra={[
          history === null ? (
            <Button type={"primary"} onClick={fetchHistory}>
              Buscar Historico
            </Button>
          ) : (
            <></>
          ),
        ]}
        tags={
          <TagControl
            tags={item.categories
              .map((c) => c.category)
              .map((category) => ({
                key: category.id,
                value: category.id,
                name: category.name,
              }))}
            options={categories
              .filter(
                (category) =>
                  !item.categories
                    .map((c) => c.category.id)
                    .includes(category.id)
              )
              .map((category) => ({
                key: category.id,
                value: category.id,
                display: category.name,
              }))}
            onSubmitTag={addCategory}
            onRemoveTag={removeCategory}
          />
        }
      >
        <Descriptions bordered size={"small"}>
          <Descriptions.Item label={"Codigo de Barras"}>
            {item.barcode}
          </Descriptions.Item>

          <Descriptions.Item label={"Nombre"}>{item.name}</Descriptions.Item>
          <Descriptions.Item label={"Tipo"}>
            {item.type === "PRODUCT" ? "PRODUCTO" : "SERVICIO"}
          </Descriptions.Item>
          <Descriptions.Item label={"Descripción"} span={2}>
            {item.description}
          </Descriptions.Item>
          <Descriptions.Item label={"Fecha de creación"}>
            {dayjs(item.createdAt).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label={"Categoria Iva"}>
            {item.iva}
          </Descriptions.Item>
          <Descriptions.Item label={"Stock"}>
            {item.type === "PRODUCT" && item.stockControl
              ? item.stocks
                .map((s) => s.quantity)
                .reduce((acc, cur) => acc + cur, 0)
              : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label={"Costo"}>
            {item.type === "PRODUCT" && item.stockControl
              ? currencyFormat(item.cost)
              : "N/A"}
          </Descriptions.Item>
        </Descriptions>
        <Tabs defaultActiveKey={"prices"}>
          <Tabs.TabPane tab={"Precios"} key={"prices"}>
            <ItemPricePane itemId={item.id} />
          </Tabs.TabPane>
          {item.type === "PRODUCT" ? (
            <Tabs.TabPane tab={"Inventario"} key={"inventory"}>
              <Table
                dataSource={item.stocks}
                rowKey={"branchId"}
                columns={[
                  { title: "Sucursal", dataIndex: "name" },
                  { title: "Cantidad", dataIndex: "quantity" },
                ]}
              />
            </Tabs.TabPane>
          ) : (
            <></>
          )}

          {item.type === 'PRODUCT' && item.stockControl ? (
            <Tabs.TabPane tab="Lotes" key="batches">

              {!item.batchControl ? (
                <Result
                  title="Control de Lotes desabilitado para el producto"
                  extra={
                    <Button type="primary" key="console" onClick={toggleBatchControl}>Activar Control de Lotes</Button>
                  } />
              ) : (
                <>
                  <div style={{ width: '100%', display: 'block', height: 35 }}>
                    <Space align="end" style={{ float: 'right' }}>
                      <Popconfirm
                        key="disableBatchControl"
                        title="Deseas desabilitar el control de Lotes para el PRODUCTO"
                        okText="Si, desactivar."
                        cancelText="No"
                        onConfirm={toggleBatchControl}>
                        <Button type="primary" danger><CloseOutlined /> Desactivar Control de Lotes</Button>
                      </Popconfirm>
                      <Button type="primary" onClick={() => setManualBatchDrawer(true)}><PlusOutlined />Mov. Lote Manual</Button>
                    </Space>
                  </div>
                  <Row gutter={[16, 16]} style={{ marginTop: 8 }}>
                    {
                      branches.map(branch => (
                        <Col key={branch.id} span={12}>
                          <Table
                            size="small"
                            pagination={false}
                            bordered
                            title={() => `${branch.code} - ${branch.name}`}
                            dataSource={item.batches.filter(b => b.branch_id === branch.id)}
                            columns={[
                              { title: 'Codigo', dataIndex: 'code' },
                              { title: 'Stock', dataIndex: 'stock' }
                            ]}
                          />
                        </Col>
                      ))
                    }
                  </Row>

                  <Drawer
                    title="Adicionar Lote Manualmente"
                    closable={false}
                    visible={manualBatchDrawer}
                    onClose={() => setManualBatchDrawer(false)}
                    width={450}
                  >
                    <Form
                      form={batchForm}
                      name={"NewManualBatchForm"}
                      layout="vertical"
                      onFinish={addBatch}
                    >

                      <Form.Item label="Tipo" name="type" rules={[{ required: true, message: 'Selecione el Tipo de Movimiento' }]}>
                        <Select>
                          <Select.Option key="IN">Entrada</Select.Option>
                          <Select.Option key="OUT">Salida</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item label="Sucursal" name="branchId" rules={[{ required: true, message: 'Por favor selecione la Sucursal' }]}>
                        <Select>
                          {branches.map(b => (<Select.Option key={b.id}>{`${b.code} - ${b.name}`}</Select.Option>))}
                        </Select>
                      </Form.Item>

                      <Form.Item label="Lote" name="code" rules={[{ min: 3, message: 'Minimo 3 caracteres' }, { required: true, message: 'Por favor complete el Lote' }]}>
                        <Input />
                      </Form.Item>

                      <Form.Item label="Cantidad" name="quantity" rules={[{ required: true, message: 'Por favor complete la cantidad' }]}>
                        <InputNumber style={{ width: '100%' }} />
                      </Form.Item>

                      <Form.Item>
                        <Button type="primary" htmlType="submit">Salvar</Button>
                      </Form.Item>
                    </Form>

                  </Drawer>
                </>
              )}

            </Tabs.TabPane>
          ) : <></>}

          {history ? (
            <>
              <Tabs.TabPane tab={"Historico Ventas"} key={"sales_history"}>
                <Table
                  dataSource={history?.sales}
                  rowKey={"id"}
                  columns={[
                    { title: "Id", dataIndex: "id" },
                    {
                      title: "Fecha",
                      dataIndex: "date",
                      render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
                    },
                    { title: "Sucursal", dataIndex: "branch" },
                    {
                      title: "Status",
                      dataIndex: "status",
                      render: (status, _) => <BillStatusTag status={status} />,
                    },
                    {
                      title: "Entidad",
                      dataIndex: "entity",
                    },
                    {
                      title: "Cantidad",
                      dataIndex: "quantity",
                    },
                    {
                      title: "Precio",
                      dataIndex: "price",
                      render: (price, _) => currencyFormatDecimal(price),
                    },
                    {
                      title: "Acciones",
                      dataIndex: "id",
                      fixed: "right",
                      width: 150,
                      render: (_, row) => (
                        <Button
                          onClick={() => urlHistory.push(`/sales/${row.id}`)}
                        >
                          <EyeOutlined /> Visualizar{" "}
                        </Button>
                      ),
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Historico Compras"} key={"purchase_history"}>
                <Table
                  dataSource={history?.purchases}
                  rowKey={"id"}
                  columns={[
                    { title: "Id", dataIndex: "id" },
                    {
                      title: "Fecha",
                      dataIndex: "date",
                      render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
                    },
                    { title: "Sucursal", dataIndex: "branch" },
                    {
                      title: "Status",
                      dataIndex: "status",
                      render: (status, _) => <BillStatusTag status={status} />,
                    },
                    {
                      title: "Entidad",
                      dataIndex: "entity",
                    },
                    {
                      title: "Cantidad",
                      dataIndex: "quantity",
                    },
                    {
                      title: "Precio",
                      dataIndex: "price",
                      render: (price, _) => currencyFormatDecimal(price),
                    },

                    {
                      title: "Acciones",
                      dataIndex: "id",
                      fixed: "right",
                      width: 150,
                      render: (_, row) => (
                        <Button
                          onClick={() =>
                            urlHistory.push(`/purchases/${row.id}`)
                          }
                        >
                          <EyeOutlined /> Visualizar{" "}
                        </Button>
                      ),
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={"Historico Transferencias"}
                key={"transferences_history"}
              >
                <Table
                  dataSource={history?.transferences}
                  rowKey={"id"}
                  columns={[
                    { title: "Id", dataIndex: "id" },
                    {
                      title: "Fecha",
                      dataIndex: "date",
                      render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      render: (status, _) => <BillStatusTag status={status} />,
                    },
                    {
                      title: "Origen",
                      dataIndex: "origin",
                    },
                    {
                      title: "Destino",
                      dataIndex: "destination",
                    },
                    {
                      title: "Cantidad",
                      dataIndex: "quantity",
                    },

                    {
                      title: "Acciones",
                      dataIndex: "id",
                      fixed: "right",
                      width: 150,
                      render: (_, row) => (
                        <Button
                          onClick={() =>
                            urlHistory.push(`/transferences/${row.id}`)
                          }
                        >
                          <EyeOutlined /> Visualizar{" "}
                        </Button>
                      ),
                    },
                  ]}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab={"Analitico"} key={"analytic"}>
                <Row gutter={[16, 15]}>
                  <Col span={24}>
                    <div className="featured-card">
                      <span className="featured-card-title">
                        Costo de Compra
                      </span>
                      <ResponsiveContainer width={"100%"} height={188}>
                        <LineChart
                          data={history?.purchases.sort((a, b) =>
                            dayjs(a.date).diff(dayjs(b.date))
                          )}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="date"
                            tickFormatter={(date) =>
                              dayjs(date).format("DD-MM-YY")
                            }
                          />
                          <YAxis />
                          <Tooltip
                            formatter={(value, name, props) => {
                              if (name == "price") {
                                return [currencyFormat(value), "Precio"]
                              }
                            }}
                          />
                          <Line
                            type="monotone"
                            dataKey="price"
                            stroke="#8884d8"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                      <span className="featured-card-desc">
                        Has las ultimas 30 compras
                      </span>
                    </div>
                  </Col>
                </Row>
              </Tabs.TabPane>
            </>
          ) : (
            <></>
          )}
        </Tabs>
      </PageHeader >
    )
  } else {
    return <Skeleton active />
  }
}

export default ItemDetails
