import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Drawer,
  message,
  PageHeader,
  Skeleton,
  Table,
  Form,
  InputNumber,
} from "antd";
import dayjs from "dayjs";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import DebounceSelect from "../../../components/search/debounceSelect";
import { api } from "../../../utils/api";
import { fetchItems } from "../../../utils/selectFetchers";

const CountDetails = () => {
  const { id } = useParams();
  const [count, setCount] = useState(null);
  const [itemDrawer, setItemDrawer] = useState(false);
  const [itemForm] = Form.useForm();
  const [posting, setPosting] = useState(false);
  const [postingEnd, setPostingEnd] = useState(false);

  function removeItem(item) {
    api
      .delete(`/stock_counts/items/${item.id}`)
      .then((res) => {
        message.success("Fue removido el item");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        fetchCount();
      });
  }

  function fetchCount() {
    api
      .get(`/stock_counts/${id}`)
      .then((res) => {
        setCount(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  const cbFetchCount = useCallback(fetchCount, [id]);

  useEffect(() => {
    if (posting) {
      const values = itemForm.getFieldsValue();
      api
        .post("/stock_counts/items", {
          stockCountId: id,
          itemId: values.item.key,
          quantity: values.quantity,
        })
        .then((res) => {
          message.success("Adicionado Item");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setPosting(false);
          setPostingEnd(true);
        });
    }
  }, [posting, cbFetchCount, id, itemForm]);

  useEffect(() => {
    if (postingEnd) {
      itemForm.resetFields();
      cbFetchCount();
      setItemDrawer(false);
      setPostingEnd(false);
    }
  }, [postingEnd, cbFetchCount, itemForm]);

  useEffect(() => {
    cbFetchCount();
  }, [cbFetchCount]);

  useEffect(() => {
    document.title = `${id} - Rec. Stock`;
  }, [id]);

  if (count) {
    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={"Detalles de Recuento de Stock"}
        extra={[
          <Button type={"primary"} onClick={() => setItemDrawer(true)}>
            <PlusOutlined /> Adicionar Item
          </Button>,
        ]}
      >
        <Descriptions bordered>
          <Descriptions.Item label={"Sucursal"}>
            {count.branch.name}
          </Descriptions.Item>
          <Descriptions.Item label={"Fecha"}>
            {dayjs(count.createdAt).format("DD/MM/YYYY")}
          </Descriptions.Item>
        </Descriptions>

        <Table
          dataSource={count.items}
          rowKey={"id"}
          columns={[
            { title: "Codigo", dataIndex: "id" },
            {
              title: "Cod. Producto",
              dataIndex: "id",
              render: (_, row) => row.item.barcode,
            },
            {
              title: "Nombre Producto",
              dataIndex: "id",
              render: (_, row) => row.item.name,
            },
            {
              title: "Cantidad",
              dataIndex: "quantity",
            },
            {
              title: "Acciones",
              dataIndex: "id",
              render: (_, row) => (
                <Button type={"link"} danger onClick={() => removeItem(row)}>
                  <DeleteOutlined />
                </Button>
              ),
            },
          ]}
        />
        <Drawer
          title={"Adicionar Item"}
          closable={false}
          visible={itemDrawer}
          onClose={() => setItemDrawer(false)}
          width={450}
        >
          <Form
            form={itemForm}
            name={"ItemForm"}
            onFinish={() => {
              setPosting(true);
            }}
            layout={"vertical"}
          >
            <Form.Item label={"Producto"} name={"item"}>
              <DebounceSelect
                showSearch
                searchOnFocus={true}
                fetchOptions={fetchItems}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              label={"Cantidad"}
              name={"quantity"}
              rules={[
                { required: true, message: "Por favor indique la cantidad" },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </PageHeader>
    );
  } else {
    return <Skeleton active />;
  }
};

export default CountDetails;
