import { useEffect, useState } from "react";

const useSessionState = (state_id, defaultValue = null) => {
  const [state, setState] = useState(() => {
    return JSON.parse(sessionStorage.getItem(state_id)) || defaultValue;
  });

  useEffect(() => {
    sessionStorage.setItem(state_id, JSON.stringify(state));
  }, [state, state_id]);

  return [state, setState];
};

export default useSessionState;
