import { Button, Form, message } from "antd";
import { useContext } from "react";
import DebounceSelect from "../../../../components/search/debounceSelect";
import { api } from "../../../../utils/api";
import { BrowserContext } from "../../../../utils/browserContext";

const ChangeClientForm = ({ bill, onFormSuccess, onUpdateNeeded }) => {
  const [changeClientForm] = Form.useForm();
  const [{ user }] = useContext(BrowserContext);

  function onFinish(values) {
    if (bill.type === "PURCHASE" && !user.roles.includes("ADM")) {
      message.warn("Es necesario el grupo administrativo en usuario");
      return;
    }

    api
      .get(`/entities/${values.entityId.value}`)
      .then((entity) => {
        api
          .patch(`/bills/${bill.id}`, {
            entityId: entity.id,
            entityDocument: entity.ruc || entity.ci,
            address: entity.address || "",
          })
          .then((res) => {
            message.success("Cliente Atualizado");
            changeClientForm.resetFields();
            onFormSuccess();
          })
          .catch((err) => {
            message.error(err.message);
          })
          .finally(() => onUpdateNeeded());
      })
      .catch((err) => message.error(err.message));
  }

  function fetchEntities(search) {
    return api.get("/entities", { search: search }).then((res) =>
      res.map((entity) => ({
        label: `${entity.id} - ${entity.name}`,
        value: entity.id,
      }))
    );
  }

  return (
    <Form
      form={changeClientForm}
      name={"ChangeClientForm"}
      layout={"vertical"}
      onFinish={onFinish}
    >
      <Form.Item
        label={"Cliente"}
        name={"entityId"}
        rules={[{ required: true, message: "Por favor selecione el cliente" }]}
      >
        <DebounceSelect
          showSearch
          searchOnFocus={true}
          fetchOptions={fetchEntities}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangeClientForm;
