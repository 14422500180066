import { message, Table } from "antd";
import Search from "antd/lib/input/Search";
import { useEffect, useState } from "react";
import { api } from "../../../utils/api";
import { currencyFormat } from "../../../utils/formaters";
import ItemPricePane from "./itemPricePane";

const PriceSearch = ({ branch }) => {
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState({
    search: "",
  });
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  function fetchItems() {
    setQuery({
      search: search,
    });
  }

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      api
        .get("/catalog", query)
        .then((res) => {
          setItems(res);
        })
        .catch((err) => {
          setItems([]);
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (query?.search?.length > 1) fetchData();
  }, [query]);

  useEffect(() => {
    setColumns([
      { title: "Codigo", dataIndex: "id" },
      { title: "Codigo de Barras", dataIndex: "barcode" },
      { title: "Nombre", dataIndex: "name" },
      {
        title: `Precio ${branch?.code}`,
        dataIndex: "id",
        render: (_, row) => {
          const prices =
            row?.prices?.filter((price) => price.branch.id === branch.id) || [];
          const firstPrice = prices[0];
          return firstPrice ? currencyFormat(firstPrice.value) : "Sin registro";
        },
      },
    ]);
  }, [branch]);

  return (
    <>
      <Search
        placeholder={"tipea para buscar"}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => fetchItems()}
        enterButton
      />
      <Table
        size={"small"}
        loading={loading}
        dataSource={items}
        columns={columns}
        rowKey={"id"}
        style={{ marginTop: 10 }}
        expandable={{
          rowExpandable: (row) => true,
          expandedRowRender: (row) => (
            <ItemPricePane itemId={row.id} editable={false} />
          ),
        }}
      />
    </>
  );
};

export default PriceSearch;
