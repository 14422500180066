import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, message, PageHeader, Table } from "antd";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { api } from "../../../utils/api";
import CategoryForm from "./categoryForm";

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);

  function openDrawer(category) {
    setSelectedCategory(category);
    setDrawerVisible(true);
  }

  function onCloseDrawer() {
    setSelectedCategory(null);
    setDrawerVisible(false);
  }

  function fetchCategories() {
    api
      .get("/categories")
      .then((res) => setCategories(res))
      .catch((err) => message.error(err.message));
  }

  const cbFetchCategories = useCallback(fetchCategories, []);

  useEffect(() => {
    cbFetchCategories();
  }, [cbFetchCategories]);

  useEffect(() => {
    document.title = "Categorias";
  }, []);

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Categorias"}
      subTitle={"Lista de categorias"}
      extra={[
        <Button type={"primary"} key={"add"} onClick={() => openDrawer(null)}>
          <PlusOutlined /> Adicionar Categoria
        </Button>,
      ]}
    >
      <Table
        dataSource={categories}
        rowKey={"id"}
        columns={[
          { title: "Codigo", dataIndex: "id", width: 80 },
          { title: "Nombre", dataIndex: "name" },
          {
            title: "Acciones",
            dataIndex: "id",
            width: 80,
            render: (_, row) => (
              <Button onClick={() => openDrawer(row)}>
                <EditOutlined /> Editar
              </Button>
            ),
          },
        ]}
      />
      <Drawer
        title={"Formulario de categoria"}
        closable={false}
        visible={drawerVisible}
        onClose={onCloseDrawer}
        width={450}
      >
        <CategoryForm
          category={selectedCategory}
          onFormSuccess={() => {
            fetchCategories();
            setDrawerVisible(false);
          }}
        />
      </Drawer>
    </PageHeader>
  );
};

export default CategoriesPage;
