import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  message,
  PageHeader,
  Table,
  Menu,
  Form,
  Input,
  Dropdown,
} from "antd";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useEffect } from "react";
import DebounceSelect from "../../../components/search/debounceSelect";
import { api } from "../../../utils/api";
import { currencyFormat } from "../../../utils/formaters";
import { fetchBranches } from "../../../utils/selectFetchers";

const DrawersPage = () => {
  const [drawers, setDrawers] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerForm] = Form.useForm();
  const history = useHistory();

  function handleFormSubmit(values) {
    api
      .post("/drawers", {
        code: values.code,
        branchId: values.branch.key,
      })
      .then((res) => {
        message.success("Caja creado");
        setDrawerVisible(false);
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        fetchDrawers();
      });
  }

  function fetchDrawers() {
    api
      .get("/drawers")
      .then((res) => {
        setDrawers(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  const cbFetchDrawers = useCallback(fetchDrawers, []);

  useEffect(() => {
    cbFetchDrawers();
  }, [cbFetchDrawers]);

  useEffect(() => {
    document.title = "Cajas";
  }, []);

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Cajas"}
      extra={[
        <Button
          type={"primary"}
          key={"add"}
          onClick={() => setDrawerVisible(true)}
        >
          <PlusOutlined /> Nuevo Caja
        </Button>,
      ]}
    >
      <Table
        dataSource={drawers}
        rowKey={"id"}
        columns={[
          { title: "ID", dataIndex: "id" },
          { title: "Codigo", dataIndex: "code" },
          {
            title: "Sucursal",
            dataIndex: "id",
            render: (_, row) => row?.branch?.code,
          },
          {
            title: "En Caja",
            dataIndex: "stored",
            render: (_, row) => currencyFormat(row.stored),
          },
          {
            title: "Acciones",
            dataIndex: "id",
            render: (_, row) => (
              <Dropdown.Button
                onClick={() => history.push(`/drawers/${row.id}`)}
                overlay={
                  <Menu>
                    <Menu.Item key={"openNewTab"}>
                      <a
                        href={`/drawers/${row.id}`}
                        target={"_blank"}
                        rel={"noreferrer"}
                      >
                        Abrir en nueva guia
                      </a>
                    </Menu.Item>
                  </Menu>
                }
              >
                Ver Detalles
              </Dropdown.Button>
            ),
          },
        ]}
      />

      <Drawer
        title={"Formulario de Caja"}
        closable={false}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={450}
      >
        <Form
          form={drawerForm}
          name={"DrawerForm"}
          onFinish={handleFormSubmit}
          layout={"vertical"}
        >
          <Form.Item
            label={"Codigo de Caja"}
            name={"code"}
            rules={[
              {
                required: true,
                message: "Por favor indique el codigo de caja",
              },
            ]}
          >
            <Input placeholder={"Codigo para identificacion interna"} />
          </Form.Item>
          <Form.Item
            label={"Sucursal"}
            name={"branch"}
            rules={[
              { required: true, message: "Por favor seleciona una sucursal" },
            ]}
          >
            <DebounceSelect
              showSearch
              searchOnFocus={true}
              fetchOptions={fetchBranches}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item>
            <Button type={"primary"} htmlType={"submit"}>
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </PageHeader>
  );
};

export default DrawersPage;
