const decFormat = new Intl.NumberFormat("es-PY").format;
module.exports = {
  currencyFormat: (value) =>
    `Gs. ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
  currencyParser: (value) => (value || "0").replace(/Gs\.\s?|(\.*)/g, ""),
  currencyFormatDecimal: (value) => {
    return `Gs. ${decFormat(value)}`;
  },
  currencyParserDecimal: (value) => {
    return Number(
      value.replaceAll("Gs. ", "").replaceAll(".", "").replaceAll(",", ".")
    );
  },
};
