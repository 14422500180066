import {
  message,
  Dropdown,
  Menu,
  PageHeader,
  Button,
  Table,
  Drawer,
} from "antd";
import { useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import { api } from "../../../../utils/api";
import useSessionState from "../../../../utils/useSessionState";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";
import PurchaseBillForm from "./purchaseBillForm";
import BillStatusTag from "../../../../components/tags/billStatusTag";
import { Link } from "react-router-dom";
const PurchasesBills = () => {
  const [purchases, setPurchases] = useState([]);
  const [search, setSearch] = useSessionState("purchases_search", "");
  const [query, setQuery] = useSessionState("pruchases_query", {
    search: "",
  });
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  function fetchBills() {
    setQuery({
      search: search,
    });
  }

  useEffect(() => {
    document.title = "Libro Compra";
  });

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      api
        .get("/bills", {
          ...query,
          type: "PURCHASE",
        })
        .then((res) => {
          setPurchases(res);
        })
        .catch((err) => {
          message.error(err.message);
          setPurchases([]);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (query?.search.length > -1) fetchData();
  }, [query]);

  const columns = [
    { title: "Codigo", dataIndex: "id", width: 80, fixed: "left" },
    {
      title: "Fecha",
      dataIndex: "date",
      width: 120,
      fixed: "left",
      render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
    },
    { title: "Sucursal", dataIndex: "id", render: (_, row) => row.branch.code },
    {
      title: "Estado",
      dataIndex: "status",
      width: 140,
      render: (status, _) => <BillStatusTag status={status} />,
    },
    {
      title: "Cond. Pago",
      dataIndex: "credit",
      render: (_, row) => (row.credit ? "Crédito" : "Contado"),
    },
    {
      title: "Cod. Provedor",
      dataIndex: "id",
      render: (_, row) => row.entity.id,
    },
    {
      title: "Provedor",
      width: 250,
      dataIndex: "id",
      render: (_, row) => row.entity.name,
    },
    { title: "Total", dataIndex: "total", width: 100, fixed: "right" },
    {
      title: "Acciones",
      dataIndex: "id",
      fixed: "right",
      width: 150,
      render: (_, row) => (
        <Dropdown.Button
          // onClick={() => history.push(`/sales/${row.id}`)}
          overlay={
            <Menu>
              <Menu.Item key="openNewTab">
                <a
                  href={`/purchases/${row.id}`}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Abrir en nueva guia
                </a>
              </Menu.Item>
            </Menu>
          }
        >
          <Link to={`/purchases/${row.id}`}>Visualizar</Link>
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Libro Compra"}
      subTitle={"Lista de facturas de Compras"}
      extra={[
        <Button
          key={"add"}
          type={"primary"}
          onClick={() => setDrawerVisible(true)}
        >
          <PlusOutlined /> Nueva Factura
        </Button>,
      ]}
    >
      <Search
        placeholder={"Tipea para buscar"}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => fetchBills()}
        enterButton
      />
      <Table
        size={"small"}
        loading={loading}
        dataSource={purchases}
        columns={columns}
        rowKey={"id"}
        scroll={{ x: 1300 }}
        style={{ marginTop: 8 }}
      />
      <Drawer
        title={"Formulário de Factura Compra"}
        closable={false}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={450}
      >
        <PurchaseBillForm
          onFormsuccess={() => setDrawerVisible(false)}
          onUpdateNeeded={fetchBills}
        />
      </Drawer>
    </PageHeader>
  );
};

export default PurchasesBills;
