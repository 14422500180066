import { PlusOutlined } from "@ant-design/icons";
import { Tag, Select, Button } from "antd";
import { useState } from "react";

const TagControl = ({
  tags,
  options,
  onRemoveTag,
  onSubmitTag,
  removable = true,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAddInput, setShowAddInput] = useState(false);

  function handleSubmitTag() {
    onSubmitTag(selectedOption);
    setShowAddInput(false);
  }

  function handleRemoveTag(e, tag) {
    e.preventDefault();
    onRemoveTag(tag.key);
  }

  function handleSelectOption(option) {
    setSelectedOption(option);
    if (!option) setShowAddInput(false);
  }

  const AddInput = (
    <>
      <Select
        autoFocus
        showSearch
        allowClear
        size={"small"}
        placeholder={"Seleciona una opción"}
        optionFilterProp={"children"}
        onChange={handleSelectOption}
        filterOption={(input, option) =>
          option.children.toLowerCase().includes(input.toLocaleLowerCase())
        }
        style={{
          width: 188,
          verticalAlign: "top",
        }}
        addonAfter={<Button>OK</Button>}
      >
        {options.map((opt) => (
          <Select.Option key={opt.key} value={opt.value}>
            {opt.display}
          </Select.Option>
        ))}
      </Select>
      <Button
        type={"primary"}
        htmlType={"submit"}
        size={"small"}
        disabled={!selectedOption}
        onClick={handleSubmitTag}
      >
        OK
      </Button>
    </>
  );

  const AddTag = (
    <Tag
      key={"add-tag"}
      onClick={() => setShowAddInput(true)}
      style={{ borderStyle: "dashed" }}
    >
      <PlusOutlined /> Adicionar
    </Tag>
  );

  const normalTags = (tags || []).map((tag) => (
    <Tag
      key={tag.key}
      onClose={(e) => handleRemoveTag(e, tag)}
      closable={removable}
    >
      {tag.name}
    </Tag>
  ));

  return [...normalTags, showAddInput ? AddInput : AddTag];
};

export default TagControl;
