import { CaretRightFilled } from "@ant-design/icons";
import { Button, message, Modal, Table } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { api } from "../../../utils/api";

const DrawerScreenOpener = ({ visible, onCancel }) => {
  const [drawers, setDrawers] = useState([]);

  useEffect(() => {
    api
      .get(`/drawers`)
      .then((res) => setDrawers(res))
      .catch((err) => {
        message.error(err.message);
        setDrawers([]);
      });
  }, []);

  return (
    <Modal
      visible={visible}
      title={"Abrir Caja"}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{
        padding: 8,
      }}
    >
      <Table
        dataSource={drawers}
        pagination={false}
        rowKey={"id"}
        columns={[
          { title: "id", dataIndex: "id" },
          { title: "Codigo", dataIndex: "code" },
          { title: "Sucursal", dataIndex: (_, row) => row.branch.code },
          {
            title: "Abrir",
            dataIndex: "id",
            width: 80,
            render: (_, row) => (
              <Button>
                <a
                  href={`/checkout/${row.id}`}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Abrir <CaretRightFilled />
                </a>
              </Button>
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default DrawerScreenOpener;
