import { Tag } from "antd";

const BillStatusTag = ({ status }) => {
  const status_list = {
    PENDING: <Tag color={"gold"}>Pendiente</Tag>,
    CANCELED: <Tag color={"red"}>Cancelado</Tag>,
    COMPLETED: <Tag color={"green"}>Completado</Tag>,
    default: <Tag color={"default"}>No reconocido</Tag>,
  };

  return status_list[status] || status_list["default"];
};

export default BillStatusTag;
