import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { api } from "../../../utils/api";

const BranchForm = ({ branch, onFormSuccess, onUpdateNeeded }) => {
  const [branchForm] = Form.useForm();

  function onFinish(values) {
    const reqMethod = branch ? "PUT" : "POST";
    const reqUrl = "/branches" + (branch ? "/" + branch.id : "");

    api
      .req(reqMethod, reqUrl, {
        ...values,
      })
      .then((res) => {
        message.success("Sucursal creada");
        onFormSuccess();
        onUpdateNeeded();
        branchForm.resetFields();
      })
      .catch((err) => {
        message.error(err.message);
        onUpdateNeeded();
      });
  }

  useEffect(() => {
    if (branch) {
      branchForm.setFieldsValue({
        code: branch.code,
        name: branch.name,
      });
    } else {
      branchForm.resetFields();
    }
  });

  return (
    <Form
      form={branchForm}
      name={"BranchForm"}
      layout={"vertical"}
      onFinish={onFinish}
    >
      <Form.Item
        label={"Code"}
        name={"code"}
        rules={[
          { required: true, message: "Por favor insira el codigo de sucursal" },
          { min: 2, message: "Es necessario tener minimo de 2 caracteres" },
          { max: 6, message: "No se puede tener mas de 6 caracteres" },
        ]}
      >
        <Input placeholder={"ej. 001 o MATRIZ"} />
      </Form.Item>

      <Form.Item
        label={"Nombre"}
        name={"name"}
        rules={[
          { required: true, message: "Por favor insira el nombre" },
          { min: 3, message: "Es necessario tener al menos 3 caracteres" },
          { max: 50, message: "No se puede tener mas de 50 caracteres" },
        ]}
      >
        <Input placeholder={"ej. CASA MATRIZ"} />
      </Form.Item>

      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BranchForm;
