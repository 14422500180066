import {
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons"
import {
  Button,
  Descriptions,
  Drawer,
  message,
  PageHeader,
  Skeleton,
  Table,
  Form,
  InputNumber,
  Select,
} from "antd"
import confirm from "antd/lib/modal/confirm"
import dayjs from "dayjs"
import { useCallback } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { useParams } from "react-router"
import DebounceSelect from "../../../components/search/debounceSelect"
import BillStatusTag from "../../../components/tags/billStatusTag"
import { api, apiDefaults } from "../../../utils/api"
import { fetchItems } from "../../../utils/selectFetchers"

const TransferenceDetails = () => {
  const { id } = useParams()
  const [transference, setTransference] = useState(null)
  const [itemDrawer, setItemDrawer] = useState(false)
  const [itemForm] = Form.useForm()
  const [posting, setPosting] = useState(false)
  const [postingEnd, setPostingEnd] = useState(false)
  const [columns, setColumns] = useState([])
  const [selectedItemOption, setSelectedItemOption] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)

  function printTransference() {
    confirm({
      title: "Desea imprimir la Transferencia?",
      icon: <PrinterOutlined />,
      okText: "Si",
      cancelText: "No",
      onOk: () => {
        window
          .open(`${apiDefaults.baseURL}/transferences/${id}/pdf`, "_blank")
          .focus()
      },
    })
  }

  function removeItem(item) {
    api
      .delete(`/transferences/items/${item.id}`)
      .then((res) => {
        message.success("Item Removido")
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => {
        fetchTransference()
      })
  }

  function changeStatus(status) {
    api
      .patch(`/transferences/${id}`, {
        status: status,
      })
      .then((res) => {
        message.success("Transferencia atualizada")
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => {
        fetchTransference()
      })
  }

  function fetchTransference() {
    api
      .get(`/transferences/${id}`)
      .then((res) => {
        setTransference(res)
      })
      .catch((err) => {
        message.error(err.message)
      })
  }

  const cbFetchTransference = useCallback(fetchTransference, [id])

  useEffect(() => {
    if (selectedItemOption) {
      api.get(`/catalog/${selectedItemOption.value}`)
        .then(res => setSelectedItem(res))
        .catch(err => { message.error(err.message) })
    } else {
      setSelectedItem(null)
    }
  }, [selectedItemOption])

  useEffect(() => {
    cbFetchTransference()
  }, [id, cbFetchTransference])

  useEffect(() => {
    document.title = `${id} - Transferencia`
  }, [id])

  useEffect(() => {
    if (posting) {
      const reqBody = {
        transferenceId: transference.id,
        itemId: posting.item.key,
        quantity: posting.quantity,
      }
      if (posting.batch) {
        reqBody['batch'] = posting.batch
      }
      api
        .post("/transferences/items", reqBody)
        .then((res) => {
          message.success("Item Adicionado")
        })
        .catch((err) => {
          message.error(err.message)
        })
        .finally(() => {
          setPosting(false)
          setPostingEnd(true)
        })
    }
  }, [posting, transference])

  useEffect(() => {
    if (postingEnd) {
      itemForm.resetFields()
      setPostingEnd(false)
      cbFetchTransference()
    }
  }, [postingEnd, cbFetchTransference, itemForm])

  useEffect(() => {
    const baseColumns = [
      { title: "Codigo", dataIndex: "id" },
      {
        title: "Cod. Producto",
        dataIndex: "id",
        render: (_, row) => row.item.barcode,
      },
      {
        title: "Nombre Producto",
        dataIndex: "id",
        render: (_, row) => `${row.item.name} ${row.item.batch ? ' L: ' + row.item.batch : ''}`,
      },
      {
        title: "Cantidad",
        dataIndex: "quantity",
      },
    ]

    let cols = [...baseColumns]
    if (transference?.status === "PENDING") {
      cols.push({
        title: "Acciones",
        dataIndex: "id",
        render: (_, row) => (
          <Button type={"link"} danger onClick={() => removeItem(row)}>
            <DeleteOutlined />
          </Button>
        ),
      })
    }

    setColumns(cols)
  }, [transference])

  if (transference) {
    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={"Detalles de Transferencia"}
        tags={<BillStatusTag status={transference.status} />}
        extra={[
          transference.status === "PENDING" ? (
            <Button
              key={"addItem"}
              type={"primary"}
              onClick={() => setItemDrawer(true)}
            >
              <PlusOutlined /> Adicionar Item
            </Button>
          ) : (
            <></>
          ),
          transference.status === "PENDING" ? (
            <Button
              key={"complete"}
              type={"primary"}
              onClick={() => changeStatus("COMPLETED")}
            >
              <CheckOutlined /> Finalizar
            </Button>
          ) : (
            <></>
          ),
          transference.status !== "CANCELED" ? (
            <Button
              key={"cancel"}
              type={"primary"}
              onClick={() => changeStatus("CANCELED")}
              danger
            >
              <DeleteOutlined /> Cancelar
            </Button>
          ) : (
            <></>
          ),
          <Button key={"print"} onClick={printTransference}>
            <PrinterOutlined /> Imprimir
          </Button>,
        ]}
      >
        <Descriptions bordered>
          <Descriptions.Item label={"Fecha"}>
            {dayjs(transference.date).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label={"Origen"}>
            {`${transference.origin.code} - ${transference.origin.name} `}
          </Descriptions.Item>
          <Descriptions.Item label={"Destination"}>
            {`${transference.destination.code} - ${transference.destination.name}`}
          </Descriptions.Item>
        </Descriptions>
        <Table
          dataSource={transference.items}
          rowKey={"id"}
          columns={columns}
        />
        <Drawer
          title={"Adicionar Item"}
          closable={false}
          visible={itemDrawer}
          onClose={() => setItemDrawer(false)}
          width={450}
        >
          <Form
            form={itemForm}
            name={"itemForm"}
            onFinish={(values) => {
              setPosting(values)
            }}
            layout={"vertical"}
          >
            <Form.Item
              label={"Producto"}
              name={"item"}
              rules={[
                { required: true, message: "Por favor seleciona un item" },
              ]}
            >
              <DebounceSelect
                showSearch
                searchOnFocus={true}
                fetchOptions={fetchItems}
                value={selectedItemOption}
                onChange={(value) => setSelectedItemOption(value)}
                style={{ width: "100%" }}
              />
            </Form.Item>

            {selectedItem ? (
              <Descriptions bordered={true} size="small" style={{ marginBottom: 8 }}>
                {['ori', 'des'].map(b => {
                  const branchStk = selectedItem?.stocks?.find(s => s.branchId === transference[b === 'ori' ? 'originId' : 'destinationId'])
                  return <Descriptions.Item span={4} label={`${b === 'ori' ? 'Origen' : 'Destino'} [${branchStk.name}]`}>{branchStk?.quantity}</Descriptions.Item>
                })}
              </Descriptions>
            ) : <></>}

            {selectedItem?.batchControl ? (
              <Form.Item label="Lote" name="batch" rules={[{ required: true, message: 'Seleciona el Lote del producto' }]} >
                <Select showAction="focus">
                  {selectedItem.batches.filter(b => b.branch_id === transference.origin.id).map(batch => (
                    <Select.Option value={batch.code}>{batch.code} - Stock: {batch.stock}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : <></>}
            <Form.Item
              label={"Cantidad"}
              name={"quantity"}
              rules={[
                { required: true, message: "Por favor indique la cantidad" },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </PageHeader>
    )
  } else {
    return <Skeleton active />
  }
}

export default TransferenceDetails
