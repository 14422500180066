import { Button, DatePicker, Form, message } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import DebounceSelect from '../../../components/search/debounceSelect';
import { api } from '../../../utils/api';
import { fetchBranches } from '../../../utils/selectFetchers';

const StockAdjustmentForm = ({ onUpdateNeeded }) => {
  const [form] = Form.useForm();
  const [posting, setPosting] = useState(null);
  const [postingEnd, setPostingEnd] = useState(false);

  useEffect(() => {
    if (posting) {
      api
        .post('/stock_adjustments', {
          date: dayjs(posting.date).format('YYYY-MM-DD'),
          branchId: posting.branch.key,
        })
        .then((res) => {
          message.success('Ajuste Creado');
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setPosting(null);
          setPostingEnd(true);
        });
    }
  }, [posting]);

  useEffect(() => {
    if (postingEnd) {
      setPostingEnd(false);
      form.resetFields();
      onUpdateNeeded();
    }
  }, [postingEnd, onUpdateNeeded, form]);

  return (
    <Form
      form={form}
      name='stockAdjustmentForm'
      onFinish={setPosting}
      layout='vertical'
    >
      <Form.Item
        label='Sucursal'
        name='branch'
        rules={[{ required: true, message: 'Por favor selecina la sucursal' }]}
      >
        <DebounceSelect
          showSearch
          searchOnFocus={true}
          fetchOptions={fetchBranches}
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        label='Fecha'
        name='date'
        rules={[{ required: true, message: 'Por favor seleciona la fecha' }]}
      >
        <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StockAdjustmentForm;
