import { DownloadOutlined } from "@ant-design/icons"
import { PageHeader, Form, message, DatePicker, Button, Input } from "antd"
import { useEffect } from "react"
import { apiDefaults } from "../../utils/api"
import dayjs from "dayjs"

const ExtractInventory = () => {
  function handleSubmit(values) {
    const name = values.name || ''
    const date = dayjs(values.date).format('YYYY-MM-DD')

    window.open(
      `${apiDefaults.baseURL}/reports/inventory_extract?name=${name}&date=${date}`
    )
  }

  useEffect(() => {
    document.title = "Extracto Inventário"
  }, [])

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Extracto de Inventário"}
      style={{
        height: "70vh",
      }}
    >
      <Form name={"InventoryExtract"} onFinish={handleSubmit} layout={"inline"}>
        <Form.Item label={"Pesquisa de producto"} name={"name"}>
          <Input />
        </Form.Item>
        <Form.Item label="Fecha" name="date">
          <DatePicker format={'DD/MM/YYYY'} />
        </Form.Item>
        <Form.Item>
          <Button type={"primary"} htmlType={"submit"}>
            <DownloadOutlined /> Exportar
          </Button>
        </Form.Item>
      </Form>
    </PageHeader>
  )
}

export default ExtractInventory
