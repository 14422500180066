import { Button, Checkbox, Form, Input, message, Radio, Select } from "antd"
import { useContext, useEffect } from "react"
import { api } from "../../../utils/api"
import { BrowserContext } from "../../../utils/browserContext"

const ItemForm = ({ item, onFormSuccess, onUpdateNeeded }) => {
  const [{ user }] = useContext(BrowserContext)
  const [itemForm] = Form.useForm()

  function onFinish(values) {
    if (!user.roles.includes("ADM")) {
      message.warn("Es necesario el grupo administrativo en usuario")
      return
    }

    const reqMethod = item ? "PUT" : "POST"
    const reqUrl = "/catalog" + (item ? `/${item.id}` : "")

    api
      .req(reqMethod, reqUrl, { ...values })
      .then((res) => {
        message.success("Item creado")
        onFormSuccess()
        itemForm.resetFields()
      })
      .catch((err) => {
        message.error(err.message)
        onUpdateNeeded()
      })
  }

  useEffect(() => {
    if (item) {
      itemForm.setFieldsValue({
        barcode: item.barcode,
        name: item.name,
        description: item.description,
        billDescription: item.billDescription,
        type: item.type,
        iva: item.iva,
        stockControl: item.stockControl,
      })
    } else {
      itemForm.resetFields()
    }
  }, [item, itemForm])

  return (
    <Form
      form={itemForm}
      name={"ItemForm"}
      onFinish={onFinish}
      layout={"vertical"}
      size={"small"}
      initialValues={{
        type: "PRODUCT",
        iva: 10,
      }}
    >
      <Form.Item
        label={"Codigo de Barras"}
        name={"barcode"}
        rules={[
          { required: true, message: "Por favor insira el codigo de barras" },
          { min: 3, message: "Codigo deve tener minimo de 3 caracteres" },
          { max: 25, message: "Codigo no puede tener mas de 25 caracteres" },
        ]}
      >
        <Input placeholder={"ej. 101 o 7891027"} />
      </Form.Item>

      <Form.Item
        label={"Nombre"}
        name={"name"}
        rules={[
          { required: true, message: "Por favor insira el nombre del item" },
          { min: 3, message: "Nombre deve tener minimo de 3 caracteres" },
          { max: 70, message: "Nombre no puede tener mas de 70 caracteres" },
        ]}
      >
        <Input placeholder={"Nombre del item"} />
      </Form.Item>

      <Form.Item
        name={"stockControl"}
        valuePropName="checked"
        initialValue={true}
      >
        <Checkbox>Control de Stock</Checkbox>
      </Form.Item>

      <Form.Item
        label={"Descripción de Factura"}
        name={"billDescription"}
        rules={[
          {
            max: 70,
            message:
              "Descripción de Factura no puede tener mas de 70 caracteres",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={"Descripción"} name={"description"}>
        <Input.TextArea
          rows={3}
          placeholder={"Descripción detallada del producto"}
        />
      </Form.Item>

      <Form.Item
        label={"Tipo"}
        name={"type"}
        rules={[{ required: true, message: "Seleciona el tipo de item" }]}
      >
        <Radio.Group>
          <Radio value={"PRODUCT"}>PRODUCTO</Radio>
          <Radio value={"SERVICE"}>SERVICIO</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="iva"
        label="IVA"
        rules={[{ required: true, message: "Seleciona la categoria de IVA" }]}
      >
        <Radio.Group>
          <Radio value={0}>Exento</Radio>
          <Radio value={5}>IVA 5%</Radio>
          <Radio value={10}>IVA 10%</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ItemForm
