import {
  CheckOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Descriptions,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Select,
  Skeleton,
  Table,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import DebounceSelect from '../../../components/search/debounceSelect';
import AdjustmentTag from '../../../components/tags/adjustmentTag';
import BillStatusTag from '../../../components/tags/billStatusTag';
import { api } from '../../../utils/api';
import { currencyFormat, currencyParser } from '../../../utils/formaters';
import { fetchItems } from '../../../utils/selectFetchers';

const StockAdjustmentDetails = () => {
  const { id } = useParams();
  const [adjustment, setAdjustment] = useState(null);
  const [itemDrawer, setItemDrawer] = useState(false);
  const [itemForm] = Form.useForm();
  const [selectedItemOption, setSelectedItemOption] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [posting, setPosting] = useState(false);
  const [postingEnd, setPostingEnd] = useState(false);
  const [columns, setColumns] = useState([]);

  function changeStatus(status) {
    api
      .patch(`/stock_adjustments/${id}`, {
        status: status,
      })
      .then((res) => {
        message.success('Ajuste atualizado');
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        fetchAdjustment();
      });
  }

  useEffect(() => {
    if (posting) {
      const reqBody = {
        stockAdjustmentId: id,
        itemId: posting.item.key,
        type: posting.type,
        price: posting.price || 1,
        quantity: posting.quantity,
        description: posting.description,
      };

      if (posting.batch) {
        reqBody['batch'] = posting.batch;
      }

      api
        .put('/stock_adjustments/items', reqBody)
        .then((res) => {
          message.success('Item Adicionado');
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setPosting(false);
          setPostingEnd(true);
        });
    }
  }, [posting, adjustment]);

  function fetchAdjustment() {
    api
      .get(`/stock_adjustments/${id}`)
      .then((res) => {
        setAdjustment(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  const cbFetchAdjustment = useCallback(fetchAdjustment, [id]);
  useEffect(cbFetchAdjustment, [cbFetchAdjustment, id]);

  useEffect(() => {
    if (postingEnd) {
      itemForm.resetFields();
      setPostingEnd(false);
      cbFetchAdjustment();
    }
  }, [postingEnd, cbFetchAdjustment, itemForm]);

  useEffect(() => {
    if (selectedItemOption) {
      api
        .get(`/catalog/${selectedItemOption.value}`)
        .then(setSelectedItem)
        .catch((err) => {
          message.error(err.message);
        });
    }
  }, [selectedItemOption]);

  function removeItem(row) {
    api
      .delete(`/stock_adjustments/items/${row.id}`)
      .then((res) => {
        message.success('Fue removido el Item');
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        fetchAdjustment();
      });
  }

  useEffect(() => {
    const baseColumns = [
      { title: 'Codigo', dataIndex: 'id', width: 80 },
      {
        title: 'Nombre Producto',
        dataIndex: 'id',
        render: (_, row) => (
          <Link to={`/catalog/${row.itemId}`}>
            {`${row.item.name} ${row.item.batch ? 'L:' + row.item.batch : ''}`}{' '}
            <EyeOutlined />
          </Link>
        ),
      },
      {
        title: 'Descripción',
        dataIndex: 'description',
        ellipsis: {
          showTitle: false,
        },
        render: (description) => (
          <Tooltip placement='topLeft' title={description}>
            {description}
          </Tooltip>
        ),
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        width: 120,
        render: (type, _) => <AdjustmentTag type={type} />,
      },
      { title: 'Lote', dataIndex: 'batch', width: 120 },
      { title: 'Cantidad', dataIndex: 'quantity', width: 120 },
    ];

    let cols = [...baseColumns];
    if (adjustment?.status === 'PENDING') {
      cols.push({
        title: 'Acciones',
        dataIndex: 'id',
        width: 100,
        render: (_, row) => (
          <Button type='link' danger onClick={() => removeItem(row)}>
            <DeleteOutlined />
          </Button>
        ),
      });
    }

    setColumns(cols);
  }, [adjustment]);

  if (!adjustment) return <Skeleton active />;

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title='Detalles del Ajuste'
      tags={<BillStatusTag status={adjustment.status} />}
      extra={[
        adjustment.status === 'PENDING' ? (
          <Button
            type='primary'
            key='add_item'
            onClick={() => setItemDrawer(true)}
          >
            <PlusOutlined /> Adicionar Item
          </Button>
        ) : (
          <></>
        ),
        adjustment.status === 'PENDING' ? (
          <Button
            type='primary'
            key='complete'
            onClick={() => changeStatus('COMPLETED')}
          >
            <CheckOutlined /> Finalizar
          </Button>
        ) : (
          <></>
        ),
        adjustment.status !== 'CANCELED' ? (
          <Button
            type='primary'
            danger
            key='cancel'
            onClick={() => changeStatus('CANCELED')}
          >
            <DeleteOutlined /> Cancelar
          </Button>
        ) : (
          <></>
        ),
      ]}
    >
      <Descriptions bordered>
        <Descriptions.Item label='Codigo'>{adjustment.id}</Descriptions.Item>
        <Descriptions.Item label={'Fecha'}>
          {dayjs(adjustment.date).format('DD/MM/YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label='Sucursal'>
          {`${adjustment.branch.code} - ${adjustment.branch.name}`}
        </Descriptions.Item>
      </Descriptions>

      <Table
        dataSource={adjustment.StockAdjustmentItems}
        rowKey='id'
        columns={columns}
      />
      <Drawer
        title='Adicionar Item'
        closable={false}
        visible={itemDrawer}
        onClose={() => setItemDrawer(false)}
        width={450}
      >
        <Form
          form={itemForm}
          name='StockAdjustmentItemForm'
          onFinish={setPosting}
          layout='vertical'
        >
          <Form.Item
            label='Tipo'
            name='type'
            rules={[{ required: true, message: 'Seleciona un tipo' }]}
          >
            <Select
              value={itemType}
              onChange={setItemType}
              showAction={'focus'}
            >
              {['IN', 'OUT'].map((type) => (
                <Select.Option value={type}>
                  <AdjustmentTag type={type} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label='Producto'
            name='item'
            rules={[{ required: true, message: 'Seleciona un item' }]}
          >
            <DebounceSelect
              showSearch
              searchOnFocus={true}
              fetchOptions={fetchItems}
              value={selectedItemOption}
              onChange={(value) => setSelectedItemOption(value)}
              style={{ width: '100%' }}
            />
          </Form.Item>

          {selectedItem?.batchControl ? (
            <Form.Item
              label='Lote'
              name='batch'
              rules={[
                { required: true, message: 'Seleciona el Lote del Producto' },
              ]}
            >
              <Select showAction='focus'>
                {selectedItem.batches
                  .filter((b) => b.branch_id === adjustment.branch.id)
                  .map((batch) => (
                    <Select.Option value={batch.code}>
                      {batch.code} - Stock: {batch.stock}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          ) : (
            <></>
          )}

          <Form.Item
            label='Cantidad'
            name='quantity'
            rules={[
              { required: true, message: 'Por favor especifica la cantidad' },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>

          {itemType && itemType === 'IN' ? (
            <Form.Item
              label='Custo'
              name='price'
              rules={[
                { required: true, message: 'Por favor especifica el precio' },
              ]}
            >
              <InputNumber
                formatter={currencyFormat}
                parser={currencyParser}
                style={{ width: '100%' }}
              />
            </Form.Item>
          ) : (
            <></>
          )}

          <Form.Item label='Descripción' name='description'>
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit'>
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </PageHeader>
  );
};

export default StockAdjustmentDetails;
