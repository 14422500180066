import { Tag } from 'antd';

const AdjustmentTag = ({ type }) => {
  const type_list = {
    IN: <Tag color='blue'>ENTRADA</Tag>,
    OUT: <Tag color='red'>SALIDA</Tag>,
    default: <Tag color='default'>No reconocido</Tag>,
  };

  return type_list[type] || type_list['default'];
};

export default AdjustmentTag;
