import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, message, PageHeader, Table } from "antd";
import { useEffect, useState } from "react";
import { api } from "../../../utils/api";
import BranchForm from "./branchForm";

const BranchesPage = () => {
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState([]);
  const [drawer, setDrawer] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);

  function showDrawer(branch) {
    setDrawer(true);
    setSelectedBranch(branch);
  }

  function onCloseDrawer() {
    setDrawer(false);
    setSelectedBranch(null);
  }

  function fetchBranches() {
    setLoading(true);
    api
      .get("/branches")
      .then((res) => {
        setLoading(false);
        setBranches(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message);
      });
  }

  function onFormSuccess() {
    fetchBranches();
    onCloseDrawer();
  }

  useEffect(() => {
    fetchBranches();
  }, []);

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Sucursales"}
      subTitle={"Lista de sucursales"}
      extra={[
        <Button key={"add"} type={"primary"} onClick={() => showDrawer(null)}>
          <PlusOutlined /> Nuevo Usuario
        </Button>,
      ]}
    >
      <Table
        loading={loading}
        dataSource={branches}
        rowKey={"id"}
        columns={[
          { title: "ID", dataIndex: "id" },
          { title: "Codigo", dataIndex: "code" },
          { title: "Nombre", dataIndex: "name" },
          {
            title: "Acciones",
            dataIndex: "id",
            render: (_, row) => (
              <Button type={"link"} onClick={() => showDrawer(row)}>
                <EditOutlined /> Editar
              </Button>
            ),
          },
        ]}
      />
      <Drawer
        title={"Formulario de Sucursal"}
        placement={"right"}
        closable={false}
        visible={drawer}
        onClose={onCloseDrawer}
        width={450}
      >
        <BranchForm
          branch={selectedBranch}
          onFormSuccess={onFormSuccess}
          onUpdateNeeded={fetchBranches}
        />
      </Drawer>
    </PageHeader>
  );
};

export default BranchesPage;
