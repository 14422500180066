import { api } from "./api"

function fetchBranches(search) {
  return api.get("/branches", { search: search }).then((res) => {
    return res.map((branch) => ({
      label: `${branch.code} - ${branch.name}`,
      value: branch.id,
    }))
  })
}

function fetchEntities(search) {
  return api.get("/entities", { search: search }).then((res) =>
    res.map((entity) => ({
      label: `${entity.id} - ${entity.name}`,
      value: entity.id,
    }))
  )
}

function fetchItems(search) {
  return api
    .get("/catalog", { search: search, take: 12, searchMode: true })
    .then((res) =>
      res.map((item) => ({
        label: `${item.barcode} - ${item.name}`,
        value: item.id,
      }))
    )
}

export { fetchBranches, fetchEntities, fetchItems }
