import { Button, DatePicker, Form, message } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useState } from "react";
import DebounceSelect from "../../../components/search/debounceSelect";
import { api } from "../../../utils/api";
import { fetchBranches } from "../../../utils/selectFetchers";

const TransferenceForm = ({ onUpdateNeeded }) => {
  const [transferenceForm] = Form.useForm();
  const [posting, setPosting] = useState(null);
  const [postingEnd, setPostingEnd] = useState(false);

  useEffect(() => {
    if (posting) {
      api
        .post("/transferences", {
          originId: posting.origin.key,
          destinationId: posting.destination.key,
          date: dayjs(posting.date).format("YYYY-MM-DD"),
        })
        .then((res) => {
          message.success("Tranferencia Creada");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setPosting(null);
          setPostingEnd(true);
        });
    }
  }, [posting]);

  useEffect(() => {
    if (postingEnd) {
      setPostingEnd(false);
      transferenceForm.resetFields();
      onUpdateNeeded();
    }
  }, [postingEnd, onUpdateNeeded, transferenceForm]);

  return (
    <Form
      form={transferenceForm}
      name={"transferenceForm"}
      onFinish={(values) => {
        setPosting(values);
      }}
      layout={"vertical"}
    >
      <Form.Item
        label={"Origen"}
        name={"origin"}
        rules={[{ required: true, message: "Por favor seleciona la origen" }]}
      >
        <DebounceSelect
          showSearch
          searchOnFocus={true}
          fetchOptions={fetchBranches}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label={"Destino"}
        name={"destination"}
        rules={[{ required: true, message: "Por favor seleciona el destino" }]}
      >
        <DebounceSelect
          showSearch
          searchOnFocus={true}
          fetchOptions={fetchBranches}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label={"Fecha"}
        name={"date"}
        rules={[{ required: true, message: "Por favor selecione la Fecha" }]}
      >
        <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default TransferenceForm;
