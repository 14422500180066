import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Dropdown,
  Menu,
  message,
  PageHeader,
  Table,
} from "antd";
import Search from "antd/lib/input/Search";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../../utils/api";
import useSessionState from "../../../utils/useSessionState";
import ItemForm from "./itemForm";

const CatalogPage = () => {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [search, setSearch] = useSessionState("catalog_search", "");
  const [query, setQuery] = useSessionState("catalog_query", {
    search: "",
  });
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  function showDrawer(item) {
    setSelectedItem(item);
    setDrawerVisible(true);
  }

  function onCloseDrawer() {
    setSelectedItem(null);
    setDrawerVisible(false);
  }

  function onFormSuccess() {
    fetchItems();
    onCloseDrawer();
  }

  function fetchItems() {
    setQuery({
      search: search,
    });
  }

  function ivaType(iva) {
    const categorias = {
      0: "EXENTO",
      5: "IVA 5%",
      10: "IVA 10%",
      default: "NO ESPECIFICADO",
    };
    return categorias[iva] || categorias["default"];
  }

  useEffect(() => {
    document.title = "Catalogo";
  }, []);

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      api
        .get("/catalog", query)
        .then((res) => {
          setLoading(false);
          setItems(res);
        })
        .catch((err) => {
          setLoading(false);
          setItems([]);
          message.error(err.message);
        });
    };

    //if (query?.search?.length > 1)
    fetchData();
  }, [query]);

  const columns = [
    { title: "Codigo", dataIndex: "id" },
    { title: "Codigo de Barras", dataIndex: "barcode" },
    { title: "Nombre", dataIndex: "name" },
    {
      title: "Tipo",
      dataIndex: "id",
      render: (_, row) => (row.type === "PRODUCT" ? "PRODUCTO" : "SERVICIO"),
    },
    {
      title: "IVA",
      dataIndex: "iva",
      render: (_, row) => ivaType(row.iva),
    },
    {
      title: "Acciones",
      dataIndex: "id",
      width: 80,
      render: (_, row) => (
        <Dropdown.Button
          onClick={() => history.push(`/catalog/${row.id}`)}
          overlay={
            <Menu>
              <Menu.Item key="openNewTab">
                <a
                  href={`/catalog/${row.id}`}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Abrir en nueva guia
                </a>
              </Menu.Item>
              <Menu.Item key="edit" onClick={() => showDrawer(row)}>
                Editar
              </Menu.Item>
            </Menu>
          }
        >
          Ver Detalles
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Catalogo"}
      extra={[
        <Button key={"add"} type={"primary"} onClick={() => showDrawer(null)}>
          <PlusOutlined /> Adicionar Item
        </Button>,
      ]}
    >
      <Search
        placeholder={"tipea para buscar"}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => fetchItems()}
        enterButton
      />
      <Table
        loading={loading}
        dataSource={items}
        columns={columns}
        rowKey={"id"}
        style={{ marginTop: 10 }}
        size={"small"}
      />

      <Drawer
        title={"Formulario de Item"}
        closable={false}
        visible={drawerVisible}
        onClose={onCloseDrawer}
        width={450}
      >
        <ItemForm
          item={selectedItem}
          onFormSuccess={onFormSuccess}
          onUpdateNeeded={fetchItems}
        />
      </Drawer>
    </PageHeader>
  );
};

export default CatalogPage;
