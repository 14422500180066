import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Dropdown,
  Menu,
  message,
  PageHeader,
  Table,
} from "antd";
import Search from "antd/lib/input/Search";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../../utils/api";
import useSessionState from "../../../utils/useSessionState";
import UserForm from "./userForm";

const UsersPage = () => {
  const history = useHistory();
  const [search, setSearch] = useSessionState("user_search", "");
  const [query, setQuery] = useSessionState("user_query", {
    search: "",
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  function showDrawer(user) {
    setSelectedUser(user);
    setDrawerVisible(true);
  }

  function onCloseDrawer() {
    setSelectedUser(null);
    setDrawerVisible(false);
  }

  function onFormSuccess() {
    fetchUsers();
    onCloseDrawer();
  }

  function fetchUsers() {
    setQuery({
      search: search,
    });
  }

  useEffect(() => {
    document.title = "Lista de Usuarios";
  }, []);

  useEffect(() => {
    const fetchUsers = () => {
      setTableLoading(true);
      api
        .get("/users", query)
        .then((res) => setUsers(res))
        .catch((err) => message.error(err.message))
        .finally(() => {
          setTableLoading(false);
        });
    };

    if (query?.search?.length > -1) fetchUsers();
  }, [query]);

  const columns = [
    { title: "Codigo", dataIndex: "id", key: "id" },
    { title: "Usuario", dataIndex: "username", key: "username" },
    { title: "Nombre", dataIndex: "fullName", key: "fullName" },
    {
      title: "Activo",
      dataIndex: "active",
      render: (_, row) => (row.active ? "Si" : "No"),
    },
    {
      title: "Acciones",
      dataIndex: "id",
      key: "id",
      width: 80,
      render: (_, row) => (
        <Dropdown.Button
          onClick={() => history.push("/users/" + row.id)}
          overlay={
            <Menu>
              <Menu.Item key="openNewTab">
                <a
                  href={`users/${row.id}`}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Abrir en nueva guia
                </a>
              </Menu.Item>
              <Menu.Item key="edit" onClick={() => showDrawer(row)}>
                Editar
              </Menu.Item>
            </Menu>
          }
        >
          Ver Detalles
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Usuarios"}
      subTitle={"Lista de usuarios"}
      extra={[
        <Button key={"add"} type={"primary"} onClick={() => showDrawer(null)}>
          <PlusOutlined /> Nuevo Usuario
        </Button>,
      ]}
    >
      <Search
        placeholder={"tipea para buscar"}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={(_) => fetchUsers()}
        enterButton
      />
      <Table
        loading={tableLoading}
        dataSource={users}
        style={{ marginTop: 10 }}
        columns={columns}
        rowKey={"id"}
      />

      <Drawer
        title={"Formulario de Usuario"}
        placement={"right"}
        closable={false}
        visible={drawerVisible}
        onClose={onCloseDrawer}
        width={450}
      >
        <UserForm user={selectedUser} onFormSuccess={onFormSuccess} />
      </Drawer>
    </PageHeader>
  );
};

export default UsersPage;
