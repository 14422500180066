import { CheckOutlined } from "@ant-design/icons"
import { Button, PageHeader, Table } from "antd"
import { useEffect } from "react"
import { useHistory } from "react-router"

const ReportsPage = () => {
  const history = useHistory()

  useEffect(() => {
    document.title = "Informes"
  }, [])

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Informes"}
      subTitle={"Lista de Informes"}
    >
      <Table
        dataSource={[
          {
            id: 1,
            name: "Extracto de Inventario",
            url: "/reports/inventory_extract",
          },
          {
            id: 2,
            name: "Extracto de Facturas",
            url: "/reports/bill_extract",
          },
          {
            id: 3,
            name: 'Extract de Ganancia Liquida',
            url: '/reports/gains_extract'
          }
        ]}
        rowKey={"id"}
        columns={[
          { title: "Cod", dataIndex: "id" },
          { title: "Nombre", dataIndex: "name" },
          {
            title: "Selecionar",
            width: 200,
            dataIndex: "id",
            render: (_, row) => (
              <Button type={"primary"} onClick={() => history.push(row.url)}>
                <CheckOutlined /> Selecionar
              </Button>
            ),
          },
        ]}
      />
    </PageHeader>
  )
}

export default ReportsPage
