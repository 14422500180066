import { Button, DatePicker, Form, Select, message, Radio } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { api } from "../../../utils/api";

const CreditNoteForm = ({ onFormSuccess, bill }) => {
  const [creditNoteForm] = Form.useForm();
  const [posting, setPosting] = useState(null);
  const [postEnd, setPostEnd] = useState(false);

  useEffect(() => {
    if (posting) {
      const postBody = {
        type: posting.type,
        date: posting.date,
        billId: bill.id,
        entityId: bill.entity.id,
      };

      api
        .post("/credit_notes", postBody)
        .then((res) => {
          message.success("Nota de credito Creada");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setPosting(null);
          setPostEnd(true);
        });
    }
  }, [posting]);

  useEffect(() => {
    if (postEnd) {
      creditNoteForm.resetFields();
      onFormSuccess();
      setPostEnd(false);
    }
  }, [postEnd, creditNoteForm]);

  return (
    <Form
      form={creditNoteForm}
      name={"CreditNoteForm"}
      onFinish={(values) => {
        setPosting({
          type: values.type,
          date: dayjs(values.date).format("YYYY-MM-DD"),
        });
      }}
      layout={"vertical"}
    >
      <Form.Item
        label={"Tipo"}
        name={"type"}
        rules={[
          {
            required: true,
            message: "Por favor seleciona el tipo de Nota de crédito",
          },
        ]}
      >
        <Radio.Group>
          <Radio value={"RETURN"}>DEVOLUCIÓN</Radio>
          <Radio value={"DISCOUNT"}>DESCUENTO</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        label={"Fecha"}
        name={"date"}
        rules={[{ required: true, message: "Por favor seleciona la Fecha" }]}
      >
        <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreditNoteForm;
