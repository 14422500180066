import { CheckOutlined, UserOutlined } from "@ant-design/icons";
import {
  Col,
  Dropdown,
  message,
  Row,
  Skeleton,
  Table,
  Menu,
  Drawer,
  Form,
  InputNumber,
  Button,
} from "antd";
import Search from "antd/lib/input/Search";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useEffect } from "react";
import { api } from "../../utils/api";
import { currencyFormat, currencyParser } from "../../utils/formaters";
import { fetchItems } from "../../utils/selectFetchers";
import useSessionState from "../../utils/useSessionState";
import WithdrawForm from "../admin/drawer/withdrawForm";
import ExchangeModal from "./ExchangeModal";
import ReceiptForm from "../facturacion/receipts/receiptForm";

const Checkout = () => {
  const history = useHistory();
  const { drawerId } = useParams();
  const [drawer, setDrawer] = useState(null);
  const [branch, setBranch] = useState(null);
  const [bills, setBills] = useState([]);
  const [search, setSearch] = useSessionState("checkout_page_search", "");
  const [tableLoading, setTableLoading] = useState(false);
  const [exchangeModalVisible, setExchangeModalVisible] = useState(false);
  const [selectedBill, setSelectedBill] = useState(null);
  const [withdrawDrawer, setWithdrawDrawer] = useState(false);
  const [dayEndDrawer, setDayEndDrawer] = useState(false);
  const [dayEndForm] = Form.useForm();
  const [postingDayEnd, setPostingDayEnd] = useState(false);
  const [receiptDrawer, setReceiptDrawer] = useState(false);

  function payEverySale() {
    bills?.forEach((b) => {
      api
        .post(`/drawers/bill/payment`, {
          drawerId: drawer.id,
          billId: b.id,
        })
        .then((res) => {
          message.success("Venta COD=" + b.id + " -> Pago realizado");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          cbFetchBills();
        });
    });
  }

  function fetchBills() {
    if (branch) {
      const query = {
        type: "SALE",
        status: "COMPLETED",
        credit: false,
        paymentStatus: "PENDING",
        branchId: branch.id,
      };

      if (search) {
        query["search"] = search;
      }

      setTableLoading(true);
      api
        .get("/bills", query)
        .then((res) => {
          setBills(res);
        })
        .catch((err) => {
          message.error(
            "Ocurrio un error al listar las ventas pendientes de pago"
          );
          setBills([]);
        })
        .finally(() => {
          setTableLoading(false);
        });
    }
  }

  const cbFetchBills = useCallback(fetchBills, [search, branch]);

  function fetchDrawer() {
    api
      .get(`/drawers/${drawerId}`)
      .then((res) => setDrawer(res))
      .catch((err) => {
        message.error("Error al cargar el caja");
      });
  }

  const cbFetchDrawer = useCallback(fetchDrawer, [drawerId]);

  useEffect(() => {
    if (postingDayEnd) {
      const values = dayEndForm.getFieldsValue();
      api
        .post("/drawers/day_end", {
          drawerId: drawer.id,
          amount: values.amount,
          amountLeft: values.amountLeft,
        })
        .then((res) => {
          message.success("Cierre de caja realizado");
          dayEndForm.resetFields();
          setDayEndDrawer(false);
        })
        .catch((err) => {
          message.error(
            "Ocurrio un error, favor verificar en pantalla de detalles"
          );
        })
        .finally(() => {
          setPostingDayEnd(false);
        });
    }
  }, [postingDayEnd, dayEndForm, drawer]);

  useEffect(() => {
    document.title = "Cargando...";
  }, []);

  useEffect(() => {
    cbFetchDrawer();
  }, [cbFetchDrawer]);

  useEffect(() => {
    if (drawer) {
      api
        .get(`/branches/${drawer.branch.id}`)
        .then((res) => {
          setBranch(res);
          document.title = `Caja ${res.code} - ${drawer.code}`;
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  }, [drawer]);

  useEffect(() => {
    if (branch && drawer) {
      cbFetchBills();
    }
  }, [branch, drawer, cbFetchBills]);

  useEffect(() => {
    window.addEventListener("focus", () => {
      cbFetchBills();
    });
  }, [cbFetchBills]);

  if (drawer && branch) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
          backgroundColor: "white",
        }}
      >
        <div style={{ padding: 8 }}>
          <Search
            placeholder={"tipea para buscar"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={fetchItems}
            enterButton
          />
          <Table
            loading={tableLoading}
            dataSource={bills}
            rowKey={"id"}
            style={{ marginTop: 10 }}
            size={"small"}
            pagination={false}
            columns={[
              { title: "Codigo", dataIndex: "id" },
              {
                title: "Fecha",
                dataIndex: "date",
                width: 120,
                render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
              },
              {
                title: "Cod. Cliente",
                dataIndex: "id",
                render: (_, row) => row.entity.id,
              },
              {
                title: "Cliente",
                dataIndex: "id",
                render: (_, row) => row.entity.name,
              },
              {
                title: "Total",
                dataIndex: "id",
                render: (_, row) => currencyFormat(row.total),
              },
              {
                title: "Acciones",
                dataIndex: "id",
                width: 100,
                render: (_, row) => (
                  <Dropdown.Button
                    overlay={
                      <Menu>
                        <Menu.Item key={"ChangeClient"}>
                          <UserOutlined /> Cambiar Cliente
                        </Menu.Item>
                      </Menu>
                    }
                    onClick={() => {
                      setSelectedBill(row);
                      setExchangeModalVisible(true);
                    }}
                  >
                    <CheckOutlined /> Realizar Pago
                  </Dropdown.Button>
                ),
              },
            ]}
          />
          <ExchangeModal
            visible={exchangeModalVisible}
            onCancel={() => setExchangeModalVisible(false)}
            onSuccess={() => {
              setSelectedBill(null);
              setExchangeModalVisible(false);
              fetchBills();
            }}
            bill={selectedBill}
            drawer={drawer}
          />
        </div>
        <Row
          style={{
            margin: 0,
            position: "fixed",
            bottom: 0,
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              padding: 3,
              backgroundColor: "#40A9FF",
              color: "white",
              fontSize: 12,
            }}
          >
            <Row style={{ margin: 0 }}>
              <Col span={3}>{`${branch.code} - ${branch.name}`}</Col>
              <Col span={6}>{`Caja ${drawer.id} - ${drawer.code}`}</Col>
              <Col span={3}></Col>
              <Col
                span={3}
                style={{ textAlign: "right" }}
                className={"selectable-footer-bar"}
                onClick={() => payEverySale()}
              >
                Pagar Todas las Ventas
              </Col>
              <Col
                span={3}
                style={{ textAlign: "right" }}
                className={"selectable-footer-bar"}
                onClick={() => setReceiptDrawer(true)}
              >
                Crear Recibo
              </Col>
              <Col
                span={3}
                style={{ textAlign: "right" }}
                className={"selectable-footer-bar"}
                onClick={() => setWithdrawDrawer(true)}
              >
                Realizar Saque
              </Col>
              <Col
                span={3}
                style={{ textAlign: "right" }}
                className={"selectable-footer-bar"}
                onClick={() => setDayEndDrawer(true)}
              >
                Realizar Cierre
              </Col>
            </Row>
          </Col>
        </Row>
        <Drawer
          title={"Formulario de Saque"}
          closable={false}
          visible={withdrawDrawer}
          onClose={() => setWithdrawDrawer(false)}
          width={450}
        >
          <WithdrawForm
            drawer={drawer}
            onSuccess={() => setWithdrawDrawer(false)}
          />
        </Drawer>
        <Drawer
          title={"Formulario de Cierre"}
          closable={false}
          visible={dayEndDrawer}
          onClose={() => setDayEndDrawer(false)}
          width={450}
        >
          <Form
            form={dayEndForm}
            name={"DayEndForm"}
            onFinish={() => setPostingDayEnd(true)}
            layout={"vertical"}
          >
            <Form.Item
              label={"Cantidad Total"}
              name={"amount"}
              rules={[
                {
                  required: true,
                  message: "Por favor especifique la cantidad total del caja",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={currencyFormat}
                parser={currencyParser}
              />
            </Form.Item>
            <Form.Item
              label={"Cantidad Restante"}
              name={"amountLeft"}
              rules={[
                {
                  required: true,
                  message:
                    "Por favor especifique la cantidad que se queda en el caja",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                formatter={currencyFormat}
                parser={currencyParser}
              />
            </Form.Item>
            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
        <Drawer
          title={"Formulario de Recibo"}
          closable={false}
          visible={receiptDrawer}
          onClose={() => setReceiptDrawer(false)}
          width={450}
        >
          <ReceiptForm
            drawer={drawer}
            onFormSuccess={(receiptId) => {
              history.push(`/checkout_receipt/${receiptId}`);
            }}
          />
        </Drawer>
      </div>
    );
  } else {
    return <Skeleton active />;
  }
};

export default Checkout;
