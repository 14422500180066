import { Button, Form, Input, InputNumber, message } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { api } from "../../../utils/api";
import { currencyFormat, currencyParser } from "../../../utils/formaters";

const WithdrawForm = ({ drawer, onSuccess }) => {
  const [withdrawForm] = Form.useForm();
  const [posting, setPosting] = useState(null);
  const [postEnd, setPostEnd] = useState(false);

  useEffect(() => {
    if (posting) {
      api
        .post("/drawers/withdraw", {
          drawerId: drawer.id,
          amount: posting.quantity,
          reason: posting.reason,
        })
        .then((res) => {
          message.success("Saque Realizado");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setPosting(null);
          setPostEnd(true);
        });
    }
  }, [posting, drawer]);

  useEffect(() => {
    if (postEnd) {
      withdrawForm.resetFields();
      onSuccess();
      setPostEnd(false);
    }
  }, [postEnd, withdrawForm, onSuccess]);

  return (
    <Form
      form={withdrawForm}
      name={"WithdrawForm"}
      onFinish={() => setPosting(withdrawForm.getFieldsValue())}
      layout={"vertical"}
    >
      <Form.Item
        label={"Cantidad"}
        name={"quantity"}
        rules={[{ required: true, message: "Por favor inque la cantidad" }]}
      >
        <InputNumber
          formatter={currencyFormat}
          parser={currencyParser}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label={"Descripción"}
        name={"reason"}
        rules={[
          { required: true, message: "Por favor indique el motivo" },
          {
            min: 10,
            message: "Minimo 10 caracteres",
          },
        ]}
      >
        <Input.TextArea rows={3} />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default WithdrawForm;
