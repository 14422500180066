import {
  CheckOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  message,
  PageHeader,
  Skeleton,
  Table,
  Form,
  Select,
  InputNumber,
  Input,
} from "antd";
import confirm from "antd/lib/modal/confirm";
import dayjs from "dayjs";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import BillStatusTag from "../../../components/tags/billStatusTag";
import CreditNoteTypeTag from "../../../components/tags/creditNoteTypeTag";
import { api, apiDefaults } from "../../../utils/api";
import {
  currencyFormat,
  currencyFormatDecimal,
  currencyParser,
} from "../../../utils/formaters";

const CreditNoteDetails = () => {
  const { id } = useParams();
  const [creditNote, setCreditNote] = useState(null);
  const [itemDrawer, setItemDrawer] = useState(false);
  const [columns, setColumns] = useState([]);
  const [itemForm] = Form.useForm();
  const [posting, setPosting] = useState(null);
  const [postingEnd, setPostingEnd] = useState(false);
  const [bill, setBill] = useState(null);

  function printCreditNote() {
    confirm({
      title: "Desea imprimir la nota de crédito?",
      icon: <PrinterOutlined />,
      okText: "Si",
      cancelText: "No",
      onOk: () => {
        window
          .open(`${apiDefaults.baseURL}/credit_notes/${id}/pdf`, "_blank")
          .focus();
      },
    });
  }

  function fetchCreditNote() {
    api
      .get(`/credit_notes/${id}`)
      .then((res) => {
        setCreditNote(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  const cbFetchCreditNote = useCallback(fetchCreditNote, [id]);

  function removeItem(itemId) {
    api
      .delete(`/credit_notes/items/${itemId}`)
      .then((res) => {
        message.success("Item Removido");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        cbFetchCreditNote();
      });
  }

  function completeCreditNote() {
    api
      .post(`/credit_notes/${id}/complete`)
      .then((res) => {
        message.success("Lá nota de crédita fue completada");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        cbFetchCreditNote();
      });
  }

  function cancelCreditNote() {
    api
      .delete(`/credit_notes/${id}`)
      .then((res) => {
        message.success("Fue Cancelada la Nota de Credito");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        cbFetchCreditNote();
      });
  }

  useEffect(() => {
    const baseColumns = [
      { title: "Cantidad", dataIndex: "quantity" },
      { title: "Descripción", dataIndex: "description" },
      {
        title: "Precio",
        dataIndex: "price",
        render: (price, _) => currencyFormatDecimal(price),
      },
      {
        title: "Exentas",
        dataIndex: "iva0",
        render: (data, _) => currencyFormat(data),
      },
      {
        title: "Iva 5%",
        dataIndex: "iva5",
        render: (data, _) => currencyFormat(data),
      },
      {
        title: "Iva 10%",
        dataIndex: "iva10",
        render: (data, _) => currencyFormat(data),
      },
    ];

    const columns = [];

    columns.push(...baseColumns);
    if (creditNote?.status === "PENDING") {
      columns.push({
        title: "Acciones",
        dataIndex: "id",
        width: 80,
        render: (_, row) =>
          creditNote.status === "PENDING" ? (
            <Button type={"link"} danger onClick={() => removeItem(row.id)}>
              <DeleteOutlined />
            </Button>
          ) : (
            <></>
          ),
      });
    }

    setColumns(columns);
  }, [creditNote]);

  useEffect(() => {
    cbFetchCreditNote();
  }, [id, cbFetchCreditNote]);

  useEffect(() => {
    document.title = `${id} - Nota de Crédito`;
  }, [id]);

  useEffect(() => {
    if (creditNote && !bill) {
      api
        .get(`/bills/${creditNote.billId}`)
        .then((res) => {
          setBill(res);
        })
        .catch((err) => {
          message.error("Ocurrio un error al buscar la Factura de Origen");
        });
    }
  }, [creditNote, bill]);

  useEffect(() => {
    if (posting) {
      api
        .put("/credit_notes/items", {
          creditNoteId: creditNote.id,
          billItemId: posting.billItem,
          price: posting.price,
          quantity: posting.quantity,
        })
        .then((res) => {
          message.success("Fue adicionado el Item a la Nota de crédito");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setPosting(null);
          setPostingEnd(true);
        });
    }
  }, [posting]);

  useEffect(() => {
    if (postingEnd) {
      itemForm.resetFields();
      setPostingEnd(false);
      cbFetchCreditNote();
    }
  }, [postingEnd, itemForm, cbFetchCreditNote]);

  if (creditNote) {
    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={"Detalles de la Nota de Crédito"}
        tags={<BillStatusTag status={creditNote.status} />}
        extra={[
          <Button onClick={printCreditNote}>
            <PrinterOutlined /> Imprimir
          </Button>,
          creditNote.status === "PENDING" ? (
            <Button
              key={"add_item"}
              type={"primary"}
              onClick={() => setItemDrawer(true)}
            >
              <PlusOutlined /> Adicionar Item
            </Button>
          ) : (
            <></>
          ),
          creditNote.status === "PENDING" ? (
            <Button
              key={"complete"}
              type={"primary"}
              onClick={() => completeCreditNote()}
            >
              <CheckOutlined /> Finalizar
            </Button>
          ) : (
            <></>
          ),
          creditNote.status !== "CANCELED" ? (
            <Button
              key={"cancel"}
              type={"primary"}
              onClick={() => cancelCreditNote()}
              danger
            >
              <DeleteOutlined /> Cancelar
            </Button>
          ) : (
            <></>
          ),
        ]}
      >
        <Descriptions bordered size={"small"}>
          <Descriptions.Item label={"ID"}>{creditNote.id}</Descriptions.Item>
          <Descriptions.Item label={"Fecha"}>
            {dayjs(creditNote.date).format("DD/MM/YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label={"Tipo"}>
            {<CreditNoteTypeTag type={creditNote.type} />}
          </Descriptions.Item>
          <Descriptions.Item
            label={"Entidade"}
            span={2}
          >{`${creditNote.entity.id} - ${creditNote.entity.name}`}</Descriptions.Item>
          <Descriptions.Item label={"Ruc o CI"}>
            {creditNote.entityDoc}
          </Descriptions.Item>
          <Descriptions.Item label={"Id Factura"}>
            {`${creditNote.bill.type === "SALE" ? "VENTA" : "COMPRA"}: ${
              creditNote.billId
            }`}{" "}
            <Link
              to={`/${
                creditNote.bill.type === "SALE" ? "sales" : "purchases"
              }/${creditNote.billId}`}
            >
              <EyeOutlined />
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label={"Nro.Factura"}>
            {creditNote.factura}
          </Descriptions.Item>
          <Descriptions.Item label={"Total"}>
            {currencyFormat(creditNote.total)}
          </Descriptions.Item>
        </Descriptions>
        {/* <Divider orientation="right">Articulos</Divider> */}
        <Table
          dataSource={creditNote.items}
          rowKey={"id"}
          pagination={false}
          columns={columns}
        />
        {/* <Divider orientation="right">IVA</Divider> */}
        <Descriptions bordered size={"small"}>
          <Descriptions.Item label={"Exentas"}>
            {currencyFormat(creditNote.iva0)}
          </Descriptions.Item>
          <Descriptions.Item label={"Gravada IVA 5%"}>
            {currencyFormat(creditNote.iva5)}
          </Descriptions.Item>
          <Descriptions.Item label={"Gravada IVA 10%"}>
            {currencyFormat(creditNote.iva10)}
          </Descriptions.Item>
          <Descriptions.Item label={"IVA 5%"}>
            {currencyFormat(creditNote.liqIva5)}
          </Descriptions.Item>
          <Descriptions.Item label={"IVA 10%"}>
            {currencyFormat(creditNote.liqIva10)}
          </Descriptions.Item>
          <Descriptions.Item label={"Total IVA"}>
            {currencyFormat(creditNote.ivaTotal)}
          </Descriptions.Item>
        </Descriptions>

        <Drawer
          title={"Formulário de Ítem"}
          closable={false}
          visible={itemDrawer}
          onClose={() => setItemDrawer(false)}
          width={450}
        >
          <Form
            form={itemForm}
            name={"ItemForm"}
            onFinish={(values) => {
              setPosting(values);
            }}
            layout={"vertical"}
            onChange={(v) => {
              itemForm.setFieldsValue({
                total:
                  (itemForm.getFieldValue("price") || 0) *
                  (itemForm.getFieldValue("quantity") || 0),
              });
            }}
          >
            <Form.Item
              label={"Producto"}
              name={"billItem"}
              rules={[
                {
                  required: true,
                  message: "Por favor selecione un ítem de la factura",
                },
              ]}
            >
              <Select
                onChange={(value) => {
                  const bi = bill.items.find((i) => i.id === value);
                  itemForm.setFieldsValue({
                    quantity: bi.quantity,
                    price: bi.price,
                    total: bi.quantity * bi.price,
                  });
                }}
              >
                {bill?.items.map((billItem) => (
                  <Select.Option value={billItem.id}>
                    {`${billItem.quantity}x ${
                      billItem.description
                    } ${currencyFormat(billItem.price)}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={"Cantidad a Devolver"}
              name={"quantity"}
              rules={[
                { required: true, message: "Por favor especifica la cantidad" },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                disabled={creditNote.type !== "RETURN"}
              />
            </Form.Item>

            <Form.Item
              label={"Precio"}
              name={"price"}
              rules={[
                { required: true, message: "Por favor especifica el precio" },
              ]}
            >
              <InputNumber
                formatter={currencyFormat}
                parser={currencyParser}
                style={{ width: "100%" }}
                disabled={creditNote.type !== "DISCOUNT"}
              />
            </Form.Item>

            <Form.Item label={"Total"} name={"total"}>
              <InputNumber
                formatter={currencyFormat}
                parser={currencyParser}
                style={{ width: "100%" }}
                disabled
              />
            </Form.Item>

            <Form.Item>
              <Button type={"primary"} htmlType={"submit"}>
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </PageHeader>
    );
  } else {
    return <Skeleton active />;
  }
};

export default CreditNoteDetails;
