import { Select, Spin } from "antd";
import { useMemo, useRef, useState } from "react";

const DebounceSelect = ({
  fetchOptions,
  placeholder = "Tipea para buscar",
  debounceTimeout = 800,
  preventEnter = true,
  searchOnFocus = false,
  searchRef = null,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, 250);
  }, [fetchOptions]);
  return (
    <Select
      ref={searchRef}
      labelInValue
      placeholder={placeholder}
      filterOption={false}
      onSearch={debounceFetcher}
      onFocus={searchOnFocus ? () => debounceFetcher() : () => {}}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
};

export default DebounceSelect;
