import { Button, Descriptions, Form, Input, InputNumber, message } from "antd"
import { api } from "../../../../utils/api"
import DebounceSelect from "../../../../components/search/debounceSelect"
import {
  currencyFormatDecimal,
  currencyParserDecimal,
} from "../../../../utils/formaters"
import { useContext, useRef } from "react"
import { useEffect } from "react"
import { fetchItems } from "../../../../utils/selectFetchers"
import { BrowserContext } from "../../../../utils/browserContext"
import { useState } from "react"

const BillItemForm = ({ bill, onUpdateNeeded }) => {
  const [itemForm] = Form.useForm()
  const itemSelectRef = useRef(null)
  const [{ user }] = useContext(BrowserContext)
  const [selectedItem, setSelectedItem] = useState(null)
  const [itemDetails, setItemDetails] = useState(null)

  const truckMode =
    bill?.type === "SALE" && process.env["REACT_APP_TRUCK_WEIGHT"] === "true"

  function onFinish(values) {
    if (!user.roles.includes("ADM")) {
      message.warn("Es necesario el grupo administrativo en usuario")
      return
    }

    let meta = {}
    if (truckMode) {
      meta = {
        ...meta,
        tare: values.tare,
        gross: values.gross,
      }
    }

    api
      .put(`/bills/items/`, {
        billId: bill.id,
        itemId: values.itemId.value,
        quantity: values.quantity,
        price: values.price,
        batch: values.batch,
        meta: meta,
      })
      .then((res) => {
        message.success("Item adicionado")
        itemForm.resetFields()
        setSelectedItem(null)
        setItemDetails(null)
      })
      .catch((err) => {
        message.error(err.message)
      })
      .finally(() => onUpdateNeeded())
  }

  useEffect(() => {
    itemSelectRef?.current?.focus()
  }, [])

  useEffect(() => {
    if (selectedItem) {
      api.get('/catalog/' + selectedItem.key)
        .then(res => {
          setItemDetails(res)
        }).catch(err => {
          message.error(err.message)
        })
    }
  }, [selectedItem])

  return (
    <Form
      form={itemForm}
      name={"SaleBillItemForm"}
      layout={"vertical"}
      onFinish={onFinish}
      onChange={(_) => {
        if (truckMode) {
          itemForm.setFieldsValue({
            quantity:
              (itemForm.getFieldValue("gross") || 0) -
              (itemForm.getFieldValue("tare") || 0),
          })
        }
      }}
    >
      <Form.Item
        label={"Ítem"}
        name={"itemId"}
        rules={[{ required: true, message: "Por favor seleciona el iten" }]}
      >
        <DebounceSelect
          searchRef={itemSelectRef}
          showSearch
          value={selectedItem}
          onChange={(value) => {
            setSelectedItem(value)
          }}
          searchOnFocus={true}
          fetchOptions={fetchItems}
          style={{ width: "100%" }}
        />
      </Form.Item>

      {
        itemDetails?.batchControl ?
          <Form.Item label="Lote" name="batch" rules={[{ required: true, message: 'Por favor informe el Lote del producto' }, { min: 3, message: 'Minimo de 2 caracteres' }]}>
            <Input />
          </Form.Item>
          : <></>
      }

      {truckMode ? (
        <>
          <Form.Item
            label={"Tara"}
            name={"tare"}
            rules={[{ required: true, message: "Por favor informe la Tara" }]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={"Peso Bruto"}
            name={"gross"}
            rules={[
              { required: true, message: "Por favor informe el peso liquido" },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </>
      ) : (
        <></>
      )}

      <Form.Item
        label={truckMode ? "Peso Liq." : "Cantidad"}
        name={"quantity"}
        rules={[
          { required: true, message: "Por favor especifique la cantidad" },
        ]}
      >
        <InputNumber
          decimalSeparator={","}
          style={{ width: "100%" }}
          disabled={truckMode}
        />
      </Form.Item>

      <Form.Item
        label={"Precio"}
        name={"price"}
        rules={[{ required: true, message: "Por favor indique el precio" }]}
      >
        <InputNumber
          formatter={currencyFormatDecimal}
          parser={currencyParserDecimal}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default BillItemForm
