import { Button, Form, message } from "antd";
import { useState, useEffect } from "react";
import DebounceSelect from "../../../components/search/debounceSelect";
import { api } from "../../../utils/api";
import { fetchBranches } from "../../../utils/selectFetchers";

const StockCountForm = ({ onUpdateNeeded }) => {
  const [countForm] = Form.useForm();
  const [posting, setPosting] = useState(null);

  function submitForm(values) {
    setPosting(values);
  }

  useEffect(() => {
    if (posting) {
      api
        .post("/stock_counts", {
          branchId: posting.branch.key,
        })
        .then((res) => {
          message.success("Fue Abierta el Recuento de Stock");
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          onUpdateNeeded();
          setPosting(null);
          countForm.resetFields();
        });
    }
  }, [posting, countForm, onUpdateNeeded]);

  return (
    <Form
      form={countForm}
      name={"countForm"}
      onFinish={submitForm}
      layout={"vertical"}
    >
      <Form.Item
        label={"Surucrsal"}
        name={"branch"}
        rules={[{ required: true, message: "Por favor seleciona la sucursal" }]}
      >
        <DebounceSelect
          showSearch
          searchOnFocus={true}
          fetchOptions={fetchBranches}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default StockCountForm;
