import { PlusOutlined } from "@ant-design/icons";
import {
  Dropdown,
  message,
  PageHeader,
  Table,
  Menu,
  Drawer,
  Button,
} from "antd";
import dayjs from "dayjs";
import Search from "antd/lib/input/Search";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import BillStatusTag from "../../../../components/tags/billStatusTag";
import { api } from "../../../../utils/api";
import useSessionState from "../../../../utils/useSessionState";
import SaleBillForm from "./saleBillForm";

const SalesBills = () => {
  const [sales, setSales] = useState([]);
  const [search, setSearch] = useSessionState("sales_search", "");
  const [query, setQuery] = useSessionState("sales_query", {
    search: "",
  });
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [, setSelectedSale] = useState(null);

  function showDrawer(bill) {
    setSelectedSale(bill);
    setDrawerVisible(true);
  }

  function onCloseDrawer() {
    setSelectedSale(null);
    setDrawerVisible(false);
  }

  function onFormSuccess() {
    onCloseDrawer();
  }

  function fetchBills() {
    setQuery({
      search: search,
    });
  }

  useEffect(() => {
    document.title = "Facturas de Venta";
  });

  useEffect(() => {
    const fetchData = () => {
      setLoading(true);
      api
        .get("/bills", {
          ...query,
          type: "SALE",
        })
        .then((res) => {
          setSales(res);
        })
        .catch((err) => {
          message.error(err.message);
          setSales([]);
        })
        .finally(() => setLoading(false));
    };
    if (query?.search.length > -1) fetchData();
  }, [query]);

  const columns = [
    { title: "Codigo", dataIndex: "id", width: 80, fixed: "left" },
    {
      title: "Fecha",
      dataIndex: "date",
      width: 120,
      fixed: "left",
      render: (date, _) => dayjs(date).format("DD/MM/YYYY"),
    },
    { title: "Sucursal", dataIndex: "id", render: (_, row) => row.branch.code },
    {
      title: "Estado",
      dataIndex: "status",
      width: 140,
      render: (status, _) => <BillStatusTag status={status} />,
    },
    {
      title: "Cond. Pago",
      dataIndex: "credit",
      render: (_, row) => (row.credit ? "Crédito" : "Contado"),
    },
    {
      title: "Nro. Factura",
      dataIndex: "factura",
    },
    {
      title: "Cliente",
      width: 250,
      dataIndex: "id",
      render: (_, row) => row.entity.name,
    },
    { title: "Doc. Cliente", dataIndex: "entityDocument" },
    { title: "Total", dataIndex: "total", width: 100, fixed: "right" },
    {
      title: "Acciones",
      dataIndex: "id",
      fixed: "right",
      width: 150,
      render: (_, row) => (
        <Dropdown.Button
          // onClick={() => history.push(`/sales/${row.id}`)}
          overlay={
            <Menu>
              <Menu.Item key="openNewTab">
                <a
                  href={`/sales/${row.id}`}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Abrir en nueva guia
                </a>
              </Menu.Item>
            </Menu>
          }
        >
          <Link to={`/sales/${row.id}`}>Visualizar</Link>
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Facturas de Venta"}
      subTitle={"Lista de facturas de venta"}
      extra={[
        <Button key={"add"} type={"primary"} onClick={() => showDrawer(null)}>
          <PlusOutlined /> Nueva Factura
        </Button>,
      ]}
    >
      <Search
        placeholder={"Tipea para buscar"}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSearch={() => fetchBills()}
        enterButton
      />
      <Table
        size={"small"}
        loading={loading}
        dataSource={sales}
        columns={columns}
        rowKey={"id"}
        scroll={{ x: 1300 }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "50", "100", "500"],
        }}
        style={{ marginTop: 8 }}
      />

      <Drawer
        title={"Formulário de Factura Venta"}
        closable={false}
        visible={drawerVisible}
        onClose={onCloseDrawer}
        width={450}
      >
        <SaleBillForm
          onFormSuccess={onFormSuccess}
          onUpdateNeeded={fetchBills}
        />
      </Drawer>
    </PageHeader>
  );
};

export default SalesBills;
