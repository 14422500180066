import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { api } from "../../../utils/api";

const UserForm = ({ user, onFormSuccess }) => {
  const [userForm] = Form.useForm();

  function onFinish(values) {
    const reqMethod = user ? "PUT" : "POST";
    const reqUrl = "/users" + (user ? "/" + user.id : "");
    api
      .req(reqMethod, reqUrl, {
        ...values,
      })
      .then((res) => {
        message.info("Usuario creado");
        onFormSuccess();
        userForm.resetFields();
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  useEffect(() => {
    if (user) {
      userForm.setFieldsValue({
        username: user.username,
        fullName: user.fullName,
        password: user.password,
      });
    } else {
      userForm.resetFields();
    }
  }, [user, userForm]);

  return (
    <Form
      form={userForm}
      name={"UserForm"}
      layout={"vertical"}
      onFinish={onFinish}
      onFin
    >
      <Form.Item
        label={"Usuario"}
        name={"username"}
        rules={[
          { required: true, message: "Por favor insira un usuario" },
          { min: 3, message: "Es necessario tener al menos 3 caracteres" },
          { max: 20, message: "No se puede tener mas de 20 caracteres" },
        ]}
      >
        <Input placeholder={"Usuario(Login)"} />
      </Form.Item>

      <Form.Item
        label={"Nombre"}
        name={"fullName"}
        rules={[
          { required: true, message: "Por favor insira el Nombre del usuario" },
          { min: 3, message: "Es necessario tener al menos 3 caracteres" },
        ]}
      >
        <Input />
      </Form.Item>
      {user ? (
        ""
      ) : (
        <Form.Item
          label={"Contraseña"}
          name={"password"}
          rules={[
            { required: true, message: "Por favor insira la contraseña" },
            { min: 6, message: "Es necessario tener al menos 6 caracteres" },
          ]}
        >
          <Input />
        </Form.Item>
      )}

      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
