import { EyeOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, Drawer, message, PageHeader, Table } from "antd"
import dayjs from "dayjs"
import { useEffect } from "react"
import { useCallback } from "react"
import { useState } from "react"
import { useHistory } from "react-router"
import BillStatusTag from "../../../components/tags/billStatusTag"
import { api } from "../../../utils/api"
import TransferenceForm from "./transferenceForm"

const TransferencePage = () => {
  const [transferences, setTransferences] = useState([])
  const [formDrawer, setFormDrawer] = useState(false)
  const history = useHistory()

  function fetchTransferences() {
    api
      .get("/transferences")
      .then((res) => {
        setTransferences(res)
      })
      .catch((err) => {
        message.error(err.message)
        setTransferences([])
      })
  }

  const cbFetchTransferences = useCallback(fetchTransferences, [])

  useEffect(() => {
    cbFetchTransferences()
  }, [cbFetchTransferences])

  useEffect(() => {
    document.title = "Transferencias"
  }, [])

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Lista de Transferencias"}
      extra={[
        <Button type={"primary"} onClick={() => setFormDrawer(true)}>
          <PlusOutlined /> Nueva Transferencia
        </Button>,
      ]}
    >
      <Table
        dataSource={transferences}
        rowKey={"id"}
        columns={[
          { title: "Codigo", dataIndex: "id" },
          {
            title: "Fecha",
            dataIndex: "date",
            render: (_, row) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            title: "Status",
            dataIndex: "status",
            render: (_, row) => <BillStatusTag status={row.status} />,
          },
          {
            title: "Origen",
            dataIndex: "originId",
            render: (_, row) => row.origin.name,
          },
          {
            title: "Destino",
            dataIndex: "destinationId",
            render: (_, row) => row.destination.name,
          },
          {
            title: "Acciones",
            dataIndex: "id",
            width: 120,
            render: (_, row) => (
              <Button onClick={() => history.push(`/transferences/${row.id}`)}>
                <EyeOutlined /> Visualizar
              </Button>
            ),
          },
        ]}
      />
      <Drawer
        visible={formDrawer}
        closable={false}
        title={"Crear nueva Transferencia"}
        onClose={() => setFormDrawer(false)}
        width={450}
      >
        <TransferenceForm
          onUpdateNeeded={() => {
            fetchTransferences()
            setFormDrawer(false)
          }}
        />
      </Drawer>
    </PageHeader>
  )
}

export default TransferencePage
