import { DownloadOutlined } from "@ant-design/icons"
import { PageHeader, Form, DatePicker, Button, Select } from "antd"
import dayjs from "dayjs"
import { apiDefaults } from "../../utils/api"

const BillExtract = () => {
  function handleSubmit(values) {
    const start = dayjs(values.dates[0]).format("YYYY-MM-DD")
    const end = dayjs(values.dates[1]).format("YYYY-MM-DD")
    window.open(
      `${apiDefaults.baseURL}/reports/bill_extract?type=${values.type}&start=${start}&end=${end}`
    )
  }
  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Extracto de Facturas"}
      style={{
        height: "70vh",
      }}
    >
      <Form name={"ExtractSales"} onFinish={handleSubmit} layout={"inline"}>
        <Form.Item label={"Tipo"} name={"type"}>
          <Select style={{ width: 120 }}>
            <Select.Option value={"SALE"}>VENTAS</Select.Option>
            <Select.Option value={"PURCHASE"}>COMPRAS</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={"Fecha"} name={"dates"}>
          <DatePicker.RangePicker format={"DD/MM/YYYY"} />
        </Form.Item>
        <Form.Item>
          <Button type={"primary"} htmlType={"submit"}>
            <DownloadOutlined />
            Exportar
          </Button>
        </Form.Item>
      </Form>
    </PageHeader>
  )
}

export default BillExtract
