import { PrinterOutlined, UserOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import SideMenu from "../components/menu/sideMenu";
import { BrowserContext } from "../utils/browserContext";
import { FaCashRegister, FaStore } from "react-icons/fa";

const routes = [
  { key: "1", label: "Usuarios", path: "/users", icon: <UserOutlined /> },
  { key: "2", label: "Sucursales", path: "/branches", icon: <FaStore /> },
  { key: "3", label: "Cajas", path: "/drawers", icon: <FaCashRegister /> },
  {
    key: "4",
    label: "Cfg. Impressión",
    path: "/print_config",
    icon: <PrinterOutlined />,
  },
];

const AdminLayout = ({ children }) => {
  return <SideMenu routes={routes}>{children}</SideMenu>;
};

const AdminLayoutRoute = ({ component: Component, ...rest }) => {
  const [browserData] = useContext(BrowserContext);
  const hasAccess = browserData.user;
  // browserData.user && browserData.user.modules.includes('admin');

  return (
    <Route
      {...rest}
      render={(props) =>
        hasAccess ? (
          <AdminLayout>
            <Component {...props} />
          </AdminLayout>
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
};

export default AdminLayoutRoute;
