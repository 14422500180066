import { Tag } from "antd";

const CreditNoteTypeTag = ({ type }) => {
  const options = {
    RETURN: <Tag color={"volcano"}>DEVOLUCIÓN</Tag>,
    DISCOUNT: <Tag color={"cyan"}>DESCUENTO</Tag>,
    default: <Tag>No indentificado</Tag>,
  };

  return options[type] || options["default"];
};

export default CreditNoteTypeTag;
