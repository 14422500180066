import { Button, Form, InputNumber, message, Select } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { api } from "../../../utils/api";
import { currencyFormat, currencyParser } from "../../../utils/formaters";

const ReceiptPaymentForm = ({ receipt, onFormSuccess }) => {
  const [paymentForm] = Form.useForm();
  const [bills, setBills] = useState([]);
  const [posting, setPosting] = useState(null);
  const [postingEnd, setPostingEnd] = useState(false);

  useEffect(() => {
    if (posting) {
      api
        .post("/receipts/payments", {
          receiptId: receipt.id,
          billId: posting.billId,
          amount: posting.amount,
        })
        .then((res) => {
          message.success("Pago adicionado");
          setPosting(null);
          setPostingEnd(true);
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  }, [posting, receipt]);

  useEffect(() => {
    if (postingEnd) {
      setPostingEnd(false);
      paymentForm.resetFields();
      onFormSuccess();
    }
  }, [postingEnd, onFormSuccess, paymentForm]);

  useEffect(() => {
    if (receipt) {
      api
        .get("/bills", {
          type: receipt.type === "CHARGE" ? "SALE" : "PURCHASE",
          credit: true,
          status: "COMPLETED",
          notPaymentStatus: "COMPLETED",
          entityId: receipt.entity.id,
        })
        .then((res) => {
          setBills(res);
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  }, [receipt]);

  return (
    <Form
      form={paymentForm}
      name={"PaymentForm"}
      onFinish={(values) => {
        setPosting(values);
      }}
      layout={"vertical"}
    >
      <Form.Item
        label={"Factura"}
        name={"billId"}
        rules={[{ required: true, message: "Por favor selecione la factura" }]}
      >
        <Select>
          {bills.map((bill) => (
            <Select.Option value={bill.id}>{`${
              bill.factura || "Sin Nro Factura"
            } - ${currencyFormat(bill.paymentLeft)}`}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={"Cantidad"}
        name={"amount"}
        rules={[
          {
            required: true,
            message: "Por favor especifique la cantidad del pago",
          },
        ]}
      >
        <InputNumber
          formatter={currencyFormat}
          parser={currencyParser}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ReceiptPaymentForm;
