import { Button, Descriptions, message, PageHeader, Skeleton } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TagControl from "../../../components/tags/tagControl";
import { api } from "../../../utils/api";

const UserDetails = () => {
  let { id } = useParams();
  const [user, setUser] = useState();
  const [branches, setBranches] = useState([]);

  function addRole(role) {
    api
      .put(`/users/roles/`, {
        userId: id,
        role: role,
      })
      .then((res) => {
        message.success("Adicionado grupo al usuario");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => fetchUser());
  }

  function removeRole(role) {
    api
      .delete(`/users/roles`, {
        userId: id,
        role: role,
      })
      .then((res) => {
        message.info("Removido el grupo de usuario");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => fetchUser());
  }

  function addBranch(branchId) {
    api
      .put("/users/branches", {
        branchId: branchId,
        userId: id,
      })
      .then((res) => {
        message.success("Adicionada sucursal al usuario");
      })
      .catch((err) => message.error(err.message))
      .finally(() => fetchUser());
  }

  function removeBranch(branchId) {
    api
      .delete("/users/branches", {
        branchId: branchId,
        userId: id,
      })
      .then((res) => {
        message.success("Removido sucursal del usuario");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => fetchUser());
  }

  function resetPassword() {
    api
      .post(`/users/${id}/reset_password`)
      .then((res) => {
        message.success("Contraseña actualizada al padrón");
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  function activateUser() {
    api
      .post(`/users/${id}/activate`)
      .then((res) => {
        message.success("Usuario activado con sucesso");
        fetchUser();
      })
      .catch((err) => {
        message.error(err.message);
        fetchUser();
      });
  }

  function inactivateUser() {
    api
      .post(`/users/${id}/inactivate`)
      .then((res) => {
        message.success("Usuario inactivado con sucesso");
        fetchUser();
      })
      .catch((err) => {
        message.error(err.message);
        fetchUser();
      });
  }

  function fetchUser() {
    api
      .get("/users/" + id)
      .then((res) => {
        setUser(res);
      })
      .catch((err) => message.error(err.message));
  }

  const cbFetchUser = useCallback(fetchUser, [id]);

  useEffect(() => {
    cbFetchUser();
  }, [cbFetchUser]);

  useEffect(() => {
    api
      .get("/branches")
      .then((res) => setBranches(res))
      .catch((err) => message.error(err.message));
  }, []);

  useEffect(() => {
    document.title = user ? user.fullName : "";
  }, [user]);

  if (user) {
    return (
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={user.fullName}
        subTitle={"Detalles de usuario"}
        extra={[
          user.active ? (
            <Button type={"primary"} onClick={inactivateUser} danger>
              Inactivar Usuario
            </Button>
          ) : (
            <Button type={"primary"} onClick={activateUser} success>
              Activar Usuario
            </Button>
          ),
          <Button type={"primary"} onClick={resetPassword}>
            Resetear Contraseña
          </Button>,
        ]}
      >
        <Descriptions bordered>
          <Descriptions.Item label={"Codigo"}>{user.id}</Descriptions.Item>
          <Descriptions.Item label={"Login"}>{user.username}</Descriptions.Item>
          <Descriptions.Item label={"Activo"}>
            {user.active ? "Si" : "No"}
          </Descriptions.Item>
          <Descriptions.Item span={3} label={"Nombre"}>
            {user.fullName}
          </Descriptions.Item>
          <Descriptions.Item label={"Grupos"} span={3}>
            <TagControl
              tags={user.roles.map((role) => ({
                key: role.role,
                name: role.role === "ADM" ? "ADMINISTRATIVO" : "OPERATIVO",
              }))}
              options={["ADM", "OPE"]
                .filter((opt) => !user.roles.map((r) => r.role).includes(opt))
                .map((opt) => ({
                  key: opt,
                  value: opt,
                  display: opt === "ADM" ? "ADMINISTRATIVO" : "OPERATIVO",
                }))}
              onRemoveTag={removeRole}
              onSubmitTag={addRole}
            />
          </Descriptions.Item>

          <Descriptions.Item label={"Sucursales"} span={3}>
            <TagControl
              tags={user?.branches
                ?.map((b) => b.branch)
                .map((branch) => ({
                  key: branch.id,
                  value: branch.id,
                  name: branch.code,
                }))}
              options={branches
                ?.filter(
                  (branch) =>
                    !user?.branches?.map((b) => b.branch.id).includes(branch.id)
                )
                .map((branch) => ({
                  key: branch.id,
                  value: branch.id,
                  display: `${branch.code} - ${branch.name}`,
                }))}
              onRemoveTag={removeBranch}
              onSubmitTag={addBranch}
            />
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
    );
  } else {
    return <Skeleton active />;
  }
};

export default UserDetails;
