import axios from "axios"
import { message } from "antd"

const defaults = {
  baseURL: "/api",
  headers: () => ({
    "Content-Type": "application/json",
    //   'Access-Control-Allow-Origin': '*',
  }),
}

const _api = (method, url, param, responseType = "json") =>
  new Promise((resolve, reject) => {
    axios({
      url: `${defaults.baseURL}${url}`,
      method: method,
      headers: defaults.headers(),
      params: method === "get" ? param : undefined,
      data: method !== "get" ? param : undefined,
      withCredentials: true,
      responseType: responseType
    })
      .then((response) => resolve(response.data))
      .catch((err) => {
        // NULL error SHOULD NOT HAPPEND
        if (!err) {
          message.error(
            "Un erro ocurrio en el cliente. Por favor contacte el administrador!"
          )
          window.history.push("/login")
        }
        // Network Connection error
        else if (err.message && err.message === "Network Error") {
          message.error(
            "Ocurrio un problema al concetar con la internet. Por favor verifique su conexión."
          )
        }
        // RESPONSE ERROR WITHOUT SPECIFICATIONS
        else if (!err.response) {
          message.error(
            "No fue possible interpretar el error. Por favor contacte el adminsitrador!"
          )
        }
        // INVALID ATHENTICATION
        else if (err.response.status && err.response.status === 401) {
          message.error("Por favor realize el login.")
          const browserData = JSON.parse(localStorage.getItem("data"))
          const newData = {
            ...browserData,
            user: null,
          }
          localStorage.setItem("data", JSON.stringify(newData))
          window.location.href = "/login"
        }
        // FORBIDEN AUTHENTICATION
        else if (err.response.status && err.response.status === 403) {
          message.error("Permisso a la pantalla bloqueado!")
          window.history.back()
        }
        // Normal error handling!
        else {
          reject(err.response.data)
        }
      })
  })

export const apiDefaults = {
  ...defaults,
}

export const api = {
  req: (...args) => _api(...args),
  get: (...args) => _api("get", ...args),
  post: (...args) => _api("post", ...args),
  put: (...args) => _api("put", ...args),
  patch: (...args) => _api("patch", ...args),
  delete: (...args) => _api("delete", ...args),
}
