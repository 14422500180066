import { Col, message, Row, Skeleton } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Tooltip } from "recharts/lib";
import { Bar } from "recharts/lib/cartesian/Bar";
import { CartesianGrid } from "recharts/lib/cartesian/CartesianGrid";
import { XAxis } from "recharts/lib/cartesian/XAxis";
import { YAxis } from "recharts/lib/cartesian/YAxis";
import { BarChart } from "recharts/lib/chart/BarChart";
import { ResponsiveContainer } from "recharts/lib/component/ResponsiveContainer";
import { api } from "../../utils/api";
import { currencyFormat } from "../../utils/formaters";

const FinancesDash = () => {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [checkPending, setCheckPending] = useState(null);
  const [receiptsCharge, setReceiptsCharge] = useState([]);
  const [receiptsPayment, setReceiptsPayment] = useState([]);

  useEffect(() => {
    document.title = "Est. Financiero";
    api
      .get("/analitics/finances/paymentStatus")
      .then((res) => {
        setPaymentStatus(res);
      })
      .catch((err) => {
        message.error(err.message);
      });

    api
      .get("/analitics/finances/checksPending")
      .then((res) => {
        setCheckPending(res);
      })
      .catch((err) => {
        message.error(err.message);
      });

    api
      .get("/analitics/finances/receiptsGroupByDate", { type: "CHARGE" })
      .then((res) => {
        setReceiptsCharge(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
    api
      .get("/analitics/finances/receiptsGroupByDate", { type: "PAYMENT" })
      .then((res) => {
        setReceiptsPayment(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }, []);
  if (receiptsCharge.length > 0 && receiptsPayment.length > 0) {
    return (
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <div className="featured-card">
            <span className="featured-card-title">Total Pen. Pago</span>
            <br />
            <span className="featured-card-money">
              {currencyFormat(paymentStatus.pen_payment)}
            </span>
            <br />
            <span className="featured-card-desc">Total acumulado</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="featured-card">
            <span className="featured-card-title">Total Pen. Cobro</span>
            <br />
            <span className="featured-card-money">
              {currencyFormat(paymentStatus.pen_charge)}
            </span>
            <br />
            <span className="featured-card-desc">Total acumulado</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="featured-card">
            <span className="featured-card-title">Cheques a Cobrar</span>
            <br />
            <span className="featured-card-money">
              {currencyFormat(checkPending.total)}
            </span>
            <br />
            <span className="featured-card-desc">Total acumulado</span>
          </div>
        </Col>
        <Col span={24}>
          <div className="featured-card">
            <span className="featured-card-title">Recibos Cobro</span>
            <ResponsiveContainer width={"100%"} height={168}>
              <BarChart data={receiptsCharge}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  interval={0}
                  tickFormatter={(date) => dayjs(date).format("DD")}
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="total" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
            <span className="featured-card-desc">En los ultimos 30 dias</span>
          </div>
        </Col>
        <Col span={24}>
          <div className="featured-card">
            <span className="featured-card-title">Recibos Cobro</span>
            <ResponsiveContainer width={"100%"} height={168}>
              <BarChart data={receiptsPayment}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  interval={0}
                  tickFormatter={(date) => dayjs(date).format("DD")}
                />
                <YAxis />
                <Tooltip />
                <Bar dataKey="total" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
            <span className="featured-card-desc">En los ultimos 30 dias</span>
          </div>
        </Col>
      </Row>
    );
  } else {
    return <Skeleton active />;
  }
};

export default FinancesDash;
