import { Card, Col, message, PageHeader, Row, Skeleton } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { LineChart, Line, ResponsiveContainer, Tooltip } from "recharts";
import { Area } from "recharts/lib/cartesian/Area";
import { Bar } from "recharts/lib/cartesian/Bar";
import { CartesianGrid } from "recharts/lib/cartesian/CartesianGrid";
import { XAxis } from "recharts/lib/cartesian/XAxis";
import { YAxis } from "recharts/lib/cartesian/YAxis";
import { AreaChart } from "recharts/lib/chart/AreaChart";
import { BarChart } from "recharts/lib/chart/BarChart";
import { PieChart } from "recharts/lib/chart/PieChart";
import { Label } from "recharts/lib/component/Label";
import { LabelList } from "recharts/lib/component/LabelList";
import { Legend } from "recharts/lib/component/Legend";
import { Pie } from "recharts/lib/polar/Pie";
import { api } from "../../utils/api";
import { dayInMilis } from "../../utils/constUtils";
import { currencyFormat } from "../../utils/formaters";

const PurchaseDash = () => {
  const [branches, setBranches] = useState([]);
  const [purchasesAcc, setPurchasesAcc] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [branchDateData, setBranchDateData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [featuredData, setFeaturedData] = useState(null);

  useEffect(() => {
    document.title = "Est. Compras";
    api
      .get("/branches")
      .then((res) => {
        setBranches(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
    api
      .get("/analitics/bills/groupedByDay", {
        type: "PURCHASE",
        end: new Date(),
        start: new Date(new Date() - dayInMilis * 30),
      })
      .then((res) => {
        setPurchasesAcc(
          res.map((d) => {
            return {
              date: dayjs(d.date).format("DD/MM/YYYY"),
              sum_credit: d.sum_credit,
              sum_cash: d.sum_cash,
              count: d.count,
            };
          })
        );
      })
      .catch((err) => {
        message.error(err.message);
        message.warning("No se pudo cargar analisis de venta");
      });

    api
      .get("/analitics/bills/groupedByCategory", { type: "PURCHASE" })
      .then((res) => {
        setCategoryData(res);
      })
      .catch((err) => {
        message.error(err.message);
      });

    api
      .get("/analitics/bills/groupByBranchDate", { type: "PURCHASE" })
      .then((res) => {
        setBranchDateData(res);
      })
      .catch((err) => {
        message.error(err.message);
      });

    api
      .get("/analitics/bills/groupedByBranch", { type: "PURCHASE" })
      .then((res) => {
        setBranchData(res);
      })
      .catch((err) => {
        message.error(err.message);
      });

    api
      .get("/analitics/bills/statistics", { type: "PURCHASE" })
      .then((res) => {
        setFeaturedData(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }, []);
  if (purchasesAcc.length > 0) {
    return (
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <div className="featured-card">
            <span className="featured-card-title">Total Compras</span>
            <br />
            <span className="featured-card-money">
              {currencyFormat(featuredData?.total)}
            </span>
            <br />
            <span className="featured-card-desc">En los ultimos 30 dias</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="featured-card">
            <span className="featured-card-title">Total Efectivo</span>
            <br />
            <span className="featured-card-money">
              {currencyFormat(featuredData?.cash)}
            </span>
            <br />
            <span className="featured-card-desc">En los ultimos 30 dias</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="featured-card">
            <span className="featured-card-title">Total Credito</span>
            <br />
            <span className="featured-card-money">
              {currencyFormat(featuredData?.credit)}
            </span>
            <br />
            <span className="featured-card-desc">En los ultimos 30 dias</span>
          </div>
        </Col>
        <Col span={16}>
          <div className="featured-card">
            <span className="featured-card-title">Compras</span>
            <ResponsiveContainer width={"100%"} height={168}>
              <BarChart data={purchasesAcc}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  interval={1}
                  tickFormatter={(date) => date[0] + date[1]}
                />
                <YAxis />
                <Tooltip
                  formatter={(value, name, props) => {
                    if (name == "sum_credit") {
                      return [currencyFormat(value), "Total Credito"];
                    }

                    if (name === "sum_cash") {
                      return [currencyFormat(value), "Total Efectivo"];
                    }
                  }}
                />
                <Bar dataKey="sum_credit" stackId="a" fill="#8884d8" />
                <Bar dataKey="sum_cash" stackId="a" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
            <span className="featured-card-desc">En los ultimos 30 dias</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="featured-card">
            <span className="featured-card-title">Compras Por Categoria</span>
            <ResponsiveContainer height={168}>
              <PieChart height={160}>
                <Pie
                  data={categoryData}
                  cx="50%"
                  cy="50%"
                  outerRadius={40}
                  dataKey="sum"
                  nameKey="name"
                  fill="#8884d8"
                  isAnimationActive={false}
                  label={(row) => row.name}
                  paddingAngle={8}
                  minAngle={6}
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <span className="featured-card-desc">En los ultimos 30 dias</span>
          </div>
        </Col>
        <Col span={16}>
          <div className="featured-card">
            <span className="featured-card-title">Compras Por Sucursal</span>
            <ResponsiveContainer width={"100%"} height={168}>
              <AreaChart data={branchDateData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  interval={1}
                  tickFormatter={(date) => dayjs(date).format("DD")}
                />
                <YAxis />
                <Tooltip />
                {branches.map((branch) => (
                  <Area
                    type="monotone"
                    dataKey={branch.name}
                    stackId="1"
                    stroke="#8884d8"
                    fill="#8884d8"
                  />
                ))}
              </AreaChart>
            </ResponsiveContainer>
            <span className="featured-card-desc">En los ultimos 30 dias</span>
          </div>
        </Col>
        <Col span={8}>
          <div className="featured-card">
            <span className="featured-card-title">Compras Por Sucursal</span>
            <ResponsiveContainer height={168}>
              <PieChart height={160}>
                <Pie
                  data={branchData}
                  cx="50%"
                  cy="50%"
                  outerRadius={40}
                  dataKey="sum"
                  nameKey="name"
                  fill="#8884d8"
                  isAnimationActive={false}
                  label={(row) => row.name}
                  paddingAngle={5}
                  minAngle={5}
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <span className="featured-card-desc">En los ultimos 30 dias</span>
          </div>
        </Col>
      </Row>
    );
  } else {
    return <Skeleton active />;
  }
};

export default PurchaseDash;
