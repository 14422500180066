import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, message, PageHeader, Table } from "antd";
import dayjs from "dayjs";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { api } from "../../../utils/api";
import StockCountForm from "./stockCountForm";

const CountsPage = () => {
  const [counts, setCounts] = useState([]);
  const [formDrawer, setFormDrawer] = useState(false);
  const history = useHistory();

  function fetchCounts() {
    api
      .get("/stock_counts")
      .then((res) => {
        setCounts(res);
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  const cbFetchCounts = useCallback(fetchCounts, []);

  useEffect(() => {
    cbFetchCounts();
  }, [cbFetchCounts]);

  useEffect(() => {
    document.title = "Recuento Stock";
  }, []);

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title={"Recuento de Stock"}
      extra={[
        <Button type={"primary"} onClick={() => setFormDrawer(true)}>
          <PlusOutlined /> Abrir Recuento
        </Button>,
      ]}
    >
      <Table
        dataSource={counts}
        rowKey={"id"}
        columns={[
          { title: "Codigo", dataIndex: "id" },
          {
            title: "Fecha",
            dataIndex: "createdAt",
            render: (_, row) => dayjs(row.createdAt).format("DD/MM/YYYY"),
          },
          {
            title: "Sucursal",
            dataIndex: "id",
            render: (_, row) => row.branch.name,
          },
          {
            title: "Acciones",
            dataIndex: "id",
            width: 120,
            render: (_, row) => (
              <Button onClick={() => history.push("/stock_count/" + row.id)}>
                <EyeOutlined /> Visualizar
              </Button>
            ),
          },
        ]}
      />
      <Drawer
        visible={formDrawer}
        closable={false}
        title={"Abrir Recuento de Stock"}
        onClose={() => setFormDrawer(null)}
        width={450}
      >
        <StockCountForm
          onUpdateNeeded={() => {
            fetchCounts();
            setFormDrawer(false);
          }}
        />
      </Drawer>
    </PageHeader>
  );
};

export default CountsPage;
