import { DownloadOutlined } from "@ant-design/icons"
import { Button, DatePicker, Form, PageHeader } from "antd"
import dayjs from "dayjs"
import { apiDefaults } from "../../utils/api"

const GainsExtract = () => {
	function handleSubmit(values) {
		const start = dayjs(values.dates[0]).format('YYYY-MM-DD')
		const end = dayjs(values.dates[1]).format('YYYY-MM-DD')
		window.open(`${apiDefaults.baseURL}/reports/extracto_ganancia?start=${start}&end=${end}`)
	}

	return (
		<PageHeader
			ghost={false}
			onBack={() => window.history.back()}
			title={"Extracto de Ganancia Liquida"}
			style={{ height: '70vh' }}>
			<Form name="GainsExtract" onFinish={handleSubmit} layout="inline">
				<Form.Item label="Fecha" name={"dates"}>
					<DatePicker.RangePicker format={"DD/MM/YYYY"} />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						<DownloadOutlined />
						Exportar
					</Button>
				</Form.Item>
			</Form>
		</PageHeader>
	)
}

export default GainsExtract