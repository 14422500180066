import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, message, PageHeader, Table } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BillStatusTag from '../../../components/tags/billStatusTag';
import { api } from '../../../utils/api';
import StockAdjustmentForm from './stockAdjustmentForm';

const StockAdjustmentPage = () => {
  const [adjustments, setAdjustments] = useState([]);
  const [formDrawer, setFormDrawer] = useState(false);
  const history = useHistory();

  function fetchTransferences() {
    api
      .get('/stock_adjustments')
      .then((res) => {
        setAdjustments(res);
      })
      .catch((err) => {
        message.error(err.message);
        setAdjustments([]);
      });
  }

  const cbFetchTransferences = useCallback(fetchTransferences, []);
  useEffect(cbFetchTransferences, [cbFetchTransferences]);

  useEffect(() => {
    document.title = 'Transferencias';
  }, []);

  return (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title='Lista de Ajustes de Stock'
      extra={[
        <Button type='primary' onClick={() => setFormDrawer(true)}>
          <PlusOutlined /> Nuevo Ajuste
        </Button>,
      ]}
    >
      <Table
        dataSource={adjustments}
        rowKey='id'
        columns={[
          { title: 'Codigo', dataIndex: 'id' },
          {
            title: 'Fecha',
            dataIndex: 'date',
            render: (_, row) => dayjs(row.date).format('DD/MM/YYYY'),
          },
          {
            title: 'Status',
            dataIndex: 'status',
            render: (_, row) => <BillStatusTag status={row.status} />,
          },
          {
            title: 'Sucursal',
            dataIndex: 'row',
            render: (_, row) => row.branch.name,
          },
          {
            title: 'Acciones',
            dataIndex: 'id',
            width: 120,
            render: (_, row) => (
              <Button onClick={() => history.push(`/adjustments/${row.id}`)}>
                <EyeOutlined /> Visualizar
              </Button>
            ),
          },
        ]}
      />

      <Drawer
        visible={formDrawer}
        closable={false}
        title={'Crear Nuevo Ajuste'}
        onClose={() => setFormDrawer(false)}
        width={450}
      >
        <StockAdjustmentForm
          onUpdateNeeded={() => {
            fetchTransferences();
            setFormDrawer(false);
          }}
        />
      </Drawer>
    </PageHeader>
  );
};

export default StockAdjustmentPage;
