import { message, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { api } from "../../../utils/api";
import PriceSearch from "./PriceSearch";

const PriceSearchPage = () => {
  const { id } = useParams();
  const [branch, setBranch] = useState(null);

  useEffect(() => {
    api
      .get(`/branches/${id}`)
      .then((res) => setBranch(res))
      .catch((err) => message.error(err.message));
  }, [id]);

  useEffect(() => {
    if (branch) {
      document.title = `${branch.code} Consulta Precio`;
    } else {
      document.title = "Cargando...";
    }
  }, [branch]);

  if (branch) {
    return (
      <div style={{ padding: 16 }}>
        <PriceSearch branch={branch} />
      </div>
    );
  } else {
    return <Skeleton active />;
  }
};

export default PriceSearchPage;
