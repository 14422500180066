import { Tag } from "antd";

const BillPaymentStatus = ({ paymentStatus }) => {
  const options = {
    PENDING: <Tag color={"red"}>Pendiente</Tag>,
    PARTIAL: <Tag color={"gold"}>Parcial</Tag>,
    COMPLETED: <Tag color={"green"}>Completado</Tag>,
    default: <Tag>No especificado</Tag>,
  };

  return options[paymentStatus] || options["default"];
};

export default BillPaymentStatus;
