import { Button, Form, Input, message } from "antd";
import { useEffect } from "react";
import { api } from "../../../utils/api";

const EntityForm = ({ entity, onFormSuccess, onUpdateNeeded }) => {
  const [entityForm] = Form.useForm();

  function onFinish(values) {
    if (values?.ci?.length === 0 && values?.ruc?.length === 0) {
      message.error("Es necessario especificar el RUC o CI.");
      return;
    }

    const reqMethod = entity ? "PUT" : "POST";
    const reqUrl = "/entities" + (entity ? `/${entity.id}` : "");
    api
      .req(reqMethod, reqUrl, { ...values })
      .then((res) => {
        message.success("Entidad creada con sucesso");
        onFormSuccess();
        entityForm.resetFields();
      })
      .catch((err) => {
        message.error(err.message || "Verificar lista");
        onUpdateNeeded();
      });
  }

  useEffect(() => {
    if (entity) {
      entityForm.setFieldsValue({
        name: entity.name,
        ruc: entity.ruc,
        ci: entity.ci,
        address: entity.address,
      });
    } else {
      entityForm.resetFields();
    }
  }, [entity, entityForm]);

  return (
    <Form
      form={entityForm}
      name={"EntityForm"}
      layout={"vertical"}
      onFinish={onFinish}
    >
      <Form.Item
        label={"Nombre"}
        name={"name"}
        rules={[
          { required: true, message: "Por favor insira el nombre de entidad" },
          { required: 3, message: "Es necessari tener al menor 3 caracteres" },
          { max: 50, message: "No se puede tener mas de 50 caracteres" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={"Cedula de Identidad"} name={"ci"}>
        <Input />
      </Form.Item>
      <Form.Item label={"RUC"} name={"ruc"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Dirección"} name={"address"}>
        <Input />
      </Form.Item>
      <Form.Item label={"Telefono"} name={"phone"}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type={"primary"} htmlType={"submit"}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EntityForm;
